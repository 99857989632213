import { AppBar, Tabs, Tab } from '@mui/material'

import useStyles from './BasicTabs.styles'

const BasicTabs = ({ className, id, selectedTab, onSelectedTabChange, tabsData }) => {
  const classes = useStyles()

  return (
    <AppBar className={className} position="static" color="primary">
      <Tabs
        value={selectedTab}
        onChange={onSelectedTabChange}
        textColor="inherit"
        indicatorColor="secondary"
        variant="scrollable"
        scrollButtons={false}
      >
        {tabsData.map((tabObj) => (
          <Tab key={tabObj.id} id={id + '--tab-' + tabObj.id} className={classes.tab} label={tabObj.label} icon={tabObj.icon} />
        ))}
      </Tabs>
    </AppBar>
  )
}

export default BasicTabs
