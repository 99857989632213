export const ACCOUNT_DETAILS_CHANGE = 'ACCOUNT_DETAILS_CHANGE'

export const LOAD_ACCOUNT_DETAILS_START = 'LOAD_ACCOUNT_DETAILS_START'
export const LOAD_ACCOUNT_DETAILS_SUCCESS = 'LOAD_ACCOUNT_DETAILS_SUCCESS'
export const LOAD_ACCOUNT_DETAILS_FAILURE = 'LOAD_ACCOUNT_DETAILS_FAILURE'

export const UPDATE_ACCOUNT_DETAILS_START = 'UPDATE_ACCOUNT_DETAILS_START'
export const UPDATE_ACCOUNT_DETAILS_SUCCESS = 'UPDATE_ACCOUNT_DETAILS_SUCCESS'
export const UPDATE_ACCOUNT_DETAILS_FAILURE = 'UPDATE_ACCOUNT_DETAILS_FAILURE'

export const accountDetailsChange = ({ details, customFields }) => ({
  type: ACCOUNT_DETAILS_CHANGE,
  payload: {
    details,
    customFields
  }
})

export const loadAccountDetailsStart = () => ({
  type: LOAD_ACCOUNT_DETAILS_START
})

export const loadAccountDetailsSuccess = ({ details, customFields }) => ({
  type: LOAD_ACCOUNT_DETAILS_SUCCESS,
  payload: {
    details,
    customFields
  }
})

export const loadAccountDetailsFailure = ({ error }) => ({
  type: LOAD_ACCOUNT_DETAILS_FAILURE,
  payload: {
    error
  }
})

export const updateAccountDetailsStart = () => ({
  type: UPDATE_ACCOUNT_DETAILS_START
})

export const updateAccountDetailsSuccess = () => ({
  type: UPDATE_ACCOUNT_DETAILS_SUCCESS
})

export const updateAccountDetailsFailure = ({ error }) => ({
  type: UPDATE_ACCOUNT_DETAILS_FAILURE,
  payload: {
    error
  }
})
