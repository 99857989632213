import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(
  (theme) => ({
    'notification-types': {
      marginTop: theme.spacing(4)
    },
    'notification-channels': {
      marginTop: theme.spacing(4)
    },
    'weather-types': {
      marginTop: theme.spacing(4)
    },
    'submit-btn-container': {
      paddingBottom: theme.spacing(4)
    }
  }),
  { name: 'account-notifications-page-' }
)

export default useStyles
