import { FormControl, InputLabel, Select } from '@mui/material'

import useStyles from './NativeSelectInput.styles'

const NativeSelectInput = (props) => {
  const {
    id,
    className,
    label,
    labelVisuallyHidden,
    disabled,
    required,
    name,
    autoComplete,
    value,
    renderValue,
    options,
    blankOption,
    getOptionValue,
    renderOption,
    variant,
    margin,
    fullWidth,
    dir,
    onChange
  } = props
  const classes = useStyles(props)
  return (
    <FormControl
      className={classes.wrapper + (labelVisuallyHidden ? ' ' + classes['wrapper-label-visually-hidden'] : '') +  (className ? ' ' + className : '')}
      variant={variant}
      margin={margin}
      fullWidth={fullWidth}
      disabled={disabled}
      required={required}
      color={window.app.theme.palette.textInput ? 'textInput' : 'primary'}
    >
      {label && (
        <InputLabel
          id={id + '-label'}
          className={labelVisuallyHidden ? '_visually_hidden_' : ''}
          htmlFor={id}
        >
          {label}
        </InputLabel>
      )}
      {typeof renderValue === 'function' && (
        // This will be displayed as the value of the input, in case we need it to be different from the value attribute of the option
        <span className={classes['html-value'] + (disabled ? ' ' + classes['html-value-disabled'] : '')} aria-hidden="true" dir={dir}>
          {renderValue(value)}
        </span>
      )}
      <Select
        native
        id={id}
        label={label}
        labelId={id + '-label'}
        autoComplete={autoComplete}
        value={!disabled && value}
        onChange={onChange}
        inputProps={{ className: classes.input + (typeof renderValue === 'function' ? ' ' + classes['input-transparent'] : ''), name }}
        dir={dir}
        color={window.app.theme.palette.textInput ? 'textInput' : 'primary'}
      >
        <option className={classes.option} value="" hidden={blankOption ? undefined : true} dir={dir}>
          {blankOption || ''}
        </option>
        {options.map((option, index) => (
          <option
            className={classes.option}
            key={index}
            value={typeof getOptionValue === 'function' ? getOptionValue(option) : option.value}
            dir={dir}
          >
            {typeof renderOption === 'function' ? renderOption(option) : option.name}
          </option>
        ))}
      </Select>
    </FormControl>
  )
}

export default NativeSelectInput
