import { useState, useEffect } from 'react'
import { FormControl, FormLabel, FormGroup, Typography, Grid, Tooltip } from '@mui/material'
import { MyLocation as MyLocationIcon, Delete as DeleteIcon } from '@mui/icons-material'
import { visuallyHidden } from '@mui/utils'

import IconButtonWithProgress from '../common/IconButtonWithProgress'
import ButtonWithProgress from '../common/ButtonWithProgress'
import BasicTabs from '../common/BasicTabs'
import TextInput from '../common/TextInput'
import ProgressOverlay from '../common/ProgressOverlay'

import maps from '../../helper/maps'
import locationService from '../../service/locationService'
import intl from '../../helper/intl'

import { useSelector, useDispatch } from 'react-redux'
import {
  geocodeStart,
  geocodeSuccess,
  geocodeFailure,
  reverseGeocodeStart,
  reverseGeocodeSuccess,
  reverseGeocodeFailure
} from '../../actions/accountLocationsAction'

import useStyles from './LocationFieldset.styles'

const LocationFieldset = ({
  id,
  className,
  legend,
  autoFocus,
  disabled,
  name,
  line1,
  line2,
  city,
  postcode,
  state,
  country,
  longitude,
  latitude,
  onChange,
  onDelete,
  showDeleteProgress
}) => {
  const [selectedTab, setSelectedTab] = useState(0)
  const geocodeLoading = useSelector((state) => state.accountLocations.geocodeLoading)
  const bounds = useSelector((state) => state.auth.companyData.bounds)
  const reverseGeocodeLoading = useSelector((state) => state.accountLocations.reverseGeocodeLoading)
  const dispatch = useDispatch()
  const classes = useStyles()

  const mapId = 'geolocation-fieldset-' + id + '--map'

  useEffect(() => {
    maps.renderMap(mapId)

    // If the location has a latitude and longitude, display it on the map
    if (longitude && latitude) {
      maps.addMarkerToMap(mapId, { longitude, latitude }, 'instant')
    } else {
      maps.zoomToDisplayAllCoordinates(mapId, bounds)
    }

    // if the user clicks on the map, update the location and the marker
    maps.addEventListenerToMap(mapId, 'click', (event) => {
      dispatch(reverseGeocodeStart({ id }))
      const lonLat = maps.toLonLat(event.coordinate)
      const longitude = lonLat[0]
      const latitude = lonLat[1]
      locationService
        .reverseGeocode({ longitude, latitude })
        .then((result) => {
          dispatch(reverseGeocodeSuccess())
          maps.addMarkerToMap(mapId, { longitude, latitude })
          if (typeof onChange === 'function') {
            const location = {
              id,
              name: document.getElementById('location-fieldset-' + id + '--name').value,
              line1: result.location.line1 || '', // || line1,
              line2: result.location.line2 || '', // || line2,
              city: result.location.city || '', // || city,
              postcode: result.location.postcode || '', // || postcode,
              state: result.location.state || '', // || state,
              country: result.location.country || '', // || country,
              longitude,
              latitude
            }
            onChange(location)
          }
        })
        .catch((error) => {
          console.log(error)
          dispatch(reverseGeocodeFailure({ error: '' }))
        })
    })
  }, [])

  const onGeolocateButtonClick = (event) => {
    if (!line1 && !line2 && !city && !postcode && !state && !country) {
      resetLoocationCoords()
      return
    }
    // If the user geolocates the address, show the map (only affects mobile)
    setSelectedTab(1)

    dispatch(geocodeStart({ id }))
    locationService
      .geocode({ line1, line2, city, postcode, state, country })
      .then((result) => {
        dispatch(geocodeSuccess())
        const geocodedLocation = result.location
        if (geocodedLocation && Object.keys(geocodedLocation).length) {
          maps.addMarkerToMap(mapId, { longitude: geocodedLocation.longitude, latitude: geocodedLocation.latitude }, 'transition')

          if (typeof onChange === 'function') {
            const location = {
              id,
              name,
              line1: line1 || result.location.line1,
              line2: line2 || result.location.line2,
              city: city || result.location.city,
              postcode: postcode || result.location.postcode,
              state: state || result.location.state,
              country: country || result.location.country,
              longitude: geocodedLocation.longitude,
              latitude: geocodedLocation.latitude
            }
            onChange(location)
          }
        } else {
          resetLoocationCoords()
        }
      })
      .catch((error) => {
        dispatch(geocodeFailure({ error: error.message }))
      })
  }

  const resetLoocationCoords = () => {
    maps.removeMarkerFromMap(mapId)
    const location = { id, name, line1, line2, city, postcode, state, country, longitude: '', latitude: '' }
    onChange(location)
  }

  const onLocationFieldChange = (paramName, paramValue) => {
    if (typeof onChange === 'function') {
      const location = { id, name, line1, line2, city, postcode, state, country, longitude, latitude }
      location[paramName] = paramValue
      onChange(location)
    }
  }

  return (
    <FormControl
      className={classes.wrapper + (className ? ' ' + className : '')}
      id={'location-fieldset-' + id}
      component="fieldset"
      role="group"
    >
      {/* Legend of the fieldset, must be the first child of the fieldset. For screen readers only. */}
      {legend && (
        <Typography style={visuallyHidden} component="legend">
          {legend}
        </Typography>
      )}

      {/* Visible text that will act as the legend of the fieldset. Hidden for screen readers. */}
      {// legend && 
      (
        <FormLabel className={classes.legend} component="p" aria-hidden="true" role="presentation">
          {legend}
        </FormLabel>
      )}

      <BasicTabs
        className={classes.tabs}
        id={'location-fieldset--tabs-' + id}
        selectedTab={selectedTab}
        onSelectedTabChange={(event, newValue) => setSelectedTab(newValue)}
        tabsData={[
          { id: 'address', label: intl.translate('account_locations_page__address_tab') },
          { id: 'map', label: intl.translate('account_locations_page__map_tab') }
        ]}
      />

      <FormGroup className={classes['form-group'] + (selectedTab === 0 ? '' : ' ' + classes['form-group-hidden'])}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextInput
              id={'location-fieldset-' + id + '--name'}
              label={intl.translate('account_locations_page__name')}
              autoFocus={autoFocus}
              disabled={disabled}
              value={name}
              onChange={(event) => onLocationFieldChange('name', event.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextInput
              id={'location-fieldset-' + id + '--line1'}
              label={intl.translate('account_locations_page__line1')}
              disabled={disabled}
              value={line1}
              onChange={(event) => onLocationFieldChange('line1', event.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextInput
              id={'location-fieldset-' + id + '--line2'}
              label={intl.translate('account_locations_page__line2')}
              disabled={disabled}
              value={line2}
              onChange={(event) => onLocationFieldChange('line2', event.target.value)}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <TextInput
              id={'location-fieldset-' + id + '--city'}
              label={intl.translate('account_locations_page__city')}
              disabled={disabled}
              value={city}
              onChange={(event) => onLocationFieldChange('city', event.target.value)}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <TextInput
              id={'location-fieldset-' + id + '--postcode'}
              label={intl.translate('account_locations_page__postcode')}
              disabled={disabled}
              value={postcode}
              onChange={(event) => onLocationFieldChange('postcode', event.target.value)}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <TextInput
              id={'location-fieldset-' + id + '--state'}
              label={intl.translate('account_locations_page__state')}
              disabled={disabled}
              value={state}
              onChange={(event) => onLocationFieldChange('state', event.target.value)}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <TextInput
              id={'location-fieldset-' + id + '--country'}
              label={intl.translate('account_locations_page__country')}
              disabled={disabled}
              value={country}
              onChange={(event) => onLocationFieldChange('country', event.target.value)}
            />
          </Grid>
        </Grid>
      </FormGroup>

      <FormGroup className={classes['form-group'] + (selectedTab === 1 ? '' : ' ' + classes['form-group-hidden'])}>
        <Grid container spacing={2}>
          <Grid item md={6} xs={12}>
            <TextInput
              id={'location-fieldset-' + id + '--longitude'}
              label={intl.translate('account_locations_page__longitude')}
              disabled
              value={longitude}
              required
              onChange={(event) => onLocationFieldChange('longitude', event.target.value)}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <TextInput
              id={'location-fieldset-' + id + '--latitude'}
              label={intl.translate('account_locations_page__latitude')}
              disabled
              required
              value={latitude}
              onChange={(event) => onLocationFieldChange('latitude', event.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <div className={classes.map} id={mapId} aria-hidden="true" role="presentation">
              <ProgressOverlay transparent={true} hidden={reverseGeocodeLoading !== id} />
            </div>
          </Grid>
        </Grid>
      </FormGroup>

      <div className={classes['action-buttons-wrapper']}>
        <Tooltip title={intl.translate('account_locations_page__geolocate_btn_label')}>
          <span>
            <ButtonWithProgress
              id={'location-fieldset-' + id + '--geolocate-btn'}
              showProgress={geocodeLoading === id}
              aria-label={intl.translate('account_locations_page__geolocate_btn_label') + (legend ? ' ' + legend : '')}
              onClick={onGeolocateButtonClick}
              variant="outlined"
            >
              <MyLocationIcon />&nbsp;{intl.translate('account_locations_page__geolocate_btn_label')}
            </ButtonWithProgress>
          </span>
        </Tooltip>
        {onDelete && (
          <Tooltip title={intl.translate('account_locations_page__delete_btn')}>
            <span>
              <IconButtonWithProgress
                showProgress={showDeleteProgress}
                aria-label={intl.translate('account_locations_page__delete_btn') + (legend ? ' ' + legend : '')}
                onClick={onDelete}
              >
                <DeleteIcon />
              </IconButtonWithProgress>
            </span>
          </Tooltip>
        )}
      </div>
    </FormControl>
  )
}

export default LocationFieldset
