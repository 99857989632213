import { useState } from 'react'

import { Autocomplete, TextField } from '@mui/material'

const AutocompleteInput = ({
  id,
  className,
  label,
  disabled,
  freeSolo,
  disableClearable,
  value,
  options,
  renderOption,
  onChange,
  ...otherProperties
}) => {
  const [autocompleteValue, setAutocompleteValue] = useState(value)
  const [autocompleteIsOpen, setAutocompleteIsOpen] = useState(false)

  const onOptionSelected = (event, option) => {
    const optionValue = typeof renderOption === 'function' ? renderOption(option) : option
    setAutocompleteValue(optionValue)
  }

  const onInputChange = (event, value) => {
    if (typeof onChange === 'function') {
      onChange(value)
    }
  }

  const inputMatchesOption = () => {
    const numberOfOptions = options.length
    for (let i = 0; i < numberOfOptions; i++) {
      const optionValue = typeof renderOption === 'function' ? renderOption(options[i]) : options[i]
      if (optionValue === value) {
        return true
      }
    }

    return false
  }

  return (
    <Autocomplete
      {...otherProperties}
      id={id}
      className={className}
      disabled={disabled}
      freeSolo={freeSolo || true /* Allows the user to enter a value different from the given options */}
      disableClearable={disableClearable || true}
      value={autocompleteValue /* The value of the autocomplete component is used to filter the options list */}
      inputValue={value /* This is the value displayed on the input */}
      options={options}
      getOptionLabel={renderOption}
      renderInput={(params) => <TextField {...params} label={label} variant="outlined" margin="normal" />}
      onChange={onOptionSelected}
      onInputChange={onInputChange}
      open={inputMatchesOption() ? false : autocompleteIsOpen /* If the input matches an option, always hide list of options */}
      onFocus={() => setAutocompleteIsOpen(true)}
      onBlur={() => setAutocompleteIsOpen(false)}
    />
  )
}

export default AutocompleteInput
