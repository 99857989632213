import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material'
import ButtonWithProgress from '../ButtonWithProgress'

import { useSelector } from 'react-redux'

import useStyles from './BasicDialog.styles'

const BasicDialog = ({ id, isOpen, title, description, children, actionButtons, onClose, ...otherProperties }) => {
  const languageDirection = useSelector((state) => state.intl.languageDirection)
  const classes = useStyles()

  return (
    <Dialog
      id={id}
      dir={languageDirection}
      open={isOpen}
      onClose={onClose}
      aria-labelledby={title ? id + '--title' : undefined}
      aria-describedby={description ? id + '--description' : undefined}
      scroll="body"
      {...otherProperties}
    >
      {title && <DialogTitle id={id + '--title'}>{title}</DialogTitle>}

      <DialogContent className={classes['content-wrapper']}>
        {description && <DialogContentText id={id + '--description'}>{description}</DialogContentText>}
        {children}
      </DialogContent>

      {actionButtons && actionButtons.length && (
        <DialogActions sx={{ pt: 1, pr: 2, pb: 2, pl: 2, }}>
          {actionButtons.map((actionButton, index) => {
            const { content, onClick, ...otherProperties } = actionButton
            return (
              <ButtonWithProgress
                key={index}
                onClick={onClick}
                variant="text"
                color={window.app.theme.palette.button ? 'button' : 'primary'}
                {...otherProperties}
              >
                {content}
              </ButtonWithProgress>
            )
          })}
        </DialogActions>
      )}
    </Dialog>
  )
}

export default BasicDialog
