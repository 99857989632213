import axios from 'axios'
import config from './config'

import intl from '../helper/intl'
import utils from '../helper/utils'

import { store } from '../index'

const authService = (() => {
  const getCompanyData = async () => {
    const url = window.location.hostname

    return axios.get(config.apiUrl + '/company/getByUrl/' + url).then((result) => {
      config.setCompanyId(result.data.id)
      config.setCompanyName(result.data.name)
      return result
    })
  }

  const register = ({
    firstName,
    lastName,
    language,
    email,
    phoneNumber,
    countryCode,
    password,
    phoneNumberSms,
    addressLine1,
    addressLine2,
    city,
    zipCode,
    state,
    country,
    longitude,
    latitude,
    recaptcha_token
  }) => {
    let accountId = ''

    // We need to make three requests in order to register.
    // 1. Get the company ID.
    return getCompanyData()
      .then((result) => {
        const registerData = { password, recaptcha_token }
        if (firstName) {
          registerData.firstnames = firstName
        }
        if (lastName) {
          registerData.surnames = lastName
        }
        if (language) {
          registerData.lang = language
        }
        if (email) {
          registerData.email = email
        }
        if (typeof phoneNumberSms === 'boolean') {
          registerData.phone_type = phoneNumberSms + ''
        }
        if (phoneNumber) {
          registerData.number = phoneNumber.replace(/\D/g, '')
        } // remove non-numeric characters
        if (countryCode) {
          registerData.country_code = { code: countryCode }
        }

        // Location data
        if (result.data.location_in_reg) {
          registerData.locations = [
            {
              name: 'Main address',
              line1: addressLine1,
              line2: addressLine2,
              city: city,
              zipCode: zipCode,
              state: state,
              country: country,
              longitude: longitude,
              latitude: latitude,
              geometry: latitude && longitude ? `${latitude},${longitude}` : ''
            }
          ]
        }

        let domain = window.location.origin
        if (window.__env__ && window.__env__.PUBLIC_PATH) {
          domain += window.__env__.PUBLIC_PATH
        }
        registerData.domain = domain

        // The registration method is PORTAL
        registerData.registration = 'PORTAL'
        // 2. Register the user using the company ID.
        return axios.post(config.apiUrl + '/target/register/' + config.getCompanyId(), registerData)
      })
      .then((result) => {
        accountId = result.data.id
        // 3. Authenticate with oauth after registering to get the authentication token.
        return oauthLogin({ email, phoneNumber, countryCode, password })
      })
      .then((result) => {
        const accessToken = result && result.data ? result.data.access_token : ''
        const refreshToken = result && result.data ? result.data.refresh_token : ''
        config.setAccessToken(accessToken)
        config.setRefreshToken(refreshToken)
        result.data.id = accountId
        result.data.accessToken = accessToken
        result.data.refreshToken = refreshToken
        return result
      })
  }

  const deregister = ({ accountId, email, phoneNumber, countryCode, externalId, password, isFromSSO }) => {
    const authHeader = 'Bearer ' + config.getAccessToken()
    if (isFromSSO) {
      return axios.delete(config.apiUrl + '/target/' + accountId, { headers: { Authorization: authHeader } })
    } else {
      return oauthLogin({ email, phoneNumber, countryCode, externalId, password }).then((result) => {
        return axios.delete(config.apiUrl + '/target/' + accountId, { headers: { Authorization: authHeader } })
      })
    }
  }

  const login = ({ email, phoneNumber, countryCode, externalId, password }) => {
    let accessToken = ''
    let refreshToken = ''

    // We need to make three requests in order to log in.
    // 1. Get the company ID.
    return getCompanyData()
      .then((result) => {
        // 2. Authenticate with oauth using the company ID to get the authentication token.
        return oauthLogin({ email, phoneNumber, countryCode, externalId, password })
      })
      .then((result) => {
        accessToken = result && result.data ? result.data.access_token : ''
        refreshToken = result && result.data ? result.data.refresh_token : ''
        config.setAccessToken(accessToken)
        config.setRefreshToken(refreshToken)
        // 3. Log in using the authentication token.
        return axios.post(config.apiUrl + '/target/login', null, {
          headers: { Authorization: 'Bearer ' + accessToken }
        })
      })
      .then((result) => {
        result.data.accessToken = accessToken
        result.data.refreshToken = refreshToken
        return result
      })
  }

  const oauthLogin = ({ email, phoneNumber, countryCode, externalId, password }) => {
    const authorizationBasic = btoa('lrad_mns_gui:secret')

    let username = ''
    if (email) {
      username += 'ES_email_portal=' + email
    } else if (phoneNumber && countryCode) {
      const _phoneNumber = phoneNumber.replace(/\D/g, '') // remove non-numeric characters
      username += 'ES_msisdn_portal=(' + intl.getDialCodeByCountryCode(countryCode) + ')' + _phoneNumber
    } else if (externalId) {
      username += 'ES_externalid_portal=' + externalId
    }
    username += '_id_company=' + config.getCompanyId()

    let body = 'grant_type=password'
    body += '&username=' + utils.encodeUrlParam(username)
    body += '&password=' + utils.encodeUrlParam(password)

    return axios.post(config.oauthUrl, body, {
      headers: {
        Authorization: 'Basic ' + authorizationBasic,
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    })
  }

  const requestPasswordReset = ({ email, phoneNumber, countryCode, recaptcha_token }) => {
    let domain = window.location.origin
    if (window.__env__ && window.__env__.PUBLIC_PATH) {
      domain += window.__env__.PUBLIC_PATH
    }

    const credentialsObj = { domain, recaptcha_token }
    if (email) {
      credentialsObj.email = email
    } else if (phoneNumber && countryCode) {
      credentialsObj.number = phoneNumber.replace(/\D/g, '') // remove non-numeric characters
      credentialsObj.countryCode = countryCode
    }

    return axios.post(config.apiUrl + '/resetpassword', credentialsObj)
  }

  const resetPassword = ({ newPassword, token }) => {
    return axios.post(config.apiUrl + '/updatenewpassword', { newPassword, token })
  }

  const requestMfaCode = ({ email, countryCode, phoneNumber }) => {
    const authHeader = 'Bearer ' + (config.getAccessToken() || store.getState().auth.accessToken)

    let endpoint = config.apiUrl + '/target'
    if (email) {
      endpoint += '/sendemailcode/' + email
    } else if (countryCode && phoneNumber) {
      endpoint += '/sendsmscode/' + countryCode + '/' + phoneNumber
    }

    return axios.get(endpoint, { headers: { Authorization: authHeader } })
  }

  const verifyMfaCode = ({ mfaCode, email, countryCode, phoneNumber }) => {
    const authHeader = 'Bearer ' + (config.getAccessToken() || store.getState().auth.accessToken)

    let endpoint = config.apiUrl + '/target'
    if (email) {
      endpoint += '/verifyemailcode/' + email
    } else if (countryCode && phoneNumber) {
      endpoint += '/verifysmscode/' + countryCode + '/' + phoneNumber
    }
    endpoint += '/' + mfaCode

    return axios.get(endpoint, { headers: { Authorization: authHeader } })
  }

  return {
    getCompanyData,
    register,
    deregister,
    login,
    oauthLogin,
    requestPasswordReset,
    resetPassword,
    requestMfaCode,
    verifyMfaCode
  }
})()

export default authService
