import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(
  (theme) => ({
    wrapper: {
      position: 'absolute',
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      borderRadius: 'inherit', // in case the parent has border radius, inherit it, so the area covered by the overlay doesn't go over the rounded borders
      width: '100%',
      height: '100%',
      backgroundColor: theme.palette.background.paper,

      opacity: 1,
      zIndex: 10,
      transition: 'opacity ' + theme.transitions.duration.standard + 'ms ease 0ms'
    },
    'wrapper-hidden': {
      opacity: 0,
      zIndex: -1,
      transition:
        'opacity ' + theme.transitions.duration.standard + 'ms ease 0ms, z-index 0ms ease ' + theme.transitions.duration.standard + 'ms'
    },
    'wrapper-transparent': {
      backgroundColor: theme.palette.action.disabledBackground
    },
    'progress-wrapper': {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)'
    }
  }),
  { name: 'progress-overlay-' }
)

export default useStyles
