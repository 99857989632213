import { useEffect } from 'react'
import { Paper, Typography, Link } from '@mui/material'
import { Link as RouterLink } from 'react-router-dom'
import PageTitle from '../common/PageTitle'

import intl from '../../helper/intl'

import { useSelector } from 'react-redux'

import useStyles from './AccessibilityStatementPage.styles'

const AccessibilityStatementPage = () => {
  const companyData = useSelector((state) => state.auth.companyData)
  const accountId = useSelector((state) => state.auth.accountId)
  const urlBasename = useSelector((state) => state.intl.urlBasename)
  const classes = useStyles()

  useEffect(() => {
    document.title = intl.translate('accessibility_statement_page__title') + ' | ' + (companyData.portal_title || intl.translate('app_header__title'))
  }, [companyData])

  return (
    <Paper className="_centered_container_">
      <PageTitle className={classes.title}>{intl.translate('accessibility_statement_page__title')}</PageTitle>

      {!accountId && (
        <Typography
          variant="body1"
          gutterBottom
          className={classes['back-to-login-paragraph']}
          color={window.app.theme.palette.link ? 'link.main' : 'primary'}
        >
          <Link component={RouterLink} to={urlBasename} color="inherit">
            Back to login
          </Link>
        </Typography>
      )}

      <Typography variant="h5" gutterBottom>
        Support
      </Typography>
      <Typography variant="body1" gutterBottom className={classes['final-paragraph']}>
        Do you need assistance accessing something on our website? Email{' '}
        <Link href="mailto:contact@genasys.com" color={window.app.theme.palette.link ? 'link.main' : 'primary'}>
          contact@genasys.com
        </Link>{' '}
        and we will reply as soon as possible.
      </Typography>

      <Typography variant="h5" gutterBottom>
        Commitment to Accessibility
      </Typography>
      <Typography variant="body1" gutterBottom className={classes['final-paragraph']}>
        This website is committed to accessibility. It is our policy to ensure that persons with disabilities have full and equal access to
        our platform.
      </Typography>

      <Typography variant="h5" gutterBottom>
        Feedback
      </Typography>
      <Typography variant="body1" gutterBottom className={classes['final-paragraph']}>
        We welcome any feedback on the performance and accessibility of this website. Email{' '}
        <Link href="mailto:contact@genasys.com" color={window.app.theme.palette.link ? 'link.main' : 'primary'}>
          contact@genasys.com
        </Link>{' '}
        to provide feedback.
      </Typography>

      <Typography variant="h5" gutterBottom>
        Standards
      </Typography>
      <Typography variant="body1" gutterBottom className={classes['final-paragraph']}>
        This website strives to conform to WCAG 2.1 AA standards to ensure digital offerings are fully accessible.
      </Typography>

      <Typography variant="h5" gutterBottom>
        Compatibility
      </Typography>
      <Typography variant="body1" gutterBottom className={classes['final-paragraph']}>
        This website is compatible with most major Internet browsers including Chrome, FireFox, and Safari. This website may not perform
        optimally on Internet Explorer and older browser versions.
      </Typography>

      <Typography variant="h5" gutterBottom>
        Testing
      </Typography>
      <Typography variant="body1" gutterBottom className={classes['final-paragraph']}>
        This website has been manually audited and scanned using automated software (ANDI) to ensure accessibility.
      </Typography>

      <Typography variant="h5" gutterBottom>
        Training and Education
      </Typography>
      <Typography variant="body1" gutterBottom className={classes['final-paragraph']}>
        Our software development team is well-versed in accessibility standards and keeps up-to-date through regular research and informal
        training.
      </Typography>

      <Typography variant="h5" gutterBottom>
        Compliance
      </Typography>
      <Typography variant="body1" gutterBottom className={classes['final-paragraph']}>
        Through genuine effort and commitment including manual evaluations, consulting, and remediation, we believe this website adheres to
        best practices for compliance with the Americans with Disabilities Act (ADA).
      </Typography>

      <Typography variant="h5" gutterBottom>
        Resources
      </Typography>
      <Typography variant="body1">For more information on web accessibility and legal requirements, here are helpful resources:</Typography>
      <ul className={classes.list}>
        <li>
          <Link href="https://www.ada.gov/" target="_blank" color={window.app.theme.palette.link ? 'link.main' : 'primary'}>
            ADA.gov
          </Link>
        </li>
        <li>
          <Link href="https://section508.gov/" target="_blank" color={window.app.theme.palette.link ? 'link.main' : 'primary'}>
            Section508.gov
          </Link>
        </li>
        <li>
          <Link href="https://www.w3.org/WAI/" target="_blank" color={window.app.theme.palette.link ? 'link.main' : 'primary'}>
            Web Accessibility Initiative (WAI)
          </Link>
        </li>
      </ul>

      {!accountId && (
        <Typography variant="body1" gutterBottom className={classes['back-to-login-final-paragraph']}>
          <Link component={RouterLink} to={urlBasename} color={window.app.theme.palette.link ? 'link.main' : 'primary'}>
            Back to login
          </Link>
        </Typography>
      )}
    </Paper>
  )
}

export default AccessibilityStatementPage
