import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(
  (theme) => ({
    wrapper: {
      width: '100%'
    },
    legend: {
      marginBottom: theme.spacing(2),
      color: theme.palette.text.primary, // Overriding default color to make the legend look more like a paragraph

      '&.Mui-focused': {
        color: theme.palette.text.primary
      }
    },
    'checkbox-list': {
      margin: '0',
      padding: '0',
      listStyleType: 'none'
    },
    'checkbox-list-item': {
      marginLeft: '30px',
      marginRight: '0'
    },
    'checkbox-list-item-sortable': {
      marginLeft: '0',
      marginRight: '0'
    },
    'checkbox-list-item-dragged': {
      transition: 'opacity 250ms',
      opacity: '0'
    },
    'drag-indicator-icon-wrapper': {
      display: 'inline-block',
      padding: '3px 0px',
      width: '30px',
      height: '30px',
      verticalAlign: 'middle',
      cursor: 'grab'
    },
    'checkbox-wrapper': {
      margin: 0,
      width: 'calc(100% - 30px)'
    }
  }),
  { name: 'checkbox-input-group-' }
)

export default useStyles
