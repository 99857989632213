import {
  LOAD_ACCOUNT_NOTIFICATIONS_START,
  LOAD_ACCOUNT_NOTIFICATIONS_SUCCESS,
  LOAD_ACCOUNT_NOTIFICATIONS_FAILURE,
  NOTIFICATION_TYPES_CHANGE,
  NOTIFICATION_CHANNELS_CHANGE,
  WEATHER_TYPES_CHANGE,
  UPDATE_NOTIFICATION_TYPES_START,
  UPDATE_NOTIFICATION_TYPES_SUCCESS,
  UPDATE_NOTIFICATION_TYPES_FAILURE,
  UPDATE_NOTIFICATION_CHANNELS_START,
  UPDATE_NOTIFICATION_CHANNELS_SUCCESS,
  UPDATE_NOTIFICATION_CHANNELS_FAILURE,
  UPDATE_WEATHER_TYPES_START,
  UPDATE_WEATHER_TYPES_SUCCESS,
  UPDATE_WEATHER_TYPES_FAILURE
} from '../actions/accountNotificationsAction'

const initialState = {
  types: [],
  channels: [],
  weatherTypes: [],

  notificationsLoading: true,
  notificationsError: '',

  updateNotificationTypesLoading: false,
  updateNotificationTypesError: '',

  updateNotificationChannelsLoading: false,
  updateNotificationChannelsError: '',

  updateWeatherTypesLoading: false,
  updateWeatherTypesError: ''
}

const reducer = (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case LOAD_ACCOUNT_NOTIFICATIONS_START: {
      return {
        ...state,
        notificationsLoading: true,
        notificationsError: ''
      }
    }
    case LOAD_ACCOUNT_NOTIFICATIONS_SUCCESS: {
      return {
        ...state,
        types: payload.types,
        channels: payload.channels,
        weatherTypes: payload.weatherTypes,
        notificationsLoading: false,
        notificationsError: ''
      }
    }
    case LOAD_ACCOUNT_NOTIFICATIONS_FAILURE: {
      return {
        ...state,
        notificationsLoading: false,
        notificationsError: payload.error
      }
    }
    case NOTIFICATION_TYPES_CHANGE: {
      return {
        ...state,
        types: payload.types
      }
    }
    case NOTIFICATION_CHANNELS_CHANGE: {
      return {
        ...state,
        channels: payload.channels
      }
    }
    case WEATHER_TYPES_CHANGE: {
      return {
        ...state,
        weatherTypes: payload.weatherTypes
      }
    }
    case UPDATE_NOTIFICATION_TYPES_START: {
      return {
        ...state,
        updateNotificationTypesLoading: true,
        updateNotificationTypesError: ''
      }
    }
    case UPDATE_NOTIFICATION_TYPES_SUCCESS: {
      return {
        ...state,
        updateNotificationTypesLoading: false,
        updateNotificationTypesError: ''
      }
    }
    case UPDATE_NOTIFICATION_TYPES_FAILURE: {
      return {
        ...state,
        updateNotificationTypesLoading: false,
        updateNotificationTypesError: payload.error
      }
    }
    case UPDATE_NOTIFICATION_CHANNELS_START: {
      return {
        ...state,
        updateNotificationChannelsLoading: true,
        updateNotificationChannelsError: ''
      }
    }
    case UPDATE_NOTIFICATION_CHANNELS_SUCCESS: {
      return {
        ...state,
        updateNotificationChannelsLoading: false,
        updateNotificationChannelsError: ''
      }
    }
    case UPDATE_NOTIFICATION_CHANNELS_FAILURE: {
      return {
        ...state,
        updateNotificationChannelsLoading: false,
        updateNotificationChannelsError: payload.error
      }
    }
    case UPDATE_WEATHER_TYPES_START: {
      return {
        ...state,
        updateWeatherTypesLoading: true,
        updateWeatherTypesError: ''
      }
    }
    case UPDATE_WEATHER_TYPES_SUCCESS: {
      return {
        ...state,
        updateWeatherTypesLoading: false,
        updateWeatherTypesError: ''
      }
    }
    case UPDATE_WEATHER_TYPES_FAILURE: {
      return {
        ...state,
        updateWeatherTypesLoading: false,
        updateWeatherTypesError: payload.error
      }
    }
    default: {
      return state
    }
  }
}

export default reducer
