export const SHOW_APP_SNACKBAR = 'SHOW_APP_SNACKBAR'
export const APP_SNACKBAR_CONTENT_CHANGE = 'APP_SNACKBAR_CONTENT_CHANGE'
export const HIDE_APP_SNACKBAR = 'HIDE_APP_SNACKBAR'

export const SHOW_APP_DIALOG = 'SHOW_APP_DIALOG'
export const APP_DIALOG_CONTENT_CHANGE = 'APP_DIALOG_CONTENT_CHANGE'
export const HIDE_APP_DIALOG = 'HIDE_APP_DIALOG'

export const SHOW_CONTACT_CONFIRMATION_DIALOG = 'SHOW_CONTACT_CONFIRMATION_DIALOG'
export const CONTACT_CONFIRMATION_DIALOG_PARAMS_CHANGE = 'CONTACT_CONFIRMATION_DIALOG_PARAMS_CHANGE'
export const HIDE_CONTACT_CONFIRMATION_DIALOG = 'HIDE_CONTACT_CONFIRMATION_DIALOG'

// These are not action types, they're just constant values used in this file
const NOTIFICATION_OPEN_DELAY = 100 // milliseconds
const NOTIFICATION_CLOSE_DELAY = 400 // milliseconds

export const showAppSnackbar = () => ({
  type: SHOW_APP_SNACKBAR
})

// This function is used to load content on the snackbar, and then open it
export const openAppSnackbar =
  ({ message, title, description, duration, actionText, actionFunction }) =>
  (dispatch, getState) => {
    // If the app snackbar has content at this point, that means it's displayed on the screen (or it's in the process of being hidden)
    // In that case, we'll have to hide the snackbar and apply a delay before we show it again
    const appSnackbarHasContent = !!getState().notifications.appSnackbarMessage

    if (appSnackbarHasContent) {
      dispatch(hideAppSnackbar())
      setTimeout(() => {
        dispatch(appSnackbarContentChange({ message, title, description, duration, actionText, actionFunction }))
      }, NOTIFICATION_CLOSE_DELAY)
    } else {
      dispatch(appSnackbarContentChange({ message, title, description, duration, actionText, actionFunction }))
    }

    setTimeout(
      () => {
        dispatch(showAppSnackbar())
      },
      appSnackbarHasContent ? NOTIFICATION_OPEN_DELAY + NOTIFICATION_CLOSE_DELAY : NOTIFICATION_OPEN_DELAY
    )
  }

export const appSnackbarContentChange = ({ message, title, description, duration, actionText, actionFunction }) => ({
  type: APP_SNACKBAR_CONTENT_CHANGE,
  payload: {
    message,
    title,
    description,
    duration,
    actionText,
    actionFunction
  }
})

export const hideAppSnackbar = () => ({
  type: HIDE_APP_SNACKBAR
})

// This function is used to hide the snackbar, and then empty its content
// A timeout is used so that there are no ugly 'content jumps' when the snackbar disappears
export const closeAppSnackbar = () => (dispatch) => {
  dispatch(hideAppSnackbar())
  setTimeout(() => {
    dispatch(
      appSnackbarContentChange({
        message: '',
        title: '',
        description: '',
        actionText: '',
        actionFunction: null
      })
    )
  }, NOTIFICATION_CLOSE_DELAY)
}

export const showAppDialog = () => ({
  type: SHOW_APP_DIALOG
})

// This function is used to load content on the dialog, and then open it
export const openAppDialog =
  ({ title, description, actionText, actionFunction, autoFocusButton }) =>
  (dispatch) => {
    dispatch(appDialogContentChange({ title, description, actionText, actionFunction, autoFocusButton }))
    setTimeout(() => {
      dispatch(showAppDialog())
    }, NOTIFICATION_OPEN_DELAY)
  }

export const appDialogContentChange = ({ title, description, actionText, actionFunction, autoFocusButton }) => ({
  type: APP_DIALOG_CONTENT_CHANGE,
  payload: {
    title,
    description,
    actionText,
    actionFunction,
    autoFocusButton
  }
})

export const hideAppDialog = () => ({
  type: HIDE_APP_DIALOG
})

// This function is used to hide the dialog, and then empty its content
// A timeout is used so that there are no ugly 'content jumps' when the dialog disappears
export const closeAppDialog = () => (dispatch) => {
  dispatch(hideAppDialog())
  setTimeout(() => {
    dispatch(
      appDialogContentChange({
        title: '',
        description: '',
        actionText: '',
        actionFunction: null
      })
    )
  }, NOTIFICATION_CLOSE_DELAY)
}

export const showContactConfirmationDialog = () => ({
  type: SHOW_CONTACT_CONFIRMATION_DIALOG
})

export const contactConfirmationDialogParamsChange = ({ email, phoneNumber }) => ({
  type: CONTACT_CONFIRMATION_DIALOG_PARAMS_CHANGE,
  payload: {
    email,
    phoneNumber
  }
})

export const openContactConfirmationDialog =
  ({ email, phoneNumber }) =>
  (dispatch) => {
    dispatch(contactConfirmationDialogParamsChange({ email, phoneNumber }))
    setTimeout(() => {
      dispatch(showContactConfirmationDialog())
    }, NOTIFICATION_OPEN_DELAY)
  }

export const hideContactConfirmationDialog = () => ({
  type: HIDE_CONTACT_CONFIRMATION_DIALOG
})

export const closeContactConfirmationDialog = () => (dispatch) => {
  dispatch(hideContactConfirmationDialog())
  setTimeout(() => {
    dispatch(contactConfirmationDialogParamsChange({ email: '', phoneNumber: '' }))
  }, NOTIFICATION_CLOSE_DELAY)
}
