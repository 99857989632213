import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(
  (theme) => ({
    'individual-fieldset': {
      marginTop: theme.spacing(4),
      borderRadius: theme.shape.borderRadius
    },
    'update-btn': {
      marginTop: theme.spacing(2)
    },
    'create-btn': {
      marginTop: theme.spacing(4)
    }
  }),
  { name: 'account-individuals-page-' }
)

export default useStyles
