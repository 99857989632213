import useStyles from './Separator.style'

const Separator = ({ className, text, orientation }) => {
  const classes = useStyles()

  return (
    <div
      className={
        (orientation === 'vertical' ? classes['wrapper-vertical'] : classes['wrapper-horizontal']) + (className ? ' ' + className : '')
      }
    >
      <span className={orientation === 'vertical' ? classes['text-vertical'] : classes['text-horizontal']}>{text}</span>
    </div>
  )
}

export default Separator
