import axios from 'axios'
import intl from '../helper/intl'
import { store } from '../index'
import config from './config'

const accountService = (() => {
  const getAllData = ({ accountId }) => {
    const authHeader = 'Bearer ' + (config.getAccessToken() || store.getState().auth.accessToken)
    return axios.get(config.apiUrl + '/target/' + accountId, { headers: { Authorization: authHeader } })
  }

  const updateDetails = ({ accountId, details, customFields }) => {
    const authHeader = 'Bearer ' + (config.getAccessToken() || store.getState().auth.accessToken)
    const detailsObj = {
      id: accountId,
      firstnames: details.firstName,
      surnames: details.lastName,
      lang: details.language,
      email: details.email,
      number: details.phoneNumber.replace(/\D/g, ''), // remove non-numeric characters
      country_code: { code: details.countryCode },
      externalid: details.externalId,
      phone_type: details.phoneNumberSms + ''
    }

    if (customFields) {
      detailsObj.customFields = customFields
    }

    if (details.newPassword) {
      detailsObj.password = details.newPassword
    }

    return axios.put(config.apiUrl + '/target', detailsObj, { headers: { Authorization: authHeader }, params: { isUpdateFromPortal: true } }).then((result) => {
      result.data.emailConfirmed = result.data.email_confirmed
      delete result.data.email_confirmed
      result.data.phoneNumberConfirmed = result.data.number_confirmed
      delete result.data.number_confirmed
      return result
    })
  }

  const requestEmailConfirmation = ({ email }) => {
    const authHeader = 'Bearer ' + config.getAccessToken()
    const data = new FormData()
    data.append('email', email)
    return axios.post(config.apiUrl + '/emailconfirmationrequest', data, { headers: { Authorization: authHeader } })
  }

  const requestPhoneNumberConfirmation = ({ phoneNumber }) => {
    const authHeader = 'Bearer ' + config.getAccessToken()
    const data = new FormData()
    data.append('msisdn', '+' + phoneNumber.replace(/\D/g, ''))
    return axios.post(config.apiUrl + '/phoneconfirmationrequest', data, { headers: { Authorization: authHeader } })
  }

  const confirmPhoneNumber = ({ phoneNumber, code }) => {
    const authHeader = 'Bearer ' + config.getAccessToken()
    const data = new FormData()
    data.append('msisdn', '+' + phoneNumber.replace(/\D/g, ''))
    data.append('code', code)
    return axios.post(config.apiUrl + '/phoneconfirmation', data, { headers: { Authorization: authHeader } })
  }

  const updateEmail = ({ accountId, email }) => {
    const authHeader = 'Bearer ' + config.getAccessToken()
    return axios
      .put(
        config.apiUrl + '/target/emails/' + accountId,
        {
          id: email.id,
          alias: email.alias,
          email: email.email,
          email_confirmed: email.emailConfirmed
        },
        { headers: { Authorization: authHeader } }
      )
      .then((result) => {
        result.data.emailConfirmed = result.data.email_confirmed
        delete result.data.email_confirmed
        return result
      })
  }

  const createEmail = ({ accountId, email }) => {
    const authHeader = 'Bearer ' + config.getAccessToken()
    return axios
      .post(
        config.apiUrl + '/target/emails/' + accountId,
        {
          alias: email.alias,
          email: email.email
        },
        { headers: { Authorization: authHeader } }
      )
      .then((result) => {
        result.data.emailConfirmed = result.data.email_confirmed
        delete result.data.email_confirmed
        return result
      })
  }

  const deleteEmail = ({ accountId, emailId }) => {
    const authHeader = 'Bearer ' + config.getAccessToken()
    return axios.delete(config.apiUrl + '/target/portal/emails/' + accountId + '?emailid=' + emailId, {
      headers: { Authorization: authHeader }
    })
  }

  const updatePhone = ({ accountId, phone }) => {
    const authHeader = 'Bearer ' + config.getAccessToken()
    return axios
      .put(
        config.apiUrl + '/target/phones/' + accountId,
        {
          id: phone.id,
          alias: phone.alias,
          country_code: phone.countryCode,
          dial_code: phone.countryCode ? intl.getDialCodeByCountryCode(phone.countryCode) : '',
          number: phone.phoneNumber.replace(/\D/g, ''), // remove non-numeric characters
          number_confirmed: phone.phoneNumberConfirmed,
          phone_type: phone.phoneNumberSms + ''
        },
        { headers: { Authorization: authHeader } }
      )
      .then((result) => {
        result.data.phoneNumber = result.data.number
        delete result.data.number
        result.data.phoneNumberConfirmed = result.data.number_confirmed
        delete result.data.number_confirmed
        result.data.countryCode = result.data.country_code
        delete result.data.country_code
        result.data.phoneNumberSms = result.data.phone_type === 'true'
        delete result.data.phone_type
        return result
      })
  }

  const createPhone = ({ accountId, phone }) => {
    const authHeader = 'Bearer ' + config.getAccessToken()
    return axios
      .post(
        config.apiUrl + '/target/phones/' + accountId,
        {
          alias: phone.alias,
          country_code: phone.countryCode,
          dial_code: phone.countryCode ? intl.getDialCodeByCountryCode(phone.countryCode) : '',
          number: phone.phoneNumber.replace(/\D/g, ''), // remove non-numeric characters
          phone_type: phone.phoneNumberSms + ''
        },
        { headers: { Authorization: authHeader } }
      )
      .then((result) => {
        result.data.phoneNumber = result.data.number
        delete result.data.number
        result.data.phoneNumberConfirmed = result.data.number_confirmed
        delete result.data.number_confirmed
        result.data.countryCode = result.data.country_code
        delete result.data.country_code
        result.data.phoneNumberSms = result.data.phone_type === 'true'
        delete result.data.phone_type
        return result
      })
  }

  const deletePhone = ({ accountId, phoneId }) => {
    const authHeader = 'Bearer ' + config.getAccessToken()
    return axios.delete(config.apiUrl + '/target/portal/phones/' + accountId + '?phoneid=' + phoneId, {
      headers: { Authorization: authHeader }
    })
  }

  const updateLocation = ({ accountId, location }) => {
    const authHeader = 'Bearer ' + config.getAccessToken()
    const locationObj = {
      id: location.id,
      name: location.name,
      line1: location.line1,
      line2: location.line2,
      city: location.city,
      zipCode: location.postcode,
      state: location.state,
      country: location.country
    }
    if (location.longitude) {
      locationObj.longitude = location.longitude
    }
    if (location.latitude) {
      locationObj.latitude = location.latitude
    }
    if (location.geometry) {
      locationObj.geometry = location.geometry
    } else if (location.longitude && location.latitude) {
      locationObj.geometry = location.latitude + ',' + location.longitude
    }
    return axios
      .put(config.apiUrl + '/target/areas/' + accountId, locationObj, { headers: { Authorization: authHeader } })
      .then((result) => {
        // Transform API data to the format used by our app's components
        result.data.postcode = result.data.zipCode
        delete result.data.zipCode
        return result
      })
  }

  const createLocation = ({ accountId, location }) => {
    const authHeader = 'Bearer ' + config.getAccessToken()
    const locationObj = {
      name: location.name,
      line1: location.line1,
      line2: location.line2,
      city: location.city,
      zipCode: location.postcode,
      state: location.state,
      country: location.country
    }
    if (location.longitude) {
      locationObj.longitude = location.longitude
    }
    if (location.latitude) {
      locationObj.latitude = location.latitude
    }
    if (location.geometry) {
      locationObj.geometry = location.geometry
    } else if (location.longitude && location.latitude) {
      locationObj.geometry = location.latitude + ',' + location.longitude
    }
    return axios
      .post(config.apiUrl + '/target/areas/' + accountId, locationObj, { headers: { Authorization: authHeader } })
      .then((result) => {
        // Transform API data to the format used by our app's components
        result.data.postcode = result.data.zipCode
        delete result.data.zipCode
        return result
      })
  }

  const deleteLocation = ({ accountId, locationId }) => {
    const authHeader = 'Bearer ' + config.getAccessToken()
    return axios.delete(config.apiUrl + '/target/portal/areas/' + accountId + '?areaid=' + locationId, {
      headers: { Authorization: authHeader }
    })
  }

  const updateIndividual = ({ accountId, individual }) => {
    const authHeader = 'Bearer ' + config.getAccessToken()
    return axios
      .put(
        config.apiUrl + '/target/individuals/' + accountId,
        {
          id: individual.id,
          email: individual.email,
          email_confirmed: individual.emailConfirmed,
          firstName: individual.firstName,
          lastName: individual.lastName,
          country_code: individual.countryCode,
          dial_code: individual.countryCode ? intl.getDialCodeByCountryCode(individual.countryCode) : '',
          number: individual.phoneNumber.replace(/\D/g, ''), // remove non-numeric characters
          number_confirmed: individual.phoneNumberConfirmed,
          phone_type: individual.phoneNumberSms + ''
        },
        { headers: { Authorization: authHeader } }
      )
      .then((result) => {
        result.data.emailConfirmed = result.data.email_confirmed
        delete result.data.email_confirmed
        result.data.phoneNumber = result.data.number
        delete result.data.number
        result.data.phoneNumberConfirmed = result.data.number_confirmed
        delete result.data.number_confirmed
        result.data.countryCode = result.data.country_code
        delete result.data.country_code
        result.data.phoneNumberSms = result.data.phone_type === 'true'
        delete result.data.phone_type
        return result
      })
  }

  const createIndividual = ({ accountId, individual }) => {
    const authHeader = 'Bearer ' + config.getAccessToken()
    return axios
      .post(
        config.apiUrl + '/target/individuals/' + accountId,
        {
          email: individual.email,
          firstName: individual.firstName,
          lastName: individual.lastName,
          country_code: individual.countryCode,
          dial_code: individual.countryCode ? intl.getDialCodeByCountryCode(individual.countryCode) : '',
          number: individual.phoneNumber.replace(/\D/g, ''), // remove non-numeric characters
          phone_type: individual.phoneNumberSms + ''
        },
        { headers: { Authorization: authHeader } }
      )
      .then((result) => {
        result.data.emailConfirmed = result.data.email_confirmed
        delete result.data.email_confirmed
        result.data.phoneNumber = result.data.number
        delete result.data.number
        result.data.phoneNumberConfirmed = result.data.number_confirmed
        delete result.data.number_confirmed
        result.data.countryCode = result.data.country_code
        delete result.data.country_code
        result.data.phoneNumberSms = result.data.phone_type === 'true'
        delete result.data.phone_type
        return result
      })
  }

  const deleteIndividual = ({ accountId, individualId }) => {
    const authHeader = 'Bearer ' + config.getAccessToken()
    return axios.delete(config.apiUrl + '/target/portal/individuals/' + accountId + '?individualid=' + individualId, {
      headers: { Authorization: authHeader }
    })
  }

  const getAllNotifications = ({ accountId }) => {
    const authHeader = 'Bearer ' + config.getAccessToken()
    return axios
      .all([
        axios.get('/api/notificationtypes/all', { headers: { Authorization: authHeader } }),
        axios.get('/api/notificationchannels/all', { headers: { Authorization: authHeader } })
      ])
      .then(
        axios.spread((typesResult, channelsResult) => {
          const result = { data: {} }
          result.data.notificationTypes = typesResult.data
          result.data.notificationChannels = channelsResult.data
          return result
        })
      )
  }

  const updateNotificationTypes = ({ accountId, notificationTypes }) => {
    const authHeader = 'Bearer ' + config.getAccessToken()

    const notification_types = []
    const numNotificationTypes = notificationTypes.length
    for (let i = 0; i < numNotificationTypes; i++) {
      notification_types.push({
        id: notificationTypes[i].value,
        name: notificationTypes[i].label,
        active: notificationTypes[i].checked
      })
    }

    return axios.post(config.apiUrl + '/target/notifications_types/' + accountId, notification_types, {
      headers: { Authorization: authHeader }
    })
  }

  const updateNotificationChannels = ({ accountId, notificationChannels }) => {
    const authHeader = 'Bearer ' + config.getAccessToken()

    const notification_channels = []
    const numNotificationChannels = notificationChannels.length
    for (let i = 0; i < numNotificationChannels; i++) {
      notification_channels.push({
        id: notificationChannels[i].value,
        name: notificationChannels[i].label.toUpperCase(),
        active: notificationChannels[i].checked,
        sequenceNumber: notificationChannels[i].sequenceNumber
      })
      if (notificationChannels[i].value === 10) {
        notification_channels[i].name = 'VOICE'
      } else if (notificationChannels[i].value === 7) {
        notification_channels[i].name = 'SMS'
      }
    }

    return axios.post(config.apiUrl + '/target/notifications_channels/' + accountId, notification_channels, {
      headers: { Authorization: authHeader }
    })
  }

  const updateWeatherTypes = ({ accountId, weatherTypes }) => {
    const authHeader = 'Bearer ' + config.getAccessToken()

    const weather_categories = []
    const numWeatherCategories = weatherTypes.length
    for (let i = 0; i < numWeatherCategories; i++) {
      weather_categories.push({
        id: weatherTypes[i].id,
        name: weatherTypes[i].label,
        active: weatherTypes[i].checked,
        parent_id: weatherTypes[i].parent_id
      })
    }

    return axios.post(config.apiUrl + '/target/weather_categories/' + accountId, weather_categories, {
      headers: { Authorization: authHeader }
    })
  }

  const getFaqContent = ({ language, companyName }) => {
    const accessToken = config.getAccessToken()
    if (accessToken) {
      return axios.post(config.apiUrl + '/s3htmlfaqfile/', {
        fileName: `faq_${language}.html`,
        companyName: companyName,
      }, {
        headers: { Authorization: 'Bearer ' + config.getAccessToken() }
      });
    } else {
      return axios.post(config.apiUrl + '/s3htmlfaqfile/portallogin', {
        fileName: `faq_${language}.html`,
        companyName: companyName,
      });
    }
  }

  return {
    getAllData,
    updateDetails,
    requestEmailConfirmation,
    requestPhoneNumberConfirmation,
    confirmPhoneNumber,
    updateEmail,
    createEmail,
    deleteEmail,
    updatePhone,
    createPhone,
    deletePhone,
    updateLocation,
    createLocation,
    deleteLocation,
    updateIndividual,
    createIndividual,
    deleteIndividual,
    getAllNotifications,
    updateNotificationTypes,
    updateNotificationChannels,
    updateWeatherTypes,
    getFaqContent,
  }
})()

export default accountService
