import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(
  (theme) => ({
    description: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2)
    },
    separator: {
      marginTop: theme.spacing(1)
    },
    'google-recaptcha': {
      paddingTop: theme.spacing(2),
      '& > div > div': {
        margin: 'auto'
      }
    },
    'link-to-login': {
      marginTop: theme.spacing(2)
    }
  }),
  { name: 'login-page-' }
)

export default useStyles
