import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(
  (_theme) => ({
    wrapper: {
      position: 'relative',
      display: 'inline-flex'
    },
    button: {
      transition: 'none',
      width: '100%',
      border: '2px solid transparent',
      outline: '2px solid transparent',

      '&.MuiButton-contained': {
        backgroundColor: '#0033FF',
        color: '#FFFFFF',
      },
      '&.MuiButton-contained:focus, &.MuiButton-contained:hover': {
        backgroundColor: '#295BFF',
        outline: '2px solid #0033FF',
        border: '2px solid #FFFFFF',
      },
      '&.MuiButton-contained:disabled': {
        backgroundColor: '#F5F5F5',
        color: '#A3A3A3',
        border: '2px solid #D9D9D9',
      },

      '&.MuiButton-text': {
        backgroundColor: '#FFFFFF',
        color: '#0033FF',
      },
      '&.MuiButton-text:focus, &.MuiButton-text:hover': {
        color: '#295BFF',
        outline: '2px solid #295BFF',
        border: '2px solid #FFFFFF',
      },
      '&.MuiButton-text:disabled': {
        color: '#A3A3A3',
      },
    },
    progress: {
      position: 'absolute',
      top: '0',
      left: '0',
      bottom: '0',
      right: '0',
      margin: 'auto'
    }
  }),
  { name: 'button-with-progress-' }
)

export default useStyles
