import { FormControlLabel, Checkbox } from '@mui/material'

import useStyles from './CheckboxInput.styles'
import { useState } from 'react'

const CheckboxInput = ({ id, className, checkboxClassName, label, disabled, value, checked, onChange, onClick }) => {
  const classes = useStyles()
  const [isFocused, setIsFocused] = useState(false);

  return (
    <FormControlLabel
      className={classes.wrapper + (isFocused ? ' ' + classes.wrapperFocused : '') + (className ? ' ' + className : '')}
      control={(
        <Checkbox
          id={id}
          className={checkboxClassName}
          // color="primary" // Color is defined in CheckboxInput.styles
          disableRipple
          checked={checked}
          onChange={onChange}
          onClick={onClick}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          onKeyDown={(event) => {
            // Don't do anything when "Enter" key is pressed
            // This is done so forms are not submitted by mistake when editing checkbox fields
            if (event.key === "Enter") {
              event.preventDefault();
            }
          }}
          value={value}
        />
      )}
      label={label}
      disabled={disabled}
    />
  )
}

export default CheckboxInput
