import accountService from '../service/accountService'
import { openAppSnackbar, closeAppSnackbar, openContactConfirmationDialog } from './notificationsAction'
import intl from '../helper/intl'

export const ACCOUNT_PHONES_CHANGE = 'ACCOUNT_PHONES_CHANGE'

export const LOAD_ACCOUNT_PHONES_START = 'LOAD_ACCOUNT_PHONES_START'
export const LOAD_ACCOUNT_PHONES_SUCCESS = 'LOAD_ACCOUNT_PHONES_SUCCESS'
export const LOAD_ACCOUNT_PHONES_FAILURE = 'LOAD_ACCOUNT_PHONES_FAILURE'

export const UPDATE_PHONE_START = 'UPDATE_PHONE_START'
export const UPDATE_PHONE_SUCCESS = 'UPDATE_PHONE_SUCCESS'
export const UPDATE_PHONE_FAILURE = 'UPDATE_PHONE_FAILURE'

export const DELETE_PHONE_START = 'DELETE_PHONE_START'
export const DELETE_PHONE_SUCCESS = 'DELETE_PHONE_SUCCESS'
export const DELETE_PHONE_FAILURE = 'DELETE_PHONE_FAILURE'

export const SHOW_NEW_PHONE_DIALOG = 'SHOW_NEW_PHONE_DIALOG'
export const HIDE_NEW_PHONE_DIALOG = 'HIDE_NEW_PHONE_DIALOG'
export const NEW_PHONE_CHANGE = 'NEW_PHONE_CHANGE'
export const CREATE_NEW_PHONE_START = 'CREATE_NEW_PHONE_START'
export const CREATE_NEW_PHONE_SUCCESS = 'CREATE_NEW_PHONE_SUCCESS'
export const CREATE_NEW_PHONE_FAILURE = 'CREATE_NEW_PHONE_FAILURE'

export const accountPhonesChange = ({ phones }) => ({
  type: ACCOUNT_PHONES_CHANGE,
  payload: {
    phones
  }
})

export const loadAccountPhonesStart = () => ({
  type: LOAD_ACCOUNT_PHONES_START
})

export const loadAccountPhonesSuccess = ({ phones }) => ({
  type: LOAD_ACCOUNT_PHONES_SUCCESS,
  payload: {
    phones
  }
})

export const loadAccountPhonesFailure = ({ error }) => ({
  type: LOAD_ACCOUNT_PHONES_FAILURE,
  payload: {
    error
  }
})

export const updatePhoneStart = ({ id }) => ({
  type: UPDATE_PHONE_START,
  payload: {
    id
  }
})

export const updatePhoneSuccess = () => ({
  type: UPDATE_PHONE_SUCCESS
})

export const updatePhoneFailure = ({ error }) => ({
  type: UPDATE_PHONE_FAILURE,
  payload: {
    error
  }
})

export const deletePhoneStart = ({ id }) => ({
  type: DELETE_PHONE_START,
  payload: {
    id
  }
})

export const deletePhoneSuccess = ({ id }) => ({
  type: DELETE_PHONE_SUCCESS,
  payload: {
    id
  }
})

export const deletePhoneFailure = ({ error }) => ({
  type: DELETE_PHONE_FAILURE,
  payload: {
    error
  }
})

export const deletePhoneAsync =
  ({ accountId, id }) =>
    (dispatch) => {
      const deletePhone = (_accountId, _id) => {
        dispatch(deletePhoneStart({ id: _id }))

        accountService
          .deletePhone({ accountId: _accountId, phoneId: _id })
          .then((result) => {
            dispatch(deletePhoneSuccess({ id: _id }))
            dispatch(openAppSnackbar({ message: intl.translate('account_phones_page__delete_success') }))
          })
          .catch((error) => {
            dispatch(deletePhoneFailure({ error: intl.translate('account_phones_page__delete_error') }))
            dispatch(
              openAppSnackbar({
                message: intl.getServerError(error.response.data) || intl.translate('account_phones_page__delete_error'),
                actionText: intl.translate('general__retry'),
                actionFunction: () => {
                  dispatch(closeAppSnackbar())
                  deletePhone(_accountId, _id)
                }
              })
            )
          })
      }

      deletePhone(accountId, id)
    }

export const showNewPhoneDialog = () => ({
  type: SHOW_NEW_PHONE_DIALOG
})

export const hideNewPhoneDialog = () => ({
  type: HIDE_NEW_PHONE_DIALOG
})

export const newPhoneChange = ({ phone }) => ({
  type: NEW_PHONE_CHANGE,
  payload: {
    phone
  }
})

export const createNewPhoneStart = () => ({
  type: CREATE_NEW_PHONE_START
})

export const createNewPhoneSuccess = ({ phone }) => ({
  type: CREATE_NEW_PHONE_SUCCESS,
  payload: {
    phone
  }
})

export const createNewPhoneFailure = ({ error }) => ({
  type: CREATE_NEW_PHONE_FAILURE,
  payload: {
    error
  }
})

export const createNewPhoneAsync =
  ({ accountId, phone }) =>
    (dispatch) => {
      const createNewPhone = (_accountId, _phone) => {
        dispatch(createNewPhoneStart())

        accountService
          .createPhone({ accountId: _accountId, phone: _phone })
          .then((result) => {
            dispatch(createNewPhoneSuccess({ phone: result.data }))
            dispatch(hideNewPhoneDialog())
            dispatch(openAppSnackbar({ message: intl.translate('account_phones_page__create_success') }))
            if (result.data.phoneNumberConfirmed === false) {
              dispatch(openContactConfirmationDialog({ phoneNumber: intl.getDialCodeByCountryCode(_phone.countryCode) + _phone.phoneNumber }))
            }
          })
          .catch((error) => {
            dispatch(createNewPhoneFailure({ error: intl.translate('account_phones_page__create_error') }))
            dispatch(
              openAppSnackbar({
                message: intl.getServerError(error.response.data) || intl.translate('account_phones_page__create_error'),
                actionText: intl.translate('general__retry'),
                actionFunction: () => {
                  dispatch(closeAppSnackbar())
                  createNewPhone(_accountId, _phone)
                }
              })
            )
          })
      }

      createNewPhone(accountId, phone)
    }
