import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(
  (theme) => ({
    wrapper: {
      '&._centered_container_': {
        maxWidth: '900px'
      }
    },
    title: {
      marginBottom: theme.spacing(4)
    },
    'question-paragraph': {
      ...theme.typography.body1,
      marginBottom: theme.spacing(1)
    },
    'answer-paragraph': {
      ...theme.typography.body1,
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(4)
    },
    'answer-middle-paragraph': {
      ...theme.typography.body1,
      marginTop: theme.spacing(1),
      marginBottom: 0
    },
    iframe: {
      width: '100%',
      border: 'none',
      borderRadius: theme.shape.borderRadius,
      maxHeight: '65vh',
      [theme.breakpoints.down('sm')]: {
        maxHeight: '60vh',
      }
    }
  }),
  { name: 'faq-page-' }
)

export default useStyles
