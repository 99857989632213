import intl from './intl';

const validate = (() => {
  const isValidEmail = (email) => {
    if (typeof email !== 'string') {
      return false
    }

    const emailRegex = /^([A-Za-z0-9_\-.+'])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,63})$/
    return emailRegex.test(email)
  }

  const isValidPassword = (password) => {
    if (typeof password !== 'string') {
      return false;
    }

    const _companyData = window.companyData || {};
    const minLength = _companyData.pass_length || 12;
    const specialCharacterRequired = _companyData.pass_required_special_char || false;

    const passwordLengthIsValid = password.length >= minLength;
    const passwordHasNoSpaces = password.indexOf(' ') === -1;
    const passwordHasNumbers = /[0-9]/.test(password);
    const passwordHasLowercaseLetters = /[a-z]/.test(password);
    const passwordHasUppercaseLetters = /[A-Z]/.test(password);
    const specialCharacterCheck = specialCharacterRequired ? /[^a-zA-Z0-9]/.test(password) : true;
    return passwordLengthIsValid && passwordHasNoSpaces && passwordHasNumbers && passwordHasLowercaseLetters && passwordHasUppercaseLetters && specialCharacterCheck;
  }

  const getInvalidPasswordError = () => {
    const _companyData = window.companyData || {};
    if (_companyData.pass_required_special_char) {
      return intl.translate('general__invalid_password_special_characters', { minLength: _companyData.pass_length || 12 });
    } else {
      return intl.translate('general__invalid_password', { minLength: _companyData.pass_length || 12 });
    }
  }

  const isValidPhoneNumber = (phoneNumber, { countryCode }) => {
    const _countryCode = typeof countryCode === 'string' ? countryCode.toUpperCase() : ''

    if (!phoneNumber) {
      return false
    } else if (_countryCode === 'US' || _countryCode === 'CA') {
      // American and Canadian phone numbers are formatted to (XXX) XXX-XXXX
      // This formatting causes validation errors, so we'll only check that the number has 10 digits (ignoring the format)
      const numericPhoneNumber = phoneNumber.replace(/\D/g, '') // Ignore the format by removing non-numeric characters
      return numericPhoneNumber.length === 10
    } else {
      const nonNumericCharactersRegex = /\D/g
      return !nonNumericCharactersRegex.test(phoneNumber)
    }
  }

  return {
    isValidEmail,
    isValidPassword,
    getInvalidPasswordError,
    isValidPhoneNumber
  }
})()

export default validate
