import {
  ACCOUNT_INDIVIDUALS_CHANGE,
  LOAD_ACCOUNT_INDIVIDUALS_START,
  LOAD_ACCOUNT_INDIVIDUALS_SUCCESS,
  LOAD_ACCOUNT_INDIVIDUALS_FAILURE,
  UPDATE_INDIVIDUAL_START,
  UPDATE_INDIVIDUAL_SUCCESS,
  UPDATE_INDIVIDUAL_FAILURE,
  DELETE_INDIVIDUAL_START,
  DELETE_INDIVIDUAL_SUCCESS,
  DELETE_INDIVIDUAL_FAILURE,
  SHOW_NEW_INDIVIDUAL_DIALOG,
  HIDE_NEW_INDIVIDUAL_DIALOG,
  NEW_INDIVIDUAL_CHANGE,
  CREATE_NEW_INDIVIDUAL_START,
  CREATE_NEW_INDIVIDUAL_SUCCESS,
  CREATE_NEW_INDIVIDUAL_FAILURE
} from '../actions/accountIndividualsAction'

const initialState = {
  individuals: [],

  individualsLoading: true,
  individualsError: '',

  updateIndividualLoading: false,
  updateIndividualError: '',

  deleteIndividualLoading: false,
  deleteIndividualError: '',

  newIndividualDialogIsOpen: false,
  newIndividual: { firstName: '', lastName: '', email: '', countryCode: '', phoneNumber: '', phoneNumberSms: true },
  createNewIndividualLoading: false,
  createNewIndividualError: ''
}

const reducer = (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case ACCOUNT_INDIVIDUALS_CHANGE: {
      return {
        ...state,
        individuals: payload.individuals
      }
    }
    case LOAD_ACCOUNT_INDIVIDUALS_START: {
      return {
        ...state,
        individualsLoading: true,
        individualsError: ''
      }
    }
    case LOAD_ACCOUNT_INDIVIDUALS_SUCCESS: {
      return {
        ...state,
        individuals: payload.individuals,
        individualsLoading: false,
        individualsError: ''
      }
    }
    case LOAD_ACCOUNT_INDIVIDUALS_FAILURE: {
      return {
        ...state,
        individualsLoading: false,
        individualsError: payload.error
      }
    }
    case UPDATE_INDIVIDUAL_START: {
      return {
        ...state,
        updateIndividualLoading: payload.id,
        updateIndividualError: ''
      }
    }
    case UPDATE_INDIVIDUAL_SUCCESS: {
      return {
        ...state,
        updateIndividualLoading: false,
        updateIndividualError: ''
      }
    }
    case UPDATE_INDIVIDUAL_FAILURE: {
      return {
        ...state,
        updateIndividualLoading: false,
        updateIndividualError: payload.error
      }
    }
    case DELETE_INDIVIDUAL_START: {
      return {
        ...state,
        deleteIndividualLoading: payload.id,
        deleteIndividualError: ''
      }
    }
    case DELETE_INDIVIDUAL_SUCCESS: {
      const updatedIndividuals = [...state.individuals]
      const numIndividuals = updatedIndividuals.length
      for (let i = 0; i < numIndividuals; i++) {
        if (payload.id === updatedIndividuals[i].id) {
          updatedIndividuals.splice(i, 1) // remove individual
          break
        }
      }
      return {
        ...state,
        individuals: updatedIndividuals,
        deleteIndividualLoading: false,
        deleteIndividualError: ''
      }
    }
    case DELETE_INDIVIDUAL_FAILURE: {
      return {
        ...state,
        deleteIndividualLoading: false,
        deleteIndividualError: payload.error
      }
    }
    case SHOW_NEW_INDIVIDUAL_DIALOG: {
      return {
        ...state,
        newIndividual: { firstName: '', lastName: '', email: '', countryCode: '', phoneNumber: '', phoneNumberSms: true },
        newIndividualDialogIsOpen: true
      }
    }
    case HIDE_NEW_INDIVIDUAL_DIALOG: {
      return {
        ...state,
        newIndividualDialogIsOpen: false
      }
    }
    case NEW_INDIVIDUAL_CHANGE: {
      return {
        ...state,
        newIndividual: payload.individual
      }
    }
    case CREATE_NEW_INDIVIDUAL_START: {
      return {
        ...state,
        createNewIndividualLoading: true,
        createNewIndividualError: ''
      }
    }
    case CREATE_NEW_INDIVIDUAL_SUCCESS: {
      // Add new individual to our array of individuals
      const updatedIndividuals = [...state.individuals]
      updatedIndividuals.push(payload.individual)
      return {
        ...state,
        individuals: updatedIndividuals,
        createNewIndividualLoading: false,
        createNewIndividualError: ''
      }
    }
    case CREATE_NEW_INDIVIDUAL_FAILURE: {
      return {
        ...state,
        createNewIndividualLoading: false,
        createNewIndividualError: payload.error
      }
    }
    default: {
      return state
    }
  }
}

export default reducer
