import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(
  (theme) => ({
    form: {
      marginTop: theme.spacing(4)
    },
    'submit-btn-wrapper': {
      paddingTop: theme.spacing(2)
    }
  }),
  { name: 'account-deregister-page-' }
)

export default useStyles
