import intl from '../../../helper/intl'

import useStyles from './ProgressOverlay.styles'

const ProgressOverlay = ({ hidden, transparent }) => {
  const classes = useStyles()

  return (
    <div
      className={
        classes.wrapper + (transparent ? ' ' + classes['wrapper-transparent'] : '') + (hidden ? ' ' + classes['wrapper-hidden'] : '')
      }
    >
      <div className={classes['progress-wrapper']}>
        <div
          className="MuiCircularProgress-root MuiCircularProgress-colorPrimary MuiCircularProgress-indeterminate"
          aria-label={intl.translate('general__loading')}
          role="progressbar"
          style={{ width: '36px', height: '36px' }}
        >
          <svg className="MuiCircularProgress-svg" viewBox="22 22 44 44">
            <title>{intl.translate('general__loading')}</title>
            <circle
              className="MuiCircularProgress-circle MuiCircularProgress-circleIndeterminate"
              cx="44"
              cy="44"
              r="20.2"
              fill="none"
              strokeWidth="3.6"
            ></circle>
          </svg>
        </div>
      </div>
    </div>
  )
}

export default ProgressOverlay
