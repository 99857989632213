import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(
  (theme) => ({
    wrapper: {
      position: 'relative'
    },
    legend: {
      marginTop: 0,
      marginBottom: 0,
      paddingTop: theme.spacing(2),
      paddingRight: theme.spacing(6),
      paddingBottom: theme.spacing(2)
    },
    'delete-btn': {
      position: 'absolute',
      top: 0,
      right: 0
    }
  }),
  { name: 'phone-fieldset-' }
)

export default useStyles
