const broadcast = (() => {
  // Contains all event IDs and attached functions
  const _allEvents = {}

  /*
   * Useful for debugging
   */
  const getAllEvents = () => {
    return _allEvents
  }

  /*
   * Triggers an event, optionally specifying parameters for the event handler
   *
   * Examples:
   * broadcast.trigger('account-details-updated');
   * broadcast.trigger('account-details-updated', { 'flag': true });
   */
  const trigger = (eventId, params) => {
    const eventHandlers = _allEvents[eventId]

    if (!eventHandlers) {
      return
    }

    for (let i = 0; i < eventHandlers.length; i++) {
      // Fire all event handlers for the given event
      eventHandlers[i](params)
    }
  }

  /*
   * Subscribes a handler function to an event
   *
   * Examples:
   * broadcast.on('account-details-updated', functionName);
   * broadcast.on('account-details-updated', (params) => {
   *   if (params['flag'] === true) {
   *     doSomething();
   *   }
   * });
   */
  const on = (eventId, handler) => {
    _allEvents[eventId] = _allEvents[eventId] || []
    const eventHandlers = _allEvents[eventId]

    // Check if the event handler was already attached. In this case do nothing.
    for (let i = 0; i < eventHandlers.length; i++) {
      const handlersAreEqual = eventHandlers[i] === handler
      const handlersAreNotAnonymous =
        eventHandlers[i].name && eventHandlers[i].name !== 'anonymous' && handler.name && handler.name !== 'anonymous'
      const handlersHaveTheSameName = handlersAreNotAnonymous && eventHandlers[i].name === handler.name
      if (handlersAreEqual || handlersHaveTheSameName) {
        return
      }
    }

    // Attach the function
    eventHandlers.push(handler)
  }

  /*
   * Unsubscribes a handler function that was attached to an event.
   * If no handler is provided, all handlers attached to the event will be removed.
   *
   * Examples:
   * broadcast.off('account-details-updated', functionName);
   * broadcast.off('account-details-updated');
   */
  const off = (eventId, handler) => {
    const eventHandlers = _allEvents[eventId]
    const handlerName = typeof handler === 'function' ? handler.name : handler

    if (!eventHandlers) {
      return
    }

    if (!handler) {
      // Detach all event handlers if no handler function is specified
      eventHandlers.length = 0
    } else {
      for (let i = 0; i < eventHandlers.length; i++) {
        const handlersAreEqual = eventHandlers[i] === handler
        const handlersAreNotAnonymous =
          eventHandlers[i].name && eventHandlers[i].name !== 'anonymous' && handlerName && handlerName !== 'anonymous'
        const handlersHaveTheSameName = handlersAreNotAnonymous && eventHandlers[i].name === handlerName
        if (handlersAreEqual || handlersHaveTheSameName) {
          eventHandlers.splice(i, 1)
          break
        }
      }
    }
  }

  return {
    getAllEvents,
    trigger,
    on,
    off
  }
})()

export default broadcast
