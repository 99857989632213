import axios from 'axios'

import utils from '../helper/utils'
import config from './config'
import auth from '../helper/auth'

// const nominatimApiUrl = 'https://nominatim.openstreetmap.org'

const locationService = (() => {
  /* const nominatimGeocode = ({ line1, line2, city, state, postcode, country }) => {
    let query = ''

    if (line1) {
      query += '&street=' + utils.encodeUrlParam(line1)
    }
    if (city) {
      query += '&city=' + utils.encodeUrlParam(city)
    }
    if (postcode) {
      query += '&postalcode=' + utils.encodeUrlParam(postcode)
    }
    // if (state) {
    //   query += '&state=' + utils.encodeUrlParam(state);
    // }
    if (country) {
      query += '&country=' + utils.encodeUrlParam(country)
    }

    return axios.get(nominatimApiUrl + '/search?format=json' + query)
    .then(result => {
      const match = result.data && result.data.length ? result.data[0] : null

      if (match) {
        result.location = { longitude: match.lon, latitude: match.lat }
      }

      return result
    })
  } */

  const googleMapsAutocomplete = async({ input }) => {
    const query = `?address=${input}`;

    const accessToken = await auth.ensureToken();
    const authHeader = 'Bearer ' + accessToken;

    return axios.get(config.apiUrl + '/geocodesGoogle/' + query, { headers: { Authorization: authHeader } })
      .then((result) => {
        const match = result.data && result.data.length ? result.data[0] : null;
        result.location = parseGoogleMapsLocation(match);
        return result.data;
      });
  }

  const googleMapsGeocode = async ({ line1, line2, city, state, postcode, country }) => {
    const params = [line1, city, state, postcode, country]
      .filter(Boolean)
      .map((param) => utils.encodeUrlParam(param))
      .join(', ');

    const query = `?address=${params}`;
    
    const accessToken = await auth.ensureToken();
    const authHeader = 'Bearer ' + accessToken;

    return axios.get(config.apiUrl + '/geocodesGoogle/' + query, { headers: { Authorization: authHeader } })
      .then((result) => {
        const match = result.data && result.data.length ? result.data[0] : null;
        result.location = parseGoogleMapsLocation(match);
        return result;
      });
  }

  const googleMapsReverseGeocode = async ({ longitude, latitude }) => {
    const query = longitude && latitude ? `?latlng=${latitude},${longitude}` : '';

    const accessToken = await auth.ensureToken();
    const authHeader = 'Bearer ' + accessToken;

    return axios.get(config.apiUrl + '/geocodesGoogle/' + query, { headers: { Authorization: authHeader } })
      .then((result) => {
        const match = result.data && result.data.length ? result.data[0] : null;
        result.location = parseGoogleMapsLocation(match);
        return result;
      });
  }

  const parseGoogleMapsLocation = (locationObj) => {
    let parsedLocation = {}

    if (locationObj) {
      parsedLocation = {
        longitude: locationObj.geometry.location.lng,
        latitude: locationObj.geometry.location.lat,
        line1: '', // Line 1 is set below as it requires a bit more work
        line2: '',
        city: getGoogleMapsAddressComponent(locationObj.addressComponents, 'LOCALITY'),
        state: getGoogleMapsAddressComponent(locationObj.addressComponents, 'ADMINISTRATIVE_AREA_LEVEL_1'),
        postcode: getGoogleMapsAddressComponent(locationObj.addressComponents, 'POSTAL_CODE'),
        country: getGoogleMapsAddressComponent(locationObj.addressComponents, 'COUNTRY')
      }

      const streetAddress = getGoogleMapsAddressComponent(locationObj.addressComponents, 'STREET_ADDRESS')
      const streetNumber = getGoogleMapsAddressComponent(locationObj.addressComponents, 'STREET_NUMBER')
      const streetName = getGoogleMapsAddressComponent(locationObj.addressComponents, 'ROUTE')
      const neighborhood = getGoogleMapsAddressComponent(locationObj.addressComponents, 'NEIGHBORHOOD')

      if (streetAddress) {
        parsedLocation.line1 = streetAddress
      }
      if (streetNumber && streetName) {
        parsedLocation.line1 = streetNumber + ' ' + streetName
      } else {
        parsedLocation.line1 = streetNumber || streetName || neighborhood
      }
    }

    return parsedLocation
  }

  const getGoogleMapsAddressComponent = (addressComponents, addressComponentName, getShortName = false) => {
    const length = addressComponents.length

    for (let i = 0; i < length; i++) {
      const addressComponent = addressComponents[i]
      if (addressComponent.types && addressComponent.types.indexOf(addressComponentName) > -1) {
        return getShortName ? addressComponent.shortName : addressComponent.longName
      }
    }

    // if the code reaches this point it means we couldn't find a component with the provided addressComponentName
    return ''
  }

  return {
    geocode: googleMapsGeocode,
    reverseGeocode: googleMapsReverseGeocode,
    autocompleteAddress: googleMapsAutocomplete,
    parseLocation: parseGoogleMapsLocation,
  }
})()

export default locationService
