import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(
  (theme) => ({
    'content-wrapper': {
      // prevent overflow problems when the content uses negative margins
      width: '100%',
      overflow: 'initial'
    }
  }),
  { name: 'basic-dialog-' }
)

export default useStyles
