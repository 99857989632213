import authService from '../service/authService'

export const LANGUAGE_CHANGE = 'LANGUAGE_CHANGE'

export const AVAILABLE_LANGUAGES_CHANGE = 'AVAILABLE_LANGUAGES_CHANGE'
export const LOAD_AVAILABLE_LANGUAGES_START = 'LOAD_AVAILABLE_LANGUAGES_START'
export const LOAD_AVAILABLE_LANGUAGES_SUCCESS = 'LOAD_AVAILABLE_LANGUAGES_SUCCESS'
export const LOAD_AVAILABLE_LANGUAGES_FAILURE = 'LOAD_AVAILABLE_LANGUAGES_FAILURE'

export const languageChange = ({ language }) => ({
  type: LANGUAGE_CHANGE,
  payload: {
    language
  }
})

export const availableLanguagesChange = ({ languages }) => ({
  type: AVAILABLE_LANGUAGES_CHANGE,
  payload: {
    languages
  }
})

export const loadAvailableLanguagesStart = () => ({
  type: LOAD_AVAILABLE_LANGUAGES_START
})

export const loadAvailableLanguagesSuccess = ({ languages }) => ({
  type: LOAD_AVAILABLE_LANGUAGES_SUCCESS,
  payload: {
    languages
  }
})

export const loadAvailableLanguagesFailure = ({ error }) => ({
  type: LOAD_AVAILABLE_LANGUAGES_FAILURE,
  payload: {
    error
  }
})

export const loadAvailableLanguagesAsync = () => (dispatch) => {
  dispatch(loadAvailableLanguagesStart())

  authService
    .getCompanyData()
    .then((result) => {
      const availableLanguages = []
      const numLanguages = result.data.lang ? result.data.lang.length : 0
      for (let i = 0; i < numLanguages; i++) {
        availableLanguages.push({
          name: result.data.lang[i].language,
          value: result.data.lang[i].code
        })
      }
      dispatch(loadAvailableLanguagesSuccess({ languages: availableLanguages }))
    })
    .catch((error) => {
      dispatch(loadAvailableLanguagesFailure({ error: '' }))
      console.error(error)
    })
}
