import { Typography } from '@mui/material'

import useStyles from './PageTitle.styles'

const PageTitle = ({ className, children, ...otherProperties }) => {
  const classes = useStyles();

  return (
    <Typography
      id="page-title"
      className={classes.wrapper + (className ? ' ' + className : '')}
      variant="h1"
      gutterBottom={true}
      tabIndex={-1}
      {...otherProperties}
    >
      {children}
    </Typography>
  )
}

export default PageTitle
