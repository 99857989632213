import { combineReducers } from 'redux'
import authReducer from './authReducer'
import intlReducer from './intlReducer'
import navigationMenuReducer from './navigationMenuReducer'
import notificationsReducer from './notificationsReducer'
import accountDetailsReducer from './accountDetailsReducer'
import accountEmailsReducer from './accountEmailsReducer'
import accountPhonesReducer from './accountPhonesReducer'
import accountLocationsReducer from './accountLocationsReducer'
import accountIndividualsReducer from './accountIndividualsReducer'
import accountNotificationsReducer from './accountNotificationsReducer'

export default combineReducers({
  auth: authReducer,
  intl: intlReducer,
  navigationMenu: navigationMenuReducer,
  notifications: notificationsReducer,
  accountDetails: accountDetailsReducer,
  accountEmails: accountEmailsReducer,
  accountPhones: accountPhonesReducer,
  accountLocations: accountLocationsReducer,
  accountIndividuals: accountIndividualsReducer,
  accountNotifications: accountNotificationsReducer
})
