import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(
  (theme) => ({
    wrapper: {
      flex: 1,
      'body[data-logged-in="false"] &': {
        flex: '100%'
      },
      [theme.breakpoints.down('md')]: {
        flex: '100%'
      },

      // This rule is really important to prevent elements inside the body to overflow the parent
      // For example tables with a lot of data, tabs, etc.
      minWidth: 0
    },

    'header-spacing': theme.mixins.toolbar,

    banner: {
      padding: theme.spacing(2),
      minHeight: theme.spacing(8)
    },

    'banner-close': {
      float: 'right',
      marginTop: theme.spacing(-1),
      marginRight: theme.spacing(-1)
    },

    notifier: {
      display: 'flex',
      maxWidth: '600px',
      margin: '20px auto 0 auto',
      backgroundColor: '#FFA726',
      borderRadius: '5px',
      padding: '10px',
      color: '#fff',
      flexDirection: 'column'
    },
    'notifier-icon-wrapper': {
      display: 'flex',
      justifyContent: 'start',
      alignItems: 'center',
      gap: '10px'
    },
    link: {
      color: '#fff'
    },
    content: {
      padding: theme.spacing(4),
      paddingBottom: theme.spacing(8),

      [theme.breakpoints.down('md')]: {
        padding: theme.spacing(2),
        paddingBottom: theme.spacing(4)
      }
    },
    'banner-app': {
      position: 'fixed',
      bottom: 0,
      zIndex: 1300,
      width: '100%',
      flex: 1,
      paddingLeft: '15px',
      paddingRight: '15px',
      display: 'flex',
      justifyContent: 'space-around',
      alignItems: 'center',
      lineHeight: 1,
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.primary.contrastText
    },
    'banner-app-text-container': {
      maxWidth: '30ch'
    },
    'banner-app-text': {
      fontSize: '1.2rem'
    },
    'banner-app-button': {
      padding: '10px',
      borderRadius: '10px',
      backgroundColor: '#fff',
      color: theme.palette.primary.light,
      textDecoration: 'none'
    },
    'banner-app-close': {
      position: 'absolute',
      top: '0',
      right: '0',
      padding: '5px'
    }
  }),
  { name: 'app-body-' }
)

export default useStyles
