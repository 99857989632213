import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(
  (theme) => ({
    title: {
      marginBottom: theme.spacing(4)
    },
    'back-to-login-paragraph': {
      textAlign: 'center',
      marginBottom: theme.spacing(4)
    },
    'back-to-login-final-paragraph': {
      textAlign: 'center',
      marginTop: theme.spacing(4)
    },
    'final-paragraph': {
      marginBottom: theme.spacing(2)
    },
    list: {
      ...theme.typography.body1,
      marginBottom: theme.spacing(2)
    }
  }),
  { name: 'accessibility-statement-page-' }
)

export default useStyles
