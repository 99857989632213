import { useEffect } from 'react'
import { Paper, Grid, Box, Typography } from '@mui/material'
import PageTitle from '../common/PageTitle'
import TextInput from '../common/TextInput'
import ButtonWithProgress from '../common/ButtonWithProgress'

import authService from '../../service/authService'
import intl from '../../helper/intl'
import utils from '../../helper/utils'
import validate from '../../helper/validate'

import { useHistory } from 'react-router-dom'

import { useSelector, useDispatch } from 'react-redux'
import { resetPasswordStart, resetPasswordSuccess, resetPasswordFailure, passwordResetDataChange } from '../../actions/authAction'
import { openAppSnackbar, closeAppSnackbar } from '../../actions/notificationsAction'

const PasswordResetPage = () => {
  const companyData = useSelector((state) => state.auth.companyData)
  const resetPasswordLoading = useSelector((state) => state.auth.resetPasswordLoading)
  const passwordResetData = useSelector((state) => state.auth.passwordResetData)
  const history = useHistory()
  const dispatch = useDispatch()

  useEffect(() => {
    document.title = intl.translate('password_reset_page__title') + ' | ' + (companyData.portal_title || intl.translate('app_header__title'))
  }, [companyData])

  const onFormDataChange = (paramName, paramValue) => {
    const newFormData = { ...passwordResetData }
    newFormData[paramName] = paramValue
    dispatch(passwordResetDataChange({ data: newFormData }))
  }

  const validateFormFields = (newPassword, newPasswordConfirm) => {
    const newPasswordIsValid = validate.isValidPassword(newPassword)
    const newPasswordConfirmIsValid = newPasswordConfirm === newPassword

    return newPasswordIsValid && newPasswordConfirmIsValid
  }

  const onFormSubmit = (event) => {
    event.preventDefault()

    dispatch(resetPasswordStart())
    authService
      .resetPassword({ newPassword: passwordResetData.newPassword, token: utils.getUrlParam('token') })
      .then((result) => {
        dispatch(resetPasswordSuccess())
        dispatch(
          openAppSnackbar({
            message: intl.translate('password_reset_page__confirmation_text'),
            actionText: intl.translate('password_reset_page__confirmation_action'),
            actionFunction: () => {
              dispatch(closeAppSnackbar())
              history.push('/')
            }
          })
        )
      })
      .catch((error) => {
        dispatch(resetPasswordFailure({ error: intl.translate('password_reset_page__error') }))
        dispatch(
          openAppSnackbar({
            message: intl.getServerError(error.response.data) || intl.translate('password_reset_page__error'),
          })
        )
      })
  }

  return (
    <>
      <Paper className="_centered_container_">
        <PageTitle>{intl.translate('password_reset_page__title')}</PageTitle>

        <Box paddingTop={2} paddingBottom={2}>
          <Typography variant="body1" gutterBottom>
            {(companyData?.pass_required_special_char) ? (
              intl.translate('password_reset_page__password_rules_special_characters', { minLength: companyData.pass_length || 12 })
            ) : (
              intl.translate('password_reset_page__password_rules', { minLength: companyData.pass_length || 12 })
            )}
          </Typography>
        </Box>

        <form autoComplete="off" onSubmit={onFormSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextInput
                id="login-page--password"
                type="password"
                label={intl.translate('general__password_label')}
                disabled={resetPasswordLoading}
                value={passwordResetData.newPassword}
                onChange={(event) => onFormDataChange('newPassword', event.target.value)}
                isValid={validate.isValidPassword}
                errorMessage={validate.getInvalidPasswordError()}
              />
            </Grid>
            <Grid item xs={12}>
              <TextInput
                id="login-page--password-confirm"
                type="password"
                label={intl.translate('general__password_confirm_label')}
                disabled={resetPasswordLoading}
                value={passwordResetData.newPasswordConfirm}
                onChange={(event) => onFormDataChange('newPasswordConfirm', event.target.value)}
                isValid={(value) => value === passwordResetData.newPassword}
                errorMessage={intl.translate('general__invalid_password_confirm')}
              />
            </Grid>
            <Grid item xs={12}>
              <div className="_centered_btn_container_">
                <ButtonWithProgress
                  type="submit"
                  disabled={!validateFormFields(passwordResetData.newPassword, passwordResetData.newPasswordConfirm)}
                  showProgress={resetPasswordLoading}
                  variant="contained"
                  color={window.app.theme.palette.button ? 'button' : 'primary'}
                  size="large"
                >
                  {intl.translate('password_reset_page__submit_btn')}
                </ButtonWithProgress>
              </div>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </>
  )
}

export default PasswordResetPage
