import accountService from '../service/accountService'

export const LOAD_ACCOUNT_NOTIFICATIONS_START = 'LOAD_ACCOUNT_NOTIFICATIONS_START'
export const LOAD_ACCOUNT_NOTIFICATIONS_SUCCESS = 'LOAD_ACCOUNT_NOTIFICATIONS_SUCCESS'
export const LOAD_ACCOUNT_NOTIFICATIONS_FAILURE = 'LOAD_ACCOUNT_NOTIFICATIONS_FAILURE'

export const NOTIFICATION_TYPES_CHANGE = 'NOTIFICATION_TYPES_CHANGE'
export const NOTIFICATION_CHANNELS_CHANGE = 'NOTIFICATION_CHANNELS_CHANGE'
export const WEATHER_TYPES_CHANGE = 'WEATHER_TYPES_CHANGE'

export const UPDATE_NOTIFICATION_TYPES_START = 'UPDATE_NOTIFICATION_TYPES_START'
export const UPDATE_NOTIFICATION_TYPES_SUCCESS = 'UPDATE_NOTIFICATION_TYPES_SUCCESS'
export const UPDATE_NOTIFICATION_TYPES_FAILURE = 'UPDATE_NOTIFICATION_TYPES_FAILURE'

export const UPDATE_NOTIFICATION_CHANNELS_START = 'UPDATE_NOTIFICATION_CHANNELS_START'
export const UPDATE_NOTIFICATION_CHANNELS_SUCCESS = 'UPDATE_NOTIFICATION_CHANNELS_SUCCESS'
export const UPDATE_NOTIFICATION_CHANNELS_FAILURE = 'UPDATE_NOTIFICATION_CHANNELS_FAILURE'

export const UPDATE_WEATHER_TYPES_START = 'UPDATE_WEATHER_TYPES_START'
export const UPDATE_WEATHER_TYPES_SUCCESS = 'UPDATE_WEATHER_TYPES_SUCCESS'
export const UPDATE_WEATHER_TYPES_FAILURE = 'UPDATE_WEATHER_TYPES_FAILURE'

export const loadAccountNotificationsStart = () => ({
  type: LOAD_ACCOUNT_NOTIFICATIONS_START
})

export const loadAccountNotificationsSuccess = ({ types, channels, weatherTypes }) => ({
  type: LOAD_ACCOUNT_NOTIFICATIONS_SUCCESS,
  payload: {
    types,
    channels,
    weatherTypes
  }
})

export const loadAccountNotificationsFailure = ({ error }) => ({
  type: LOAD_ACCOUNT_NOTIFICATIONS_FAILURE,
  payload: {
    error
  }
})

export const loadAccountNotificationsAsync =
  ({ accountId }) =>
    (dispatch) => {
      dispatch(loadAccountNotificationsStart())

      accountService
        .getAllNotifications({ accountId })
        .then((result) => {
          const notificationTypesRaw = result.data.notificationTypes || []
          const notificationTypes = []
          for (let i = 0; i < notificationTypesRaw.length; i++) {
            notificationTypes.push({
              value: notificationTypesRaw[i].id,
              checked: notificationTypesRaw[i].active || false,
              label: notificationTypesRaw[i].name
            })
          }

          const notificationChannelsRaw = result.data.notificationChannels || []
          const notificationChannels = []
          for (let i = 0; i < notificationChannelsRaw.length; i++) {
            notificationChannels.push({
              value: notificationChannelsRaw[i].id,
              checked: notificationChannelsRaw[i].active || false,
              label: notificationChannelsRaw[i].name,
              sequenceNumber: notificationChannelsRaw[i].sequenceNumber
            })
          }

          const weatherTypesRaw = result.data.weather_categories || []
          const weatherTypes = []
          for (let i = 0; i < weatherTypesRaw.length; i++) {
            weatherTypes.push({
              id: weatherTypesRaw[i].id,
              checked: weatherTypesRaw[i].active,
              label: weatherTypesRaw[i].name,
              parent_id: weatherTypesRaw[i].parent_id
            })
          }

          dispatch(loadAccountNotificationsSuccess({ types: notificationTypes, channels: notificationChannels, weatherTypes }))
        })
        .catch((error) => {
          dispatch(loadAccountNotificationsFailure({ error: '' }))
          console.error(error)
        })
    }

export const notificationTypesChange = ({ types }) => ({
  type: NOTIFICATION_TYPES_CHANGE,
  payload: {
    types
  }
})

export const notificationChannelsChange = ({ channels }) => ({
  type: NOTIFICATION_CHANNELS_CHANGE,
  payload: {
    channels
  }
})

export const weatherTypesChange = ({ weatherTypes }) => ({
  type: WEATHER_TYPES_CHANGE,
  payload: {
    weatherTypes
  }
})

export const updateNotificationTypesStart = () => ({
  type: UPDATE_NOTIFICATION_TYPES_START
})

export const updateNotificationTypesSuccess = () => ({
  type: UPDATE_NOTIFICATION_TYPES_SUCCESS
})

export const updateNotificationTypesFailure = ({ error }) => ({
  type: UPDATE_NOTIFICATION_TYPES_FAILURE,
  payload: {
    error
  }
})

export const updateNotificationChannelsStart = () => ({
  type: UPDATE_NOTIFICATION_CHANNELS_START
})

export const updateNotificationChannelsSuccess = () => ({
  type: UPDATE_NOTIFICATION_CHANNELS_SUCCESS
})

export const updateNotificationChannelsFailure = ({ error }) => ({
  type: UPDATE_NOTIFICATION_CHANNELS_FAILURE,
  payload: {
    error
  }
})

export const updateWeatherTypesStart = () => ({
  type: UPDATE_WEATHER_TYPES_START
})

export const updateWeatherTypesSuccess = () => ({
  type: UPDATE_WEATHER_TYPES_SUCCESS
})

export const updateWeatherTypesFailure = ({ error }) => ({
  type: UPDATE_WEATHER_TYPES_FAILURE,
  payload: {
    error
  }
})
