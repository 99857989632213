import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(
  (theme) => ({
    tab: {
      // make the tabs take up the full width available on large screens
      flexGrow: 1,
      maxWidth: 'none'
    }
  }),
  { name: 'tabs-section-' }
)

export default useStyles
