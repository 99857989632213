import { IconButton, CircularProgress } from '@mui/material'

import useStyles from './IconButtonWithProgress.styles'

const IconButtonWithProgress = ({ className, showProgress, disabled, children, ...otherProperties }) => {
  const classes = useStyles()

  return (
    <div className={classes.wrapper + (className ? ' ' + className : '')}>
      <IconButton
        className={classes.button}
        {...otherProperties}
        disabled={showProgress || disabled /* showProgress overrides the disabled state of the button */}
      >
        {children}
      </IconButton>
      <CircularProgress className={classes.progress + (showProgress ? '' : ' _hidden_')} size={36} aria-hidden="true" />
    </div>
  )
}

export default IconButtonWithProgress
