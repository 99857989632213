import {
  ACCOUNT_PHONES_CHANGE,
  LOAD_ACCOUNT_PHONES_START,
  LOAD_ACCOUNT_PHONES_SUCCESS,
  LOAD_ACCOUNT_PHONES_FAILURE,
  UPDATE_PHONE_START,
  UPDATE_PHONE_SUCCESS,
  UPDATE_PHONE_FAILURE,
  DELETE_PHONE_START,
  DELETE_PHONE_SUCCESS,
  DELETE_PHONE_FAILURE,
  SHOW_NEW_PHONE_DIALOG,
  HIDE_NEW_PHONE_DIALOG,
  NEW_PHONE_CHANGE,
  CREATE_NEW_PHONE_START,
  CREATE_NEW_PHONE_SUCCESS,
  CREATE_NEW_PHONE_FAILURE
} from '../actions/accountPhonesAction'

const initialState = {
  phones: [],

  phonesLoading: true,
  phonesError: '',

  updatePhoneLoading: false,
  updatePhoneError: '',

  deletePhoneLoading: false,
  deletePhoneError: '',

  newPhoneDialogIsOpen: false,
  newPhone: { alias: '', countryCode: '', phoneNumber: '', phoneNumberSms: true },
  createNewPhoneLoading: false,
  createNewPhoneError: ''
}

const reducer = (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case ACCOUNT_PHONES_CHANGE: {
      return {
        ...state,
        phones: payload.phones
      }
    }
    case LOAD_ACCOUNT_PHONES_START: {
      return {
        ...state,
        phonesLoading: true,
        phonesError: ''
      }
    }
    case LOAD_ACCOUNT_PHONES_SUCCESS: {
      return {
        ...state,
        phones: payload.phones,
        phonesLoading: false,
        phonesError: ''
      }
    }
    case LOAD_ACCOUNT_PHONES_FAILURE: {
      return {
        ...state,
        phonesLoading: false,
        phonesError: payload.error
      }
    }
    case UPDATE_PHONE_START: {
      return {
        ...state,
        updatePhoneLoading: payload.id,
        updatePhoneError: ''
      }
    }
    case UPDATE_PHONE_SUCCESS: {
      return {
        ...state,
        updatePhoneLoading: false,
        updatePhoneError: ''
      }
    }
    case UPDATE_PHONE_FAILURE: {
      return {
        ...state,
        updatePhoneLoading: false,
        updatePhoneError: payload.error
      }
    }
    case DELETE_PHONE_START: {
      return {
        ...state,
        deletePhoneLoading: payload.id,
        deletePhoneError: ''
      }
    }
    case DELETE_PHONE_SUCCESS: {
      const updatedPhones = [...state.phones]
      const numPhones = updatedPhones.length
      for (let i = 0; i < numPhones; i++) {
        if (payload.id === updatedPhones[i].id) {
          updatedPhones.splice(i, 1) // remove phone
          break
        }
      }
      return {
        ...state,
        phones: updatedPhones,
        deletePhoneLoading: false,
        deletePhoneError: ''
      }
    }
    case DELETE_PHONE_FAILURE: {
      return {
        ...state,
        deletePhoneLoading: false,
        deletePhoneError: payload.error
      }
    }
    case SHOW_NEW_PHONE_DIALOG: {
      return {
        ...state,
        newPhone: { alias: '', countryCode: '', phoneNumber: '', phoneNumberSms: true },
        newPhoneDialogIsOpen: true
      }
    }
    case HIDE_NEW_PHONE_DIALOG: {
      return {
        ...state,
        newPhoneDialogIsOpen: false
      }
    }
    case NEW_PHONE_CHANGE: {
      return {
        ...state,
        newPhone: payload.phone
      }
    }
    case CREATE_NEW_PHONE_START: {
      return {
        ...state,
        createNewPhoneLoading: true,
        createNewPhoneError: ''
      }
    }
    case CREATE_NEW_PHONE_SUCCESS: {
      // Add new phone to our array of phones
      const updatedPhones = [...state.phones]
      updatedPhones.push(payload.phone)
      return {
        ...state,
        phones: updatedPhones,
        createNewPhoneLoading: false,
        createNewPhoneError: ''
      }
    }
    case CREATE_NEW_PHONE_FAILURE: {
      return {
        ...state,
        createNewPhoneLoading: false,
        createNewPhoneError: payload.error
      }
    }
    default: {
      return state
    }
  }
}

export default reducer
