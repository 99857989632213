import { useState } from 'react'

import { Typography } from '@mui/material'

import BasicDialog from '../common/BasicDialog'
import ButtonWithProgress from '../common/ButtonWithProgress'
import TextInput from '../common/TextInput'

import intl from '../../helper/intl'

import accountService from '../../service/accountService'

import { useDispatch, useSelector } from 'react-redux'
import { loadAllAccountDataAsync } from '../../actions/accountAction'
import { openAppSnackbar } from '../../actions/notificationsAction'

import useStyles from './ContactConfirmationDialog.styles'

const ContactConfirmationDialog = ({ email, phoneNumber, isOpen, onClose }) => {
  const accountId = useSelector((state) => state.auth.accountId)
  const [phoneConfirmationCode, setPhoneConfirmationCode] = useState('')
  const [sendingPhoneConfirmationCode, setSendingPhoneConfirmationCode] = useState(false)
  const dispatch = useDispatch()
  const classes = useStyles()

  const requestEmail = () => {
    accountService
      .requestEmailConfirmation({ email })
      .then((result) => {
        dispatch(openAppSnackbar({ message: intl.translate('contact_confirmation_dialog__email_resent') }))
      })
      .catch((error) => {
        dispatch(openAppSnackbar({ message: intl.translate('contact_confirmation_dialog__email_resent_error') }))
        console.error(error)
      })
  }

  const requestPhoneConfirmationCode = () => {
    accountService
      .requestPhoneNumberConfirmation({ phoneNumber: phoneNumber.replace(/\D/g, '') })
      .then((result) => {
        dispatch(openAppSnackbar({ message: intl.translate('contact_confirmation_dialog__phone_code_resent') }))
      })
      .catch((error) => {
        dispatch(openAppSnackbar({ message: intl.translate('contact_confirmation_dialog__phone_code_resent_error') }))
        console.error(error)
      })
  }

  const onPhoneConfirmationCodeChange = (event) => {
    const value = event.target.value
    const oldValue = phoneConfirmationCode
    if (value.match(/[^0-9]/g)) {
      // New value contains a non-numeric character
      setPhoneConfirmationCode(oldValue)
    } else {
      // New value only contains numeric characters
      setPhoneConfirmationCode(value)
    }
  }

  const onPhoneNumberFormSubmit = (event) => {
    event.preventDefault()

    setSendingPhoneConfirmationCode(true)

    accountService
      .confirmPhoneNumber({ phoneNumber, code: phoneConfirmationCode })
      .then((result) => {
        setPhoneConfirmationCode('')
        setSendingPhoneConfirmationCode(false)
        dispatch(loadAllAccountDataAsync({ accountId }))
        if (typeof onClose === 'function') {
          onClose()
        }
      })
      .catch((error) => {
        setPhoneConfirmationCode('')
        setSendingPhoneConfirmationCode(false)
        dispatch(openAppSnackbar({ message: intl.translate('contact_confirmation_dialog__phone_code_invalid') }))
        console.error(error)
      })
  }

  const actionButtons = [
    ...(email ? [{
      content: intl.translate('contact_confirmation_dialog__resend_email'),
      onClick: requestEmail,
    }] : []),
    ...(phoneNumber ? [{
      content: intl.translate('contact_confirmation_dialog__resend_phone_code'),
      onClick: requestPhoneConfirmationCode
    }] : []),
    {
      content: intl.translate('general__close'),
      onClick: onClose,
      variant: 'contained',
    },
  ];

  return (
    <BasicDialog
      id="contact-confirmation-dialog"
      isOpen={isOpen}
      title={intl.translate('contact_confirmation_dialog__title')}
      actionButtons={actionButtons}
      onClose={onClose}
    >
      {email && (
        <Typography variant="body1" gutterBottom={true}>
          {intl.translate('contact_confirmation_dialog__email_instructions', { email })}
        </Typography>
      )}

      {phoneNumber && (
        <form id="contact-confirmation-dialog--phone-number-form" onSubmit={onPhoneNumberFormSubmit}>
          <Typography gutterBottom={true}>{intl.translate('contact_confirmation_dialog__phone_instructions', { phoneNumber })}</Typography>
          <div className={classes['phone-confirmation-code-wrapper']}>
            <TextInput
              id="contact-confirmation-dialog--phone-confirmation-code"
              className={classes['phone-confirmation-code']}
              label={intl.translate('contact_confirmation_dialog__phone_code')}
              disabled={sendingPhoneConfirmationCode}
              value={phoneConfirmationCode}
              onChange={onPhoneConfirmationCodeChange}
            />
            <ButtonWithProgress
              type="submit"
              className={classes['phone-confirmation-code-button']}
              showProgress={sendingPhoneConfirmationCode}
            >
              {intl.translate('contact_confirmation_dialog__phone_code_button')}
            </ButtonWithProgress>
          </div>
        </form>
      )}
    </BasicDialog>
  )
}

export default ContactConfirmationDialog
