import { useState, useEffect, createElement, useMemo, useCallback } from 'react'
import { Drawer, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Tooltip, Chip, useMediaQuery } from '@mui/material'
import { Link as RouterLink, useLocation } from 'react-router-dom'
import { openAppSnackbar } from '../../actions/notificationsAction'

import { visuallyHidden } from '@mui/utils'

import intl from '../../helper/intl'
import utils from '../../helper/utils'

import { useSelector, useDispatch } from 'react-redux'
import { openNavigationMenu, toggleNavigationMenu } from '../../actions/navigationMenuAction'
import { logout } from '../../actions/authAction'

import useStyles from './NavigationMenu.styles'

const NavigationMenu = () => {
  const companyData = useSelector((state) => state.auth.companyData)
  const accountId = useSelector((state) => state.auth.accountId)
  const accountDetailsData = useSelector((state) => state.accountDetails.details)
  const accountLocationsData = useSelector((state) => state.accountLocations.locations)
  const [focusedMenuItemId, setFocusedMenuItemId] = useState('')
  const [hoveredMenuItemId, setHoveredMenuItemId] = useState('')
  const navigationMenuIsOpen = useSelector((state) => state.navigationMenu.navigationMenuIsOpen)
  const urlBasename = useSelector((state) => state.intl.urlBasename)
  const location = useLocation()
  const dispatch = useDispatch()
  const classes = useStyles()
  const isMobile = useMediaQuery('(max-width:900px)')

  useEffect(() => {
    if (isMobile) {
      dispatch(toggleNavigationMenu())
    } else {
      dispatch(openNavigationMenu())
    }
  }, [isMobile])

  const handleToggleMenu = () => {
    dispatch(toggleNavigationMenu())
  }

  const menuItems = useMemo(() => {
    return utils.getMenuItems(urlBasename, accountId, accountDetailsData.isFromSSO)
  }, [urlBasename, accountId, accountDetailsData])

  const missingAccountData = useMemo(() => {
    return utils.getMissingAccountData(companyData, { accountDetailsData, accountLocationsData })
  }, [companyData, accountDetailsData, accountLocationsData])

  useEffect(() => {
    if (!navigationMenuIsOpen) {
      // Hide tooltips if navigation menu gets closed (prevents showing tooltips on mobile)
      setFocusedMenuItemId('')
      setHoveredMenuItemId('')
    }
  }, [navigationMenuIsOpen])

  const onMenuItemClick = (menuItemId) => {
    if (menuItemId === 'logout') {
      // Logout menu item was clicked
      dispatch(logout())
      window.location.reload()
    }

    if (menuItemId !== 'logout') {
      setTimeout(() => {
        // After changing to a new page, set the focus on the page title.
        // This action will help users using a keyboard to navigate the site.
        const pageTitleElement = document.getElementById('page-title')
        if (pageTitleElement) {
          pageTitleElement.focus()
        }
      }, 50)

      const isDataMissing = missingAccountData.find((missing) => missing.id === menuItemId)
      if (isDataMissing) {
        dispatch(
          openAppSnackbar({
            title: intl.translate('navigation_menu__snackbar_title'),
            description: intl.translate('navigation_menu__snackbar_description')
          })
        )
      }
    }
  }

  const getMenuItemComponent = (menuItemId) => {
    switch (menuItemId) {
      case 'logout':
        return undefined
      default:
        return RouterLink
    }
  }

  const canShowMenuItemTooltip = (menuItemId) => {
    if (!navigationMenuIsOpen) {
      if (menuItemId === focusedMenuItemId || menuItemId === hoveredMenuItemId) {
        return true
      }
    }
    return false
  }

  const pathnameWithoutEndingSlash = typeof location.pathname === 'string' ? location.pathname.replace(/\/$/, '') : location.pathname

  return (
    <>
      <Drawer
        variant="permanent"
        className={classes.drawer + ' ' + (navigationMenuIsOpen ? classes['drawer-open'] : classes['drawer-closed'])}
        classes={{ paper: classes['drawer-paper'] + ' ' + (navigationMenuIsOpen ? classes['drawer-open'] : classes['drawer-closed']) }}
        anchor="left"
        id="menu-id"
        open={navigationMenuIsOpen}
      >
        {/* This element adds a spacing equal to the height of the header, so that the top part of the body isn't hidden. */}
        {navigationMenuIsOpen && (
          <>
            <div className={classes['header-spacing']} aria-hidden="true"></div>
            <div className={classes['drawer-list-wrapper']}>
              <List className={classes['drawer-list']} component="nav">
                {menuItems.map((menuItem) => {
                  const menuItemMissingAccountData = missingAccountData.find((missing) => missing.id === menuItem.id)
                  const hasMissingAccountData = Boolean(menuItemMissingAccountData)

                  return menuItem.menu ? (
                    <Tooltip key={menuItem.id} title={intl.translate(menuItem.name)} open={canShowMenuItemTooltip(menuItem.id)}>
                      <ListItem
                        className={classes.item}
                        onMouseEnter={() => setHoveredMenuItemId(menuItem.id)}
                        onMouseLeave={() => {
                          setHoveredMenuItemId('')
                          setFocusedMenuItemId('')
                        }}
                        onFocus={() => setFocusedMenuItemId(menuItem.id)}
                        onBlur={() => setFocusedMenuItemId('')}
                      >
                        <ListItemButton
                          selected={pathnameWithoutEndingSlash === menuItem.url}
                          aria-current={pathnameWithoutEndingSlash === menuItem.url ? 'page' : undefined}
                          component={getMenuItemComponent(menuItem.id)}
                          to={menuItem.id === 'logout' ? null : menuItem.url}
                          onClick={() => {
                            onMenuItemClick(menuItem.id)
                          }}
                          className={classes['item-button']}
                        >
                          <ListItemIcon>{menuItem.icon ? createElement(menuItem.icon) : null}</ListItemIcon>
                          <ListItemText
                            className={classes['item-text']}
                            primary={
                              <>
                                <span>{intl.translate(menuItem.name)}</span>
                                <span style={visuallyHidden}>
                                  {pathnameWithoutEndingSlash === menuItem.url ? intl.translate('navigation_menu__current_page') : ''}
                                </span>
                              </>
                            }
                          />
                          {hasMissingAccountData && (
                            <Chip
                              className={classes.chip}
                              size="small"
                              label={`${menuItemMissingAccountData.errorCount} ${
                                menuItemMissingAccountData.errorCount === 1
                                  ? intl.translate('navigation_menu__chip_missing')
                                  : intl.translate('navigation_menu__multiple_chips_missing')
                              }`}
                              color="error"
                              variant="outlined"
                            />
                          )}
                        </ListItemButton>
                      </ListItem>
                    </Tooltip>
                  ) : null
                })}
              </List>
            </div>
          </>
        )}
      </Drawer>

      {/* This <div> acts as an overlay on mobile to prevent clicking on the app body when the menu is open */}
      <div
        className={classes['drawer-overlay'] + (navigationMenuIsOpen ? ' ' + classes['drawer-overlay-active'] : '')}
        aria-hidden="true"
        onClick={handleToggleMenu}
      ></div>
    </>
  )
}

export default NavigationMenu
