import { useState, useEffect } from 'react'
import { IconButton, Collapse, Tooltip } from '@mui/material'
import { ExpandLess as ExpandLessIcon, ExpandMore as ExpandMoreIcon } from '@mui/icons-material'

import intl from '../../../helper/intl'

import useStyles from './CheckboxInputTree.styles'
import CheckboxInput from '../CheckboxInput/CheckboxInput'

const CheckboxInputTreeNode = ({ id, treeId, label, renderLabel, checked, disabled, nodes, childNodes, onChange, onExpand }) => {
  const classes = useStyles()
  const [expanded, setExpanded] = useState(false)
  const totalNodes = nodes ? nodes.length : 0

  useEffect(() => {
    // When the node is loaded, check if any of the children has checked===true. In that case, expand the node.
    const totalChildren = childNodes.length
    for (let i = 0; i < totalChildren; i++) {
      if (childNodes[i].checked === true && checked === false) {
        // At least one child is checked, but the parent is not. We have to expand the node to show the checked child.
        setExpandedValue(true)
        break
      }
    }
  }, [])

  const setExpandedValue = (value) => {
    setExpanded(value)

    if (value === true && typeof onExpand === 'function') {
      // If this node expands, notify the parent
      onExpand()
    }
  }

  // Gets the IDs of all the children from given node (including children of children and so on)
  const getChildrenIdsFromNode = (nodeId) => {
    let childrenIds = []

    for (let i = 0; i < totalNodes; i++) {
      if (nodes[i].parent_id === nodeId) {
        childrenIds.push(nodes[i].id) // This node is a child. Add the ID to the list.
        childrenIds = childrenIds.concat(getChildrenIdsFromNode(nodes[i].id)) // Also add children of children
      }
    }

    return childrenIds
  }

  // Handles the change of this node's input.
  // If this node has children and the input changes, then the children will be updated too
  const onNodeInputChange = (checked) => {
    let nodeIdsToUpdate = [id]
    const childrenIds = childNodes && childNodes.length ? getChildrenIdsFromNode(id) : []

    nodeIdsToUpdate = nodeIdsToUpdate.concat(childrenIds)
    onChange(nodeIdsToUpdate, checked)
  }

  // Handles the change of a child of this node
  // In this case, this node may or may not be updated, depending on the values of the other children
  const onNodeChildChange = (nodeIds, checked) => {
    const nodeIdsToUpdate = nodeIds.concat([])
    let updateParentNode = false
    const totalChildren = childNodes.length

    if (checked === true) {
      // The child was set to true. In this case, this node will be update if all other children are true.
      let allChildrenTrue = true
      for (let i = 0; i < totalChildren; i++) {
        if (childNodes[i].checked !== true && nodeIds.indexOf(childNodes[i].id) === -1) {
          allChildrenTrue = false
          break
        }
      }
      updateParentNode = allChildrenTrue
    } else {
      // The child was set to false. In this case, this node will be updated if there's at least other child set to true.
      let oneChildTrue = false
      for (let i = 0; i < totalChildren; i++) {
        if (childNodes[i].checked === true && nodeIds.indexOf(childNodes[i].id) === -1) {
          oneChildTrue = true
          break
        }
      }
      updateParentNode = oneChildTrue
    }

    if (updateParentNode === true) {
      nodeIdsToUpdate.push(id)
    }

    onChange(nodeIdsToUpdate, checked)
  }

  return (
    <li className={childNodes && childNodes.length ? classes['list-item-with-children'] : classes['list-item']} role="treeitem">
      {childNodes && childNodes.length ? (
        <Tooltip title={expanded ? intl.translate('general__collapse') : intl.translate('general__expand')}>
          <IconButton
            className={classes['list-button']}
            size="small"
            disabled={disabled}
            aria-label={(expanded ? intl.translate('general__collapse') : intl.translate('general__expand')) + ' ' + label}
            onClick={() => setExpandedValue(!expanded)}
          >
            {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
        </Tooltip>
      ) : null}

      {/* Main input of this node */}
      <CheckboxInput
        id={'checkbox-input-group-' + treeId + '--node-' + id}
        className={classes['checkbox-wrapper']}
        checkboxClassName={classes.checkbox}
        label={label}
        disabled={disabled}
        checked={checked}
        onChange={(event) => onNodeInputChange(event.target.checked)}
        value={id}
      />

      {/* Child nodes (if any) */}
      {childNodes && childNodes.length ? (
        <Collapse in={expanded} timeout="auto">
          <ul className={classes['child-list']} role="group">
            {childNodes.map((node) => {
              // Find children of this node, if any
              const children = []
              for (let i = 0; i < totalNodes; i++) {
                if (nodes[i].parent_id === node.id) {
                  children.push(nodes[i])
                }
              }

              return (
                <CheckboxInputTreeNode
                  key={node.id}
                  id={node.id}
                  treeId={treeId}
                  label={(typeof renderLabel === 'function') ? renderLabel(node.label) : node.label}
                  renderLabel={renderLabel}
                  checked={node.checked}
                  disabled={disabled}
                  nodes={nodes}
                  childNodes={children}
                  onChange={onNodeChildChange}
                  onExpand={() => setExpandedValue(true) /* If a child expands, the parent should expand too */}
                />
              )
            })}
          </ul>
        </Collapse>
      ) : null}
    </li>
  )
}

export default CheckboxInputTreeNode
