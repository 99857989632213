import { FormControl, FormLabel, FormGroup, FormHelperText } from '@mui/material'
import CheckboxInputTreeNode from './CheckboxInputTreeNode'

import useStyles from './CheckboxInputTree.styles'

const CheckboxInputTree = ({ id, className, legend, renderLabel, disabled, nodes, helperText, onChange }) => {
  const classes = useStyles()
  const totalNodes = nodes ? nodes.length : 0

  // Returns true if all inputs are checked, false otherwhise
  const areAllInputsChecked = () => {
    for (let i = 0; i < totalNodes; i++) {
      if (nodes[i].checked === false) {
        return false
      }
    }

    return true
  }

  const onTreeInputChange = (nodeIdsToUpdate, checked) => {
    const updatedNodes = []
    const allNodesChange = nodeIdsToUpdate.indexOf(0) > -1

    // Update nodes (taking into account node children)
    for (let i = 0; i < totalNodes; i++) {
      const node = { ...nodes[i] }
      if (nodeIdsToUpdate.indexOf(node.id) > -1 || allNodesChange) {
        node.checked = checked
      }
      updatedNodes.push(node)
    }

    if (typeof onChange === 'function') {
      onChange(updatedNodes)
    }
  }

  return (
    <FormControl component="fieldset" className={classes.wrapper + (className ? ' ' + className : '')} role="tree">
      {legend && (
        <FormLabel component="legend" className={classes.legend}>
          {legend}
        </FormLabel>
      )}

      <FormGroup>
        <ul className={classes['root-list']}>
          {/* This is a special node with ID = 0, used to check/uncheck all nodes */}
          {(nodes && (nodes.length > 1)) &&
            <CheckboxInputTreeNode
              id={0}
              treeId={id}
              label={(typeof renderLabel === 'function') ? renderLabel('All') : 'All'}
              value={0}
              checked={areAllInputsChecked()}
              disabled={disabled}
              nodes={nodes}
              childNodes={[]}
              onChange={onTreeInputChange}
            />
          }

          {nodes && nodes.length
            ? nodes.map((node) => {
              if (node.parent_id) {
                return null // Don't display children items at the top level
              }

              // Find children of this node, if any
              const children = []
              for (let i = 0; i < totalNodes; i++) {
                if (nodes[i].parent_id === node.id) {
                  children.push(nodes[i])
                }
              }

              return (
                  <CheckboxInputTreeNode
                    key={node.id}
                    id={node.id}
                    treeId={id}
                    label={(typeof renderLabel === 'function') ? renderLabel(node.label) : node.label}
                    renderLabel={renderLabel}
                    checked={node.checked}
                    disabled={disabled}
                    nodes={nodes}
                    childNodes={children}
                    onChange={onTreeInputChange}
                  />
              )
            })
            : null}
        </ul>
      </FormGroup>

      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  )
}

export default CheckboxInputTree
