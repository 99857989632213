import React, { useState, useEffect, forwardRef } from 'react'
import { TextField, Typography } from '@mui/material'
import { visuallyHidden } from '@mui/utils'
import ErrorIcon from '@mui/icons-material/Error'

// react-input-mask is used to provide a mask to the input
// Documentation available at: https://github.com/sanniassin/react-input-mask/tree/v2
import InputMask from 'react-input-mask'

import broadcast from '../../../helper/broadcast'

import useStyles from './TextInput.styles'
import intl from '../../../helper/intl'

// eslint-disable-next-line react/display-name
const InputMaskRef = React.forwardRef((props, ref) => {
  const { ...other } = props
  return <InputMask {...other} inputRef={ref} />
})

const TextInput = forwardRef(
  (
    {
      id,
      className,
      inputClassName,
      label,
      disabled,
      readOnly,
      autoFocus,
      listenToEvents,
      value,
      mask,
      maskChar,
      isDirty,
      isValid,
      required,
      errorMessage,
      onChange,
      onBlur,
      tabIndex,
      ...otherProperties
    },
    ref
  ) => {
    const [inputIsDirty, setInputIsDirty] = useState(!!value)
    const classes = useStyles()

    useEffect(() => {
      if (id && listenToEvents) {
        // Creating an event listener so that the inner state of the input can be changed from the outside
        // Usually this is done using props, but this is an edge case
        const textInputEventHandler = (params) => {
          if (params && typeof params.isDirty === 'boolean') {
            setInputIsDirty(params.isDirty)
          }
        }
        broadcast.on('text-input-' + id, textInputEventHandler)
      }
    }, [])

    useEffect(() => {
      // if the mask changes, update the value with the new mask
      if (id && typeof onChange === 'function') {
        const maskedValue = document.getElementById(id).value
        if (maskedValue !== value && value) {
          onChange({ target: { value: maskedValue } })
        }
      }
    }, [mask])

    const validateInput = (_value, _isValid) => {
      if (typeof _isValid === 'boolean') {
        return _isValid
      } else if (typeof _isValid === 'function') {
        return _isValid(_value)
      } else {
        return true // If the parent doesn't provide a method to validate the input, we consider the input is valid
      }
    }

    const onInputBlur = (event) => {
      if (value) {
        setInputIsDirty(true)
      }

      if (typeof onBlur === 'function') {
        onBlur(event)
      }
    }

    // The parent can override the "isDirty" property
    const _isDirty = typeof isDirty !== 'undefined' ? isDirty : !disabled && inputIsDirty

    const commonInputProps = {
      ...otherProperties.inputProps,
      readOnly,
      tabIndex,
      'aria-required': required ? 'true' : undefined
    }

    const maskProps = mask
      ? {
          inputComponent: InputMaskRef,
          inputProps: {
            ...commonInputProps,
            mask,
            maskChar
          }
        }
      : {}

    return (
      <div className={classes.wrapper}>
        <TextField
          id={id}
          className={className}
          label={
            required ? (
              <>
                {label}
                <Typography style={visuallyHidden}> {intl.translate('general__required_accesibility_label')}</Typography>
              </>
            ) : (
              label
            )
          }
          inputRef={ref}
          disabled={disabled}
          value={value}
          onChange={onChange}
          onBlur={onInputBlur}
          variant="outlined"
          color={window.app.theme.palette.textInput ? 'textInput' : 'primary'}
          margin="normal"
          fullWidth={true}
          required={required}
          error={_isDirty && !validateInput(value, isValid)}
          helperText={_isDirty && !validateInput(value, isValid) ? errorMessage : ''}
          {...otherProperties}
          InputProps={{
            ...otherProperties.InputProps,
            className: inputClassName,
            autoFocus,
            inputProps: commonInputProps,
            ...maskProps
          }}
        />
        <ErrorIcon
          className={_isDirty && !validateInput(value, isValid) ? classes['error-icon'] : classes['error-icon-hidden']}
          role="presentation"
        />
      </div>
    )
  }
)

TextInput.displayName = 'TextInput'

export default TextInput
