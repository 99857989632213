import { Snackbar, IconButton, Button } from '@mui/material'
import { Close as CloseIcon } from '@mui/icons-material'
import ButtonWithProgress from '../../common/ButtonWithProgress'
import { useSelector } from 'react-redux'

import useStyles from './BasicSnackbar.styles'

const BasicSnackbar = (props) => {
  const { id, isOpen, children, message, icon, title, description, duration, ignoreClickAway, actionButton, onClose } = props
  const languageDirection = useSelector((state) => state.intl.languageDirection)
  const classes = useStyles()

  // By default, snackbars won't hide automatically
  // Recommended duration for autohide by Material Design is between 4s and 10s
  const defaultDuration = null

  const handleSnackbarClose = (event, reason) => {
    if (!ignoreClickAway || reason !== 'clickaway') {
      if (typeof onClose === 'function') {
        onClose(event, reason)
      }
    }
  }

  // Renders the action buttons of the snackbar.
  // Snackbars in our app will always have a close button (IconButton), and optionally an action button
  const renderSnackbarActions = (_actionButton) => {
    const snackbarActions = []

    snackbarActions.push(
      <IconButton
        key="close"
        id={id + '--close-btn'}
        aria-label="close"
        color="inherit"
        onClick={(event) => handleSnackbarClose(event, 'buttonclick')}
      >
        <CloseIcon style={{ color: '#777777' }} />
      </IconButton>
    )

    return snackbarActions
  }

  const renderContent = () => {
    const renderButton = () => {
      if (!actionButton) return null
      return (
        <div className={classes['wrapper-button']}>
          <ButtonWithProgress key="action" id={id + '--action-btn'} variant="text" onClick={actionButton.onClick}>
            {actionButton.content}
          </ButtonWithProgress>
        </div>
      )
    }
    const renderMessage = () => {
      if (message) {
        return (
          <div className={classes['wrapper-message']}>
            <span id={id + '--message'}>{message}</span>
            {renderButton()}
          </div>
        )
      }
      return (
        <div id={id + '--message'} className={classes['wrapper-massage-container']}>
          {icon && icon}
          <div className={classes['wrapper-message']}>
            <p className={description ? classes['wrapper-title'] : classes['wrapper-title-reduced']}>{title}</p>
            {description && <p className={classes['wrapper-description']}>{description}</p>}
            {renderButton()}
          </div>
        </div>
      )
    }

    return renderMessage()
  }

  return (
    <Snackbar
      id={id}
      className={classes.wrapper}
      tabIndex={'-1' /* Makes the snackbar content focusable via script */}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      dir={languageDirection}
      open={isOpen}
      autoHideDuration={typeof duration !== 'undefined' ? duration : defaultDuration}
      onClose={handleSnackbarClose}
      message={renderContent()}
      ContentProps={{ 'aria-labelledby': id + '--message', 'aria-live': 'polite' }}
      action={renderSnackbarActions(actionButton)}
    >
      {children}
    </Snackbar>
  )
}

export default BasicSnackbar
