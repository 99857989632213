import NativeSelectInput from '../NativeSelectInput'
import TextInput from '../TextInput'

import intl from '../../../helper/intl'

import countryCodeOptions from '../../../data/country_codes.json'

import useStyles from './PhoneNumberInput.styles'
import ButtonWithProgress from '../ButtonWithProgress'
import { useSelector } from 'react-redux'
import { useState } from 'react'

const PhoneNumberInput = ({
  id,
  label,
  disabled,
  variant,
  margin,
  confirmed,
  onConfirm,
  value,
  countryCodeValue,
  countryCodeAutoComplete,
  onPhoneNumberChange,
  onCountryCodeChange,
  ...otherProperties
}) => {
  const classes = useStyles()

  const languageDirection = useSelector((state) => state.intl.languageDirection)

  const [phoneNumberFocused, setPhoneNumberFocused] = useState(false)

  const getPhoneNumberMask = (inputValue, countryCode) => {
    if (countryCode === 'CA' || countryCode === 'US') {
      // Accessibility: If the input value is empty, we don't use a mask.
      // The reason behind this is to prevent screen readers from reading an empty mask, which would be confusing.
      return inputValue !== '(' ? '(999) 999-9999' : ''
    }

    // The default phone number format is just numbers, with a maximum length of 20 numbers.
    return '99999999999999999999'
  }

  const getFakeMaskValue = (inputValue, countryCode) => {
    if (countryCode === 'CA' || countryCode === 'US') {
      const baseMask = '(___) ___-____'
      const nonMaskedPart = inputValue
      const maskedPart = inputValue ? baseMask.replace(new RegExp('.{' + inputValue.length + '}'), '') : baseMask

      return (
        <>
          <span style={{ color: 'transparent' }}>{nonMaskedPart}</span>
          <span>{maskedPart}</span>
        </>
      )
    }

    return ''
  }

  const getNumericValue = () => {
    return value.replace(/\D/g, '') // remove non-numeric characters
  }

  const getPhoneNumberError = (countryCode) => {
    if (countryCode === 'CA' || countryCode === 'US') {
      return (
        <>
          <span aria-hidden="true">{intl.translate('general__invalid_north_american_phone_number')}</span>
          <span className="_visually_hidden_">{intl.translate('general__invalid_north_american_phone_number_accessible')}</span>
        </>
      )
    }

    return (
      <>
        <span aria-hidden="true">{intl.translate('general__invalid_phone_number')}</span>
        <span className="_visually_hidden_">{intl.translate('general__invalid_phone_number_accessible')}</span>
      </>
    )
  }

  return (
    <div className={classes.wrapper}>
      <div className={classes['country-code-wrapper']}>
        <NativeSelectInput
          id={id + '--country-code'}
          label={intl.translate('general__country_prefix_label')}
          disabled={disabled}
          name={id + '--country-code'}
          autoComplete={countryCodeAutoComplete}
          value={countryCodeValue}
          renderValue={
            (value) =>
              value
                ? intl.getDialCodeByCountryCode(value) || '–'
                : '' /* The selected value contains the country code, but we want to show the dial code */
          }
          options={countryCodeOptions}
          getOptionValue={(option) => option.code}
          renderOption={(option) => option.name + (option.dial_code ? ' (' + option.dial_code + ')' : '')}
          variant={typeof variant !== 'undefined' ? variant : 'outlined'}
          margin={typeof margin !== 'undefined' ? margin : 'normal'}
          fullWidth={true}
          // dir={'ltr' /* Numbers shouldn't change in between LTR and RTL languages, so we always keep this input LTR */}
          onChange={onCountryCodeChange}
        />
      </div>
      <div className={classes['phone-number-wrapper']}>
        <TextInput
          type="tel"
          id={id + '--phone-number'}
          label={label}
          disabled={disabled}
          value={value}
          mask={getPhoneNumberMask(value, countryCodeValue)}
          maskChar=""
          onFocus={() => {
            setPhoneNumberFocused(true)
          }}
          onBlur={() => {
            setPhoneNumberFocused(false)
          }}
          onChange={(event) => {
            if (event.target.value === '(') {
              // Hack for empty american phone numbers to prevent screen readers from reading an opening parenthesis with empty content.
              event.target.value = ''
            }
            onPhoneNumberChange(event)
          }}
          variant={typeof variant !== 'undefined' ? variant : 'outlined'}
          margin={typeof margin !== 'undefined' ? margin : 'normal'}
          fullWidth={true}
          errorMessage={getPhoneNumberError(countryCodeValue)}
          {...otherProperties}
        />
        {(value || phoneNumberFocused) && languageDirection === 'ltr' && (
          <span className={classes['phone-number-fake-mask']} aria-hidden="true">
            {getFakeMaskValue(value, countryCodeValue)}
          </span>
        )}
      </div>
      {confirmed === false && getNumericValue() && (
        <div className={classes['confirm-button-wrapper']}>
          <ButtonWithProgress
            variant="text"
            color={window.app.theme.palette.button ? 'button' : 'primary'}
            size="small"
            onClick={onConfirm}
          >
            {intl.translate('general__verify')}
          </ButtonWithProgress>
        </div>
      )}
    </div>
  )
}

export default PhoneNumberInput
