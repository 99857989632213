import { DragIndicator as DragIndicatorIcon } from '@mui/icons-material'
import { FormControl, FormGroup, FormHelperText, FormLabel } from '@mui/material'
import { useState } from 'react'

import useStyles from './CheckboxInputGroup.styles'
import CheckboxInput from '../CheckboxInput/CheckboxInput'

const CheckboxInputGroup = ({ id, className, legend, renderLabel, disabled, sortable, inputs, helperText, onChange }) => {
  const [draggedInput, setDraggedInput] = useState({})
  const classes = useStyles()

  const onCheckboxInputChange = (value, checked) => {
    const newInputs = []

    const numInputs = inputs.length
    for (let i = 0; i < numInputs; i++) {
      const input = { ...inputs[i] }
      if (input.value === value) {
        input.checked = checked
      }
      newInputs.push(input)
    }

    if (typeof onChange === 'function') {
      onChange(newInputs, value)
    }
  }

  const onCheckboxInputClick = (value, checked) => {
    // This click handler is used only when the inputs are sortable.
    // It's necessary because the change handler doesn't work well on mobile
    const newInputs = []
    let updateInputs = false

    const numInputs = inputs.length
    for (let i = 0; i < numInputs; i++) {
      const input = { ...inputs[i] }
      if (input.value === value && input.checked === checked) {
        // input.checked === checked, that means the user is using a mobile device. In this case, update the input.
        input.checked = !checked
        updateInputs = true
      }
      newInputs.push(input)
    }

    if (updateInputs && typeof onChange === 'function') {
      onChange(newInputs)
    }
  }

  const onCheckboxInputDragOver = (event, dragOverInput) => {
    if (draggedInput.value === dragOverInput.value) {
      return
    }
    const hoverBoundingRect = event.currentTarget.getBoundingClientRect()

    // Get vertical middle
    const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2

    // Get vertical hover
    const hoverClientY = event.clientY - hoverBoundingRect.top

    // Only move items when the mouse has crossed half of the items height
    if (draggedInput.sequenceNumber < dragOverInput.sequenceNumber && hoverClientY < hoverMiddleY) {
      return // When dragging downwards, only move when the cursor is below 50%
    }
    if (draggedInput.sequenceNumber > dragOverInput.sequenceNumber && hoverClientY > hoverMiddleY) {
      return // When dragging upwards, only move when the cursor is above 50%
    }

    // Move items
    const startChunk = inputs.slice(0, draggedInput.sequenceNumber - 1)
    const endChunk = inputs.slice(draggedInput.sequenceNumber)

    const newInputs = startChunk.concat(endChunk)
    newInputs.splice(dragOverInput.sequenceNumber - 1, 0, inputs[draggedInput.sequenceNumber - 1])

    for (let i = 0; i < newInputs.length; i++) {
      newInputs[i].sequenceNumber = i + 1
    }

    if (typeof onChange === 'function') {
      onChange(newInputs)
    }
  }

  const getCheckboxListItemClassName = (inputObj) => {
    let className = ''

    if (sortable) {
      className += classes['checkbox-list-item-sortable']
      if (draggedInput.value === inputObj.value) {
        className += ' ' + classes['checkbox-list-item-dragged']
      }
    } else {
      className += classes['checkbox-list-item']
    }

    return className
  }

  return (
    <FormControl component="fieldset" className={classes.wrapper + (className ? ' ' + className : '')} role="group">
      {legend && (
        <FormLabel component="legend" className={classes.legend}>
          {legend}
        </FormLabel>
      )}
      <FormGroup>
        <ul className={classes['checkbox-list']}>
          {inputs.map((input) => (
            <li
              key={input.value}
              className={getCheckboxListItemClassName(input)}
              draggable={sortable}
              onDragStart={sortable ? () => setDraggedInput(input) : undefined}
              onDragEnd={sortable ? () => setDraggedInput({}) : undefined}
              onDragOver={sortable ? (event) => onCheckboxInputDragOver(event, input) : undefined}
            >
              {sortable && (
                <span className={classes['drag-indicator-icon-wrapper']} aria-hidden="true">
                  <DragIndicatorIcon />
                </span>
              )}
              <CheckboxInput
                id={'checkbox-input-group-' + id + '--' + input.value}
                className={classes['checkbox-wrapper']}
                label={(typeof renderLabel === 'function') ? renderLabel(input.label) : input.label}
                disabled={disabled}
                checked={input.checked}
                onChange={(event) => onCheckboxInputChange(input.value, event.target.checked)}
                onClick={sortable ? (event) => onCheckboxInputClick(input.value, event.target.checked) : undefined}
                value={input.value}
              />
            </li>
          ))}
        </ul>
      </FormGroup>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  )
}

export default CheckboxInputGroup
