import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(
  (theme) => ({
    wrapper: {
      position: 'relative',
      display: 'block'
    },
    legend: {
      marginTop: 0,
      marginBottom: 0,
      paddingTop: theme.spacing(2),
      paddingRight: theme.spacing(6),
      paddingBottom: theme.spacing(2)
    },
    tabs: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(2),
      [theme.breakpoints.up('md')]: {
        display: 'none'
      }
    },
    'form-group': {
      paddingRight: theme.spacing(1),
      [theme.breakpoints.up('md')]: {
        display: 'inline-block',
        width: '50%'
      },
      [theme.breakpoints.down('md')]: {
        paddingRight: 0,
        paddingLeft: 0
      },

      '&:last-child': {
        paddingRight: 0,
        paddingLeft: theme.spacing(1)
      }
    },
    'form-group-hidden': {
      [theme.breakpoints.down('md')]: {
        visibility: 'hidden',
        height: 0,
        overflow: 'hidden'
      }
    },
    map: {
      position: 'relative',
      marginTop: theme.spacing(2),
      width: '100%',
      height: '344px',
      borderRadius: theme.shape.borderRadius,
      [theme.breakpoints.down('md')]: {
        height: '448px'
      },

      '& .ol-viewport': {
        borderRadius: theme.shape.borderRadius
      }
    },
    'action-buttons-wrapper': {
      position: 'absolute',
      top: 0,
      right: 0
    }
  }),
  { name: 'location-fieldset-' }
)

export default useStyles
