import { useCallback, useEffect, useState } from "react";

import TextInput from "../TextInput";

/**
 * AutoFillAwareTextInput
 * 
 * This component was created to address an issue in Material-UI (MUI) related to Chrome's autofill.
 * The problem occurs when Chrome's autofill causes a collision between the text field label and its value,
 * resulting in a poor user experience.
 * 
 * This component detects when a text field has been autofilled and adjusts the `shrink` property of the text field label
 * to prevent the collision.
 */

const AutoFillAwareTextInput = ({ value, onChange, inputProps, InputLabelProps, ...params }) => {
  const [fieldHasValue, setFieldHasValue] = useState(false);

  const handleAnimationStart = useCallback((e) => {
    const autofilled = e.animationName === "mui-auto-fill" || e.animationName === "mui-auto-fill-cancel";
    if (autofilled) {
      setFieldHasValue(e.target.matches("*:-webkit-autofill") || !!value);
    }
  }, [value]);

  const handleOnChange = useCallback((event) => {
    setFieldHasValue(!!event.target.value);
    onChange(event);
  }, [onChange]);

  return (
    <TextInput
      inputProps={{
        ...inputProps,
        onAnimationStart: handleAnimationStart,
      }}
      InputLabelProps={{
        ...InputLabelProps,
        shrink: fieldHasValue,
      }}
      value={value}
      onChange={(event) => handleOnChange(event)}
      {...params}
    />
  );

};

export default AutoFillAwareTextInput;
