import axios from 'axios'

import config from '../service/config';

const auth = (() => {
  let anonymousToken = null;
  let anonymousTokenExpiration = null;

  const getAnonymousToken = () => {
    const currentTime = Date.now();

    // Check if the token is still valid
    if (anonymousToken && anonymousTokenExpiration && currentTime < anonymousTokenExpiration) {
      return anonymousToken;
    }

    const {
      ANONYMOUS_CLIENT_ID: clientId = '',
      ANONYMOUS_CLIENT_SECRET: clientSecret = '' 
    } = window.__env__;

    const authorizationBasic = btoa(`${clientId}:secret`);

    const params = new URLSearchParams({
      grant_type: 'client_credentials',
      client_secret: clientSecret,
      client_id: clientId,
    });

    return axios.post(config.oauthUrl, params.toString(), {
      headers: {
        Authorization: 'Basic ' + authorizationBasic,
        'Content-Type': 'application/x-www-form-urlencoded'
      },
    }).then((result) => {
      if (result.data) {
        anonymousToken = result.data.access_token;
        anonymousTokenExpiration = currentTime + (result.data.expires_in * 1000);
        return anonymousToken;
      }      
    });
  };

  const ensureToken = async () => {
    const accessToken = config.getAccessToken()?.trim();
    return accessToken || await getAnonymousToken();
  };

  return {
    getAnonymousToken,
    ensureToken,
  };

})();

export default auth;
