import { fromExtent as polygonFromExtent } from 'ol/geom/Polygon'
import WKT from 'ol/format/WKT.js'
import { Map, View, Feature } from 'ol'
import { Tile as TileLayer, Vector as VectorLayer } from 'ol/layer'
import { OSM, XYZ, Vector as VectorSource } from 'ol/source'
import { Style, Fill, Stroke, RegularShape as RegularShapeStyle } from 'ol/style'
import { Point } from 'ol/geom'
import { fromLonLat, toLonLat } from 'ol/proj'

const maps = (() => {
  const mapsById = {}
  const sourcesById = {}
  const layersById = {}
  const viewsById = {}
  const MAP_TRANSITION_DURATION = 500

  const renderMap = (mapId) => {
    let baseSource = new OSM()
    if (window.companyData && window.companyData.name === 'Slovenia') {
      // Special source layer for Slovenia
      baseSource = new XYZ({
        url: 'http://lbs.sledenje.com/logostms/1.0.0/sos_112_group@EPSG:900913_sos112@png/{z}/{x}/{-y}.png'
      })
    }
    const baseLayer = new TileLayer({
      id: mapId + '-base-layer',
      visible: true,
      source: baseSource
    })

    const markerSource = new VectorSource()
    const markerLayer = new VectorLayer({
      id: mapId + 'marker-layer',
      source: markerSource
    })

    let view = new View({
      center: [0, 0],
      zoom: 2
    })

    if (window.companyData && window.companyData.name === 'Slovenia') {
      // Special view for Slovenia
      view = new View({
        center: fromLonLat([15, 46]),
        zoom: 7
      })
    }

    const map = new Map({
      target: mapId,
      layers: [baseLayer, markerLayer],
      view
      // No controls are added. Map is only accessible using the mouse.
      // For keyboard users or screen readers, it remains as a presentation element, simplifying the UI.
      // controls: []
    })

    mapsById[mapId] = map
    viewsById[mapId + '-view'] = view
    layersById[mapId + '-base-layer'] = baseLayer
    layersById[mapId + '-marker-layer'] = markerLayer
    sourcesById[mapId + '-base-source'] = baseSource
    sourcesById[mapId + '-marker-source'] = markerSource
  }

  const isMapRendered = (mapId) => {
    const mapWrapperElement = document.getElementById(mapId)
    return mapWrapperElement && mapWrapperElement.children.length > 2
  }

  const addMarkerToMap = (mapId, { longitude, latitude }, movementToMarker = 'none') => {
    // Create the marker
    const geometry = new Point(fromLonLat([longitude, latitude]))
    const style = new Style({
      image: new RegularShapeStyle({
        fill: new Fill({ color: 'red' }),
        stroke: new Stroke({ color: 'black' }),
        radius: 15,
        points: 3,
        rotation: Math.PI
      })
    })
    const marker = new Feature({ name: 'marker', geometry })
    marker.setId(mapId + '-marker')
    marker.setStyle(style)

    // Add the marker to the map (if there's already a marker, remove it)
    const markerSource = sourcesById[mapId + '-marker-source']
    const oldMarker = markerSource.getFeatureById(mapId + '-marker')
    if (oldMarker) {
      markerSource.removeFeature(oldMarker)
    }
    markerSource.addFeature(marker)

    // Move and zoom the view to show the marker
    if (movementToMarker === 'transition') {
      const view = viewsById[mapId + '-view']
      view.animate({
        center: fromLonLat([longitude, latitude]),
        zoom: 16
      })
    } else if (movementToMarker === 'instant') {
      const view = new View({
        center: fromLonLat([longitude, latitude]),
        zoom: 16
      })
      const map = mapsById[mapId]
      map.setView(view)
      viewsById[mapId + '-view'] = view
    }
  }

  const removeMarkerFromMap = (mapId) => {
    const markerSource = sourcesById[mapId + '-marker-source']
    if (markerSource) {
      const marker = markerSource.getFeatureById(mapId + '-marker')
      if (marker) {
        markerSource.removeFeature(marker)
      }
    }
  }

  const addEventListenerToMap = (mapId, eventType, handlerFunction) => {
    const map = mapsById[mapId]
    if (map) {
      map.on(eventType, handlerFunction)
    }
  }

  const getMapById = (mapId) => {
    return mapsById[mapId]
  }

  const getLayerById = (layerId) => {
    return layersById[layerId]
  }

  const getLayersByMapId = (mapId) => {
    const layers = {}
    const mapIdRegex = new RegExp('^' + mapId + '-')

    for (const layerId in layersById) {
      if (mapIdRegex.test(layerId)) {
        layers[layerId] = layersById[layerId]
      }
    }

    return layers
  }

  const getSourceById = (sourceId) => {
    return sourcesById[sourceId]
  }

  const getSourcesByMapId = (mapId) => {
    const sources = {}
    const mapIdRegex = new RegExp('^' + mapId + '-')

    for (const sourceId in sourcesById) {
      if (mapIdRegex.test(sourceId)) {
        sources[sourceId] = sourcesById[sourceId]
      }
    }

    return sources
  }

  const center_map = (mapId, longitude, latitude) => {
    const map = getMapById(mapId)
    if (!map) {
      return
    }
    const view = map.getView()
    view.animate({
      center: fromLonLat([longitude, latitude]),
      zoom: 16
    })
  }

  const zoomToDisplayAllCoordinates = (mapId, wkt) => {
    const map = getMapById(mapId)
    if (!map) {
      return
    }
    const format = new WKT()
    const feature = format.readFeature(wkt, {
      dataProjection: 'EPSG:4326',
      featureProjection: 'EPSG:3857'
    })
    const extent = feature.getGeometry().getExtent()
    const polygon = polygonFromExtent(extent)
    const view = map.getView()
    view.fitInternal(polygon, {
      maxZoom: 18,
      padding: [25, 25, 25, 25],
      duration: MAP_TRANSITION_DURATION
    })
  }

  return {
    renderMap,
    isMapRendered,
    addMarkerToMap,
    removeMarkerFromMap,
    addEventListenerToMap,
    getMapById,
    getLayerById,
    getLayersByMapId,
    getSourceById,
    getSourcesByMapId,
    toLonLat,
    zoomToDisplayAllCoordinates,
    center_map
  }
})()

export default maps
