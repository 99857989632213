import { useState } from 'react';

import {
  Grid,
  Typography
} from '@mui/material';

import BasicDialog from '../common/BasicDialog';
import TextInput from '../common/TextInput';

import intl from '../../helper/intl';
import validate from '../../helper/validate';
import accountService from '../../service/accountService';

import { useSelector, useDispatch } from 'react-redux';
import { openAppSnackbar } from '../../actions/notificationsAction';

const PasswordResetDialog = ({ className, isOpen }) => {
  const companyData = useSelector((state) => state.auth.companyData)
  const accountId = useSelector((state) => state.auth.accountId);
  const details = useSelector((state) => state.accountDetails.details);
  const customFields = useSelector((state) => state.accountDetails.customFields);
  const dispatch = useDispatch();
  const [newPassword, setNewPassword] = useState('');
  const [newPasswordIsDirty, setNewPasswordIsDirty] = useState(false);
  const [newPasswordConfirm, setNewPasswordConfirm] = useState('');
  const [newPasswordConfirmIsDirty, setNewPasswordConfirmIsDirty] = useState(false);
  const [resetPasswordLoading, setResetPasswordLoading] = useState(false);

  const onFormSubmit = (event) => {
    event.preventDefault();
    setResetPasswordLoading(true);
    const updatedDetails = {
      ...details,
      newPassword,
      newPasswordConfirm,
    }
    accountService
      .updateDetails({ accountId, details: updatedDetails, customFields })
      .then((result) => {
        dispatch(
          openAppSnackbar({
            message: intl.translate('account_details_page__update_success')
          })
        );
        window.location.reload();
      })
      .catch((error) => {
        setResetPasswordLoading(false);
        dispatch(
          openAppSnackbar({
            message: intl.getServerError(error.response.data) || intl.translate('account_details_page__update_error')
          })
        );
      });
  }

  return (
    <BasicDialog
      id="password-reset-dialog"
      className={className}
      isOpen={isOpen}
      title={intl.translate('password_reset_page__title')}
      actionButtons={[{
        id: 'password-reset-dialog--subumit-button',
        content: intl.translate('password_reset_page__submit_btn'),
        type: 'submit',
        form: 'password-reset-dialog--form',
        showProgress: resetPasswordLoading,
        disabled: !validate.isValidPassword(newPassword) || (newPasswordConfirm !== newPassword)
      }]}
      onClose={() => {}}
    >
      <form id="password-reset-dialog--form" autoComplete="off" onSubmit={onFormSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="body1" gutterBottom>
              {(companyData?.pass_required_special_char)? (
                intl.translate('account_details_page__update_password_prompt_special_characters', { minLength: companyData.pass_length || 12 })
              ) : (
                intl.translate('account_details_page__update_password_prompt', { minLength: companyData.pass_length || 12 })
              )}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextInput
              id="password-reset-dialog--new-password"
              type="password"
              label={intl.translate('account_details_page__new_password')}
              disabled={resetPasswordLoading}
              autoComplete="new-password"
              value={newPassword}
              onChange={(event) => setNewPassword(event.target.value)}
              isDirty={newPasswordIsDirty}
              onBlur={() => { if (newPassword) {setNewPasswordIsDirty(true);} }}
              isValid={validate.isValidPassword}
              errorMessage={validate.getInvalidPasswordError()}
            />
          </Grid>
          <Grid item xs={12}>
            <TextInput
              id="password-reset-dialog--new-password-confirm"
              type="password"
              label={intl.translate('account_details_page__new_password_confirm')}
              disabled={resetPasswordLoading}
              autoComplete="new-password-confirm"
              value={newPasswordConfirm}
              onChange={(event) => setNewPasswordConfirm(event.target.value)}
              isDirty={newPasswordConfirmIsDirty}
              onBlur={() => { if (newPasswordConfirm) {setNewPasswordConfirmIsDirty(true);} }}
              isValid={(value) => value === newPassword}
              errorMessage={intl.translate('general__invalid_password_confirm')}
            />
          </Grid>
        </Grid>
      </form>
    </BasicDialog>
  )
}

export default PasswordResetDialog;