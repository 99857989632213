import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(
  (theme) => ({
    wrapper: {
      width: '100%'
    },
    legend: {
      marginBottom: theme.spacing(2),
      color: theme.palette.text.primary, // Overriding default color to make the legend look more like a paragraph

      '&.Mui-focused': {
        color: theme.palette.text.primary
      }
    },
    'root-list': {
      margin: '0',
      padding: '0',
      listStyleType: 'none'
    },
    'child-list': {
      margin: '0',
      padding: '0',
      paddingLeft: '36px',
      listStyleType: 'none'
    },
    'checkbox-wrapper': {
      margin: 0,
      width: 'calc(100% - 30px)'
    },
    'list-button': {
      width: '30px'
    },
    'list-item': {
      marginLeft: '30px'
    },
    'list-item-with-children': {
      marginLeft: '0'
    }
  }),
  { name: 'checkbox-input-tree-' }
)

export default useStyles
