import accountService from '../service/accountService'
import { openAppSnackbar, closeAppSnackbar } from './notificationsAction'
import intl from '../helper/intl'

export const ACCOUNT_LOCATIONS_CHANGE = 'ACCOUNT_LOCATIONS_CHANGE'

export const LOAD_ACCOUNT_LOCATIONS_START = 'LOAD_ACCOUNT_LOCATIONS_START'
export const LOAD_ACCOUNT_LOCATIONS_SUCCESS = 'LOAD_ACCOUNT_LOCATIONS_SUCCESS'
export const LOAD_ACCOUNT_LOCATIONS_FAILURE = 'LOAD_ACCOUNT_LOCATIONS_FAILURE'

export const UPDATE_LOCATION_START = 'UPDATE_LOCATION_START'
export const UPDATE_LOCATION_SUCCESS = 'UPDATE_LOCATION_SUCCESS'
export const UPDATE_LOCATION_FAILURE = 'UPDATE_LOCATION_FAILURE'

export const DELETE_LOCATION_START = 'DELETE_LOCATION_START'
export const DELETE_LOCATION_SUCCESS = 'DELETE_LOCATION_SUCCESS'
export const DELETE_LOCATION_FAILURE = 'DELETE_LOCATION_FAILURE'

export const GEOCODE_START = 'GEOCODE_START'
export const GEOCODE_SUCCESS = 'GEOCODE_SUCCESS'
export const GEOCODE_FAILURE = 'GEOCODE_FAILURE'

export const REVERSE_GEOCODE_START = 'REVERSE_GEOCODE_START'
export const REVERSE_GEOCODE_SUCCESS = 'REVERSE_GEOCODE_SUCCESS'
export const REVERSE_GEOCODE_FAILURE = 'REVERSE_GEOCODE_FAILURE'

export const SHOW_NEW_LOCATION_DIALOG = 'SHOW_NEW_LOCATION_DIALOG'
export const HIDE_NEW_LOCATION_DIALOG = 'HIDE_NEW_LOCATION_DIALOG'
export const NEW_LOCATION_CHANGE = 'NEW_LOCATION_CHANGE'
export const CREATE_NEW_LOCATION_START = 'CREATE_NEW_LOCATION_START'
export const CREATE_NEW_LOCATION_SUCCESS = 'CREATE_NEW_LOCATION_SUCCESS'
export const CREATE_NEW_LOCATION_FAILURE = 'CREATE_NEW_LOCATION_FAILURE'

export const accountLocationsChange = ({ locations }) => ({
  type: ACCOUNT_LOCATIONS_CHANGE,
  payload: {
    locations
  }
})

export const loadAccountLocationsStart = () => ({
  type: LOAD_ACCOUNT_LOCATIONS_START
})

export const loadAccountLocationsSuccess = ({ locations }) => ({
  type: LOAD_ACCOUNT_LOCATIONS_SUCCESS,
  payload: {
    locations
  }
})

export const loadAccountLocationsFailure = ({ error }) => ({
  type: LOAD_ACCOUNT_LOCATIONS_FAILURE,
  payload: {
    error
  }
})

export const updateLocationStart = ({ id }) => ({
  type: UPDATE_LOCATION_START,
  payload: {
    id
  }
})

export const updateLocationSuccess = () => ({
  type: UPDATE_LOCATION_SUCCESS
})

export const updateLocationFailure = ({ error }) => ({
  type: UPDATE_LOCATION_FAILURE,
  payload: {
    error
  }
})

export const updateLocationAsync =
  ({ accountId, location }) =>
  (dispatch) => {
    const updateLocation = (_accountId, _location) => {
      dispatch(updateLocationStart({ id: _location.id }))

      accountService
        .updateLocation({ accountId: _accountId, location: _location })
        .then((result) => {
          dispatch(updateLocationSuccess())
          dispatch(openAppSnackbar({ message: intl.translate('account_locations_page__update_success') }))
        })
        .catch((error) => {
          dispatch(updateLocationFailure({ error: intl.translate('account_locations_page__update_error') }))
          dispatch(
            openAppSnackbar({
              message: intl.getServerError(error.response.data) || intl.translate('account_locations_page__update_error'),
              actionText: intl.translate('general__retry'),
              actionFunction: () => {
                dispatch(closeAppSnackbar())
                updateLocation(_accountId, _location)
              }
            })
          )
        })
    }

    updateLocation(accountId, location)
  }

export const deleteLocationStart = ({ id }) => ({
  type: DELETE_LOCATION_START,
  payload: {
    id
  }
})

export const deleteLocationSuccess = ({ id }) => ({
  type: DELETE_LOCATION_SUCCESS,
  payload: {
    id
  }
})

export const deleteLocationFailure = ({ error }) => ({
  type: DELETE_LOCATION_FAILURE,
  payload: {
    error
  }
})

export const deleteLocationAsync =
  ({ accountId, id }) =>
  (dispatch) => {
    const deleteLocation = (_accountId, _id) => {
      dispatch(deleteLocationStart({ id: _id }))

      accountService
        .deleteLocation({ accountId: _accountId, locationId: _id })
        .then((result) => {
          dispatch(deleteLocationSuccess({ id: _id }))
          dispatch(openAppSnackbar({ message: intl.translate('account_locations_page__delete_success') }))
        })
        .catch((error) => {
          dispatch(deleteLocationFailure({ error: intl.translate('account_locations_page__delete_error') }))
          dispatch(
            openAppSnackbar({
              message: intl.getServerError(error.response.data) || intl.translate('account_locations_page__delete_error'),
              actionText: intl.translate('general__retry'),
              actionFunction: () => {
                dispatch(closeAppSnackbar())
                deleteLocation(_accountId, _id)
              }
            })
          )
        })
    }

    deleteLocation(accountId, id)
  }

export const geocodeStart = ({ id }) => ({
  type: GEOCODE_START,
  payload: {
    id
  }
})

export const geocodeSuccess = () => ({
  type: GEOCODE_SUCCESS
})

export const geocodeFailure = ({ error }) => ({
  type: GEOCODE_FAILURE,
  payload: {
    error
  }
})

export const reverseGeocodeStart = ({ id }) => ({
  type: REVERSE_GEOCODE_START,
  payload: {
    id
  }
})

export const reverseGeocodeSuccess = () => ({
  type: REVERSE_GEOCODE_SUCCESS
})

export const reverseGeocodeFailure = ({ error }) => ({
  type: REVERSE_GEOCODE_FAILURE,
  payload: {
    error
  }
})

export const showNewLocationDialog = () => ({
  type: SHOW_NEW_LOCATION_DIALOG
})

export const hideNewLocationDialog = () => ({
  type: HIDE_NEW_LOCATION_DIALOG
})

export const newLocationChange = ({ location }) => ({
  type: NEW_LOCATION_CHANGE,
  payload: {
    location
  }
})

export const createNewLocationStart = () => ({
  type: CREATE_NEW_LOCATION_START
})

export const createNewLocationSuccess = ({ location }) => ({
  type: CREATE_NEW_LOCATION_SUCCESS,
  payload: {
    location
  }
})

export const createNewLocationFailure = ({ error }) => ({
  type: CREATE_NEW_LOCATION_FAILURE,
  payload: {
    error
  }
})

export const createNewLocationAsync =
  ({ accountId, location }) =>
  (dispatch) => {
    const createNewLocation = (_accountId, _location) => {
      dispatch(createNewLocationStart())

      accountService
        .createLocation({ accountId: _accountId, location: _location })
        .then((result) => {
          dispatch(createNewLocationSuccess({ location: result.data }))
          dispatch(hideNewLocationDialog())
          dispatch(openAppSnackbar({ message: intl.translate('account_locations_page__create_success') }))
        })
        .catch((error) => {
          dispatch(createNewLocationFailure({ error: intl.translate('account_locations_page__create_error') }))
          dispatch(
            openAppSnackbar({
              message: intl.getServerError(error.response.data) || intl.translate('account_locations_page__create_error'),
              actionText: intl.translate('general__retry'),
              actionFunction: () => {
                dispatch(closeAppSnackbar())
                createNewLocation(_accountId, _location)
              }
            })
          )
        })
    }

    createNewLocation(accountId, location)
  }
