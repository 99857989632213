import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(
  (theme) => ({
    'phone-confirmation-code-wrapper': {
      display: 'flex'
    },
    'phone-confirmation-code': {
      flex: '1'
    },
    'phone-confirmation-code-button': {
      flex: '0',
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(1)
    }
  }),
  { name: 'contact-confirmation-dialog-' }
)

export default useStyles
