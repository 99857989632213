import { useEffect, useRef, useState } from 'react'

import { Link, Paper, Typography } from '@mui/material'
import PageTitle from '../common/PageTitle'

import { Link as RouterLink } from 'react-router-dom'

import intl from '../../helper/intl'

import { useSelector } from 'react-redux'

import useStyles from './FaqPage.styles'
import accountService from '../../service/accountService'


const FaqPage = () => {
  const accountId = useSelector((state) => state.auth.accountId)
  const companyData = useSelector((state) => state.auth.companyData)
  const urlBasename = useSelector((state) => state.intl.urlBasename)
  const language = useSelector((state) => state.intl.language)
  const classes = useStyles()
  const [faqContent, setFaqContent] = useState('');
  const [faqTextContent, setFaqTextContent] = useState('');
  const iframeRef = useRef(null);

  useEffect(() => {
    document.title = intl.translate('faq_page__title') + ' | ' + (companyData.portal_title || intl.translate('app_header__title'))
  }, [companyData])

  useEffect(() => {
    if (iframeRef && iframeRef.current) {
      iframeRef.current.onload = () => {
        const iframeTextContent = iframeRef.current.contentWindow.document.body.textContent;
        if (!iframeTextContent || !iframeTextContent.replace(/[\n ]/g, '')) {
          setFaqTextContent(intl.translate('faq_page__title'));
        } else {
          setFaqTextContent(iframeTextContent);
        }
        
        try {
          iframeRef.current.height = '';
          const innerContentHeight = iframeRef.current.contentWindow.document.body.scrollHeight || 600;
          iframeRef.current.height = (innerContentHeight + 50) + 'px';
        } catch (error) {
          iframeRef.current.height = '';
          iframeRef.current.height = '650px';
        }
      }
    }
  }, [iframeRef]);

  useEffect(() => {
    if (language && companyData && companyData.name) {
      accountService.getFaqContent({ language: language, companyName: companyData.name })
      .then((result) => {
        setFaqContent(result.data);
      });
    }
  }, [language, companyData]);

  return (
    <Paper className={'_centered_container_ ' + classes.wrapper}>
      <PageTitle className={classes.title}>{intl.translate('faq_page__title')}</PageTitle>

      <div id="faq-page--content-wrapper" title={faqTextContent} role="presentation" tabIndex="1">
        <iframe
          id="faq-page--content"
          className={classes.iframe}
          ref={iframeRef}
          title={intl.translate('faq_page__title')}
          srcDoc={faqContent}
        />
      </div>

      {!accountId && (
        <Typography
          align="center"
          variant="body1"
          color={window.app.theme.palette.button ? 'link.main' : 'primary'}
        >
          <Link component={RouterLink} to={urlBasename} color="inherit">
            {intl.translate('password_request_page__link_to_login')}
          </Link>
        </Typography>
      )}
    </Paper>
  )
}

export default FaqPage
