import {
  COMPANY_DATA_CHANGE,
  LOAD_COMPANY_DATA_START,
  LOAD_COMPANY_DATA_SUCCESS,
  LOAD_COMPANY_DATA_FAILURE,
  REGISTER_START,
  REGISTER_SUCCESS,
  REGISTER_FAILURE,
  REGISTER_CREDENTIALS_CHANGE,
  DEREGISTER_START,
  DEREGISTER_SUCCESS,
  DEREGISTER_FAILURE,
  DEREGISTER_CREDENTIALS_CHANGE,
  LOGIN_START,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGIN_CREDENTIALS_CHANGE,
  LOGOUT,
  PASSWORD_REQUEST_DATA_CHANGE,
  REQUEST_PASSWORD_START,
  REQUEST_PASSWORD_SUCCESS,
  REQUEST_PASSWORD_FAILURE,
  PASSWORD_RESET_DATA_CHANGE,
  RESET_PASSWORD_START,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILURE,
  MFA_USER_DATA_CHANGE
} from '../actions/authAction'

const initialState = {
  companyData: {},
  companyDataLoading: false,
  companyDataError: '',

  registerLoading: false,
  registerError: '',
  registerCredentials: {
    firstName: '',
    lastName: '',
    language: '',
    email: '',
    countryCode: '',
    phoneNumber: '',
    password: '',
    passwordConfirm: '',
    phoneNumberSms: true,
    addressLine1: '',
    addressLine2: '',
    city: '',
    zipCode: '',
    state: '',
    country: '',
    longitude: '',
    latitude: ''
  },

  deregisterLoading: false,
  deregisterError: '',
  deregisterCredentials: {
    password: ''
  },

  accountId: sessionStorage.getItem('accountId') || '',
  accessToken: sessionStorage.getItem('accessToken') || '',
  refreshToken: sessionStorage.getItem('refreshToken') || '',

  loginLoading: false,
  loginError: '',
  loginCredentials: {
    email: '',
    countryCode: '',
    phoneNumber: '',
    externalId: '',
    password: ''
  },

  passwordRequestData: {
    email: '',
    countryCode: '',
    phoneNumber: ''
  },
  requestPasswordLoading: false,
  requestPasswordError: '',

  passwordResetData: {
    newPassword: '',
    newPasswordConfirm: ''
  },
  resetPasswordLoading: false,
  resetPasswordError: '',
  
  mfaUserData: {
    email: '',
    countryCode: '',
    phoneNumber: '',
  },
}

const reducer = (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case COMPANY_DATA_CHANGE: {
      return {
        ...state,
        companyData: payload.data
      }
    }
    case LOAD_COMPANY_DATA_START: {
      return {
        ...state,
        companyDataLoading: true,
        companyDataError: ''
      }
    }
    case LOAD_COMPANY_DATA_SUCCESS: {
      return {
        ...state,
        companyData: payload.data,
        companyDataLoading: false,
        companyDataError: ''
      }
    }
    case LOAD_COMPANY_DATA_FAILURE: {
      return {
        ...state,
        companyDataLoading: false,
        companyDataError: payload.error
      }
    }
    case REGISTER_START: {
      return {
        ...state,
        accountId: '',
        registerLoading: true,
        registerError: ''
      }
    }
    case REGISTER_SUCCESS: {
      sessionStorage.setItem('accountId', payload.accountId)
      sessionStorage.setItem('accessToken', payload.accessToken)
      sessionStorage.setItem('refreshToken', payload.refreshToken)
      return {
        ...state,
        accountId: payload.accountId,
        accessToken: payload.accessToken,
        refreshToken: payload.refreshToken,
        registerLoading: false,
        registerError: ''
      }
    }
    case REGISTER_FAILURE: {
      return {
        ...state,
        accountId: '',
        registerLoading: false,
        registerError: payload.error
      }
    }
    case REGISTER_CREDENTIALS_CHANGE: {
      return {
        ...state,
        registerCredentials: payload.credentials
      }
    }
    case DEREGISTER_START: {
      return {
        ...state,
        deregisterLoading: true,
        deregisterError: ''
      }
    }
    case DEREGISTER_SUCCESS: {
      sessionStorage.removeItem('accountId')
      return {
        ...state,
        deregisterLoading: false,
        deregisterError: ''
      }
    }
    case DEREGISTER_FAILURE: {
      return {
        ...state,
        deregisterLoading: false,
        deregisterError: payload.error
      }
    }
    case DEREGISTER_CREDENTIALS_CHANGE: {
      return {
        ...state,
        deregisterCredentials: payload.credentials
      }
    }
    case LOGIN_START: {
      return {
        ...state,
        accountId: '',
        loginLoading: true,
        loginError: ''
      }
    }
    case LOGIN_SUCCESS: {
      const updatedState = {
        ...state,
        loginLoading: false,
        loginError: '',
      }
      if (typeof payload.accountId === 'string') {
        updatedState.accountId = payload.accountId;
        sessionStorage.setItem('accountId', payload.accountId);
      }
      if (typeof payload.accessToken === 'string') {
        updatedState.accessToken = payload.accessToken;
        sessionStorage.setItem('accessToken', payload.accessToken);
      }
      if (typeof payload.refreshToken === 'string') {
        updatedState.refreshToken = payload.refreshToken;
        sessionStorage.setItem('refreshToken', payload.refreshToken);
      }
      return updatedState;
    }
    case LOGIN_FAILURE: {
      return {
        ...state,
        accountId: '',
        loginLoading: false,
        loginError: payload.error
      }
    }
    case LOGIN_CREDENTIALS_CHANGE: {
      return {
        ...state,
        loginCredentials: payload.credentials
      }
    }
    case LOGOUT: {
      sessionStorage.removeItem('accountId')
      sessionStorage.removeItem('accessToken')
      sessionStorage.removeItem('refreshToken')
      return {
        ...state
        // accountId: '' // updating the state here doesn't work well if we're reloading the whole app.
      }
    }
    case PASSWORD_REQUEST_DATA_CHANGE: {
      return {
        ...state,
        passwordRequestData: payload.data
      }
    }
    case REQUEST_PASSWORD_START: {
      return {
        ...state,
        requestPasswordLoading: true,
        requestPasswordError: ''
      }
    }
    case REQUEST_PASSWORD_SUCCESS: {
      return {
        ...state,
        requestPasswordLoading: false,
        requestPasswordError: ''
      }
    }
    case REQUEST_PASSWORD_FAILURE: {
      return {
        ...state,
        requestPasswordLoading: false,
        requestPasswordError: payload.error
      }
    }
    case PASSWORD_RESET_DATA_CHANGE: {
      return {
        ...state,
        passwordResetData: payload.data
      }
    }
    case RESET_PASSWORD_START: {
      return {
        ...state,
        resetPasswordLoading: true,
        resetPasswordError: ''
      }
    }
    case RESET_PASSWORD_SUCCESS: {
      return {
        ...state,
        resetPasswordLoading: false,
        resetPasswordError: ''
      }
    }
    case RESET_PASSWORD_FAILURE: {
      return {
        ...state,
        resetPasswordLoading: false,
        resetPasswordError: payload.error
      }
    }
    case MFA_USER_DATA_CHANGE: {
      return {
        ...state,
        mfaUserData: {
          ...state.mfaUserData,
          ...payload,
        }
      }
    }
    default: {
      return state
    }
  }
}

export default reducer
