import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(
  (theme) => ({
    separator: {
      marginTop: theme.spacing(1)
    },
    'sso-wrapper': {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2)
    },
    'link-to-register': {
      marginTop: theme.spacing(2)
    },
    'link-to-faq': {
      marginTop: theme.spacing(2)
    },
    'link-to-resend-mfa': {
      textAlign: 'right'
    },
    'p-mfa-authorize': {
      fontSize: '14px'
    },
  }),
  { name: 'login-page-' }
)

export default useStyles
