import { FormControl, FormLabel, FormGroup, Typography, Grid, Tooltip } from '@mui/material'
import { Delete as DeleteIcon } from '@mui/icons-material'
import { visuallyHidden } from '@mui/utils'

import TextInput from '../common/TextInput'
import EmailInput from '../common/EmailInput'
import IconButtonWithProgress from '../common/IconButtonWithProgress'

import intl from '../../helper/intl'
import validate from '../../helper/validate'

import useStyles from './EmailFieldset.styles'

const EmailFieldset = ({
  id,
  className,
  legend,
  autoFocus,
  disabled,
  emailConfirmed,
  onConfirmEmail,
  alias,
  email,
  onChange,
  onDelete,
  showDeleteProgress
}) => {
  const classes = useStyles()

  const onEmailFieldChange = (paramName, paramValue) => {
    if (typeof onChange === 'function') {
      const emailObj = { id, alias, email, emailConfirmed }
      emailObj[paramName] = paramValue
      onChange(emailObj)
    }
  }

  return (
    <FormControl
      className={classes.wrapper + (className ? ' ' + className : '')}
      id={'email-fieldset-' + id}
      component="fieldset"
      role="group"
    >
      {/* Legend of the fieldset, must be the first child of the fieldset. For screen readers only. */}
      {legend && (
        <Typography style={visuallyHidden} component="legend">
          {legend}
        </Typography>
      )}

      {/* Visible text that will act as the legend of the fieldset. Hidden for screen readers. */}
      {legend && (
        <FormLabel className={classes.legend} component="p" aria-hidden="true" role="presentation">
          {legend}
        </FormLabel>
      )}

      <FormGroup>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextInput
              id={'email-fieldset-' + id + '--alias'}
              label={intl.translate('account_emails_page__alias')}
              autoFocus={autoFocus}
              disabled={disabled}
              value={alias}
              onChange={(event) => onEmailFieldChange('alias', event.target.value)}
              isValid={(value) => !!value}
              errorMessage={intl.translate('general__empty_field_error')}
            />
          </Grid>
          <Grid item xs={12}>
            <EmailInput
              id={'email-fieldset-' + id + '--email'}
              label={intl.translate('account_emails_page__email')}
              disabled={disabled}
              confirmed={emailConfirmed}
              onConfirm={onConfirmEmail}
              value={email}
              onChange={(event) => onEmailFieldChange('email', event.target.value)}
              isValid={validate.isValidEmail}
              errorMessage={intl.translate('general__invalid_email')}
            />
            {(!emailConfirmed && legend) && <Typography variant="body1" paragraph>{intl.translate('email_input__description')}</Typography>}
          </Grid>
        </Grid>
      </FormGroup>

      {onDelete && (
        <Tooltip title={intl.translate('general__delete')}>
          {/* The <div> is necessary to avoid problems with the tooltip wrapping a disabled button */}
          <div className={classes['delete-btn']}>
            <IconButtonWithProgress
              showProgress={showDeleteProgress}
              aria-label={intl.translate('general__delete') + ' ' + legend}
              onClick={onDelete}
            >
              <DeleteIcon />
            </IconButtonWithProgress>
          </div>
        </Tooltip>
      )}
    </FormControl>
  )
}

export default EmailFieldset
