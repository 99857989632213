import { makeStyles } from '@mui/styles'

const inputPaddingTop = 16.5
const inputPaddingRight = 32
const inputPaddingBottom = 16.5
const inputPaddingLeft = 14

const inputZIndex = 2

const useStyles = makeStyles(
  (theme) => ({
    wrapper: {
      position: 'relative',
      '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
        border: '2px solid #295BFF'
      }
    },
    'wrapper-label-visually-hidden': {
      '& .MuiOutlinedInput-notchedOutline legend': {
        maxWidth: '0px'
      }
    },
    input: {
      position: 'relative',
      zIndex: inputZIndex // The select input needs to lay over the html-value for the click functionality to work properly
    },
    'input-transparent': {
      color: 'transparent'
    },
    option: {
      color: theme.palette.text.primary // necessary to set color of options because we set the color of the select input to transparent
    },
    'html-value': {
      position: 'absolute',
      top: inputPaddingTop,
      right: inputPaddingRight,
      bottom: inputPaddingBottom,
      left: inputPaddingLeft,
      display: 'block',
      zIndex: inputZIndex - 1, // The select input needs to lay over the html-value for the click functionality to work properly
      width: 'calc(100% - ' + (inputPaddingRight + inputPaddingLeft) + 'px)',
      maxWidth: 'calc(100% - ' + (inputPaddingRight + inputPaddingLeft) + 'px)',
      // lineHeight: '1.1875em', // Same line height as the Material UI select input
      fontSize: theme.typography.pxToRem(16),
      fontFamily: theme.typography.fontFamily,

      'body[dir="rtl"] &[dir="ltr"]': {
        // Edge case caused by this input used in ltr mode in a rtl language
        flip: false,
        textAlign: 'right'
      }
    },
    'html-value-disabled': {
      color: theme.palette.text.disabled
    }
  }),
  { name: 'native-select-input-' }
)

export default useStyles
