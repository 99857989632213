import TextInput from '../TextInput'

import intl from '../../../helper/intl'

import useStyles from './EmailInput.styles'
import ButtonWithProgress from '../ButtonWithProgress'

const EmailInput = ({ id, label, disabled, variant, margin, confirmed, onConfirm, value, onChange, ...otherProperties }) => {
  const classes = useStyles()

  return (
    <div className={classes.wrapper}>
      <div className={classes['email-wrapper']}>
        <TextInput
          id={id}
          label={label}
          disabled={disabled}
          value={value}
          onChange={onChange}
          variant={typeof variant !== 'undefined' ? variant : 'outlined'}
          margin={typeof margin !== 'undefined' ? margin : 'normal'}
          fullWidth={true}
          {...otherProperties}
        />
      </div>
      {confirmed === false && value && (
        <div className={classes['confirm-button-wrapper']}>
          <ButtonWithProgress variant="text" color={window.app.theme.palette.button ? 'button' : 'primary'} size="small" onClick={onConfirm}>
            {intl.translate('general__verify')}
          </ButtonWithProgress>
        </div>
      )}
    </div>
  )
}

export default EmailInput
