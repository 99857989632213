import { Link as RouterLink } from 'react-router-dom'

import intl from '../../helper/intl'
import utils from '../../helper/utils'

import { useSelector } from 'react-redux'

import useStyles from './AppFooter.styles'
import ButtonWithProgress from '../common/ButtonWithProgress'

const AppFooter = () => {
  const accountId = useSelector((state) => state.auth.accountId)
  const accountDetailsData = useSelector((state) => state.accountDetails.details)
  const urlBasename = useSelector((state) => state.intl.urlBasename)
  const classes = useStyles()

  const onMenuItemClick = (menuItemId) => {
    if (menuItemId !== "logout") {
      setTimeout(() => {
        // After changing to a new page, set the focus on the page title.
        // This action will help users using a keyboard to navigate the site.
        const pageTitleElement = document.getElementById('page-title');
        if (pageTitleElement) {
          pageTitleElement.focus();
        }
      }, 50);
    }
  }

  const menuItems = utils.getMenuItems(urlBasename, accountId, accountDetailsData.isFromSSO)

  return (
    <footer className={classes.wrapper}>
      <ul className={classes.menu}>
        {menuItems.map((menuItem, index) =>
          menuItem.footer ? (
            <li key={menuItem.id} className={classes['menu-item']}>
              <ButtonWithProgress
                variant="text"
                component={RouterLink}
                to={menuItem.url}
                onClick={() => onMenuItemClick(menuItem.id)}
              >
                {intl.translate(menuItem.name)}
              </ButtonWithProgress>
            </li>
          ) : null
        )}
      </ul>
    </footer>
  )
}

export default AppFooter
