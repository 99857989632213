import {
  ACCOUNT_EMAILS_CHANGE,
  LOAD_ACCOUNT_EMAILS_START,
  LOAD_ACCOUNT_EMAILS_SUCCESS,
  LOAD_ACCOUNT_EMAILS_FAILURE,
  UPDATE_EMAIL_START,
  UPDATE_EMAIL_SUCCESS,
  UPDATE_EMAIL_FAILURE,
  DELETE_EMAIL_START,
  DELETE_EMAIL_SUCCESS,
  DELETE_EMAIL_FAILURE,
  SHOW_NEW_EMAIL_DIALOG,
  HIDE_NEW_EMAIL_DIALOG,
  NEW_EMAIL_CHANGE,
  CREATE_NEW_EMAIL_START,
  CREATE_NEW_EMAIL_SUCCESS,
  CREATE_NEW_EMAIL_FAILURE
} from '../actions/accountEmailsAction'

const initialState = {
  emails: [],

  emailsLoading: true,
  emailsError: '',

  updateEmailLoading: false,
  updateEmailError: '',

  deleteEmailLoading: false,
  deleteEmailError: '',

  newEmailDialogIsOpen: false,
  newEmail: { alias: '', email: '' },
  createNewEmailLoading: false,
  createNewEmailError: ''
}

const reducer = (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case ACCOUNT_EMAILS_CHANGE: {
      return {
        ...state,
        emails: payload.emails
      }
    }
    case LOAD_ACCOUNT_EMAILS_START: {
      return {
        ...state,
        emailsLoading: true,
        emailsError: ''
      }
    }
    case LOAD_ACCOUNT_EMAILS_SUCCESS: {
      return {
        ...state,
        emails: payload.emails,
        emailsLoading: false,
        emailsError: ''
      }
    }
    case LOAD_ACCOUNT_EMAILS_FAILURE: {
      return {
        ...state,
        emailsLoading: false,
        emailsError: payload.error
      }
    }
    case UPDATE_EMAIL_START: {
      return {
        ...state,
        updateEmailLoading: payload.id,
        updateEmailError: ''
      }
    }
    case UPDATE_EMAIL_SUCCESS: {
      return {
        ...state,
        updateEmailLoading: false,
        updateEmailError: ''
      }
    }
    case UPDATE_EMAIL_FAILURE: {
      return {
        ...state,
        updateEmailLoading: false,
        updateEmailError: payload.error
      }
    }
    case DELETE_EMAIL_START: {
      return {
        ...state,
        deleteEmailLoading: payload.id,
        deleteEmailError: ''
      }
    }
    case DELETE_EMAIL_SUCCESS: {
      const updatedEmails = [...state.emails]
      const numEmails = updatedEmails.length
      for (let i = 0; i < numEmails; i++) {
        if (payload.id === updatedEmails[i].id) {
          updatedEmails.splice(i, 1) // remove email
          break
        }
      }
      return {
        ...state,
        emails: updatedEmails,
        deleteEmailLoading: false,
        deleteEmailError: ''
      }
    }
    case DELETE_EMAIL_FAILURE: {
      return {
        ...state,
        deleteEmailLoading: false,
        deleteEmailError: payload.error
      }
    }
    case SHOW_NEW_EMAIL_DIALOG: {
      return {
        ...state,
        newEmail: { alias: '', email: '' },
        newEmailDialogIsOpen: true
      }
    }
    case HIDE_NEW_EMAIL_DIALOG: {
      return {
        ...state,
        newEmailDialogIsOpen: false
      }
    }
    case NEW_EMAIL_CHANGE: {
      return {
        ...state,
        newEmail: payload.email
      }
    }
    case CREATE_NEW_EMAIL_START: {
      return {
        ...state,
        createNewEmailLoading: true,
        createNewEmailError: ''
      }
    }
    case CREATE_NEW_EMAIL_SUCCESS: {
      // Add new email to our array of emails
      const updatedEmails = [...state.emails]
      updatedEmails.push(payload.email)
      return {
        ...state,
        emails: updatedEmails,
        createNewEmailLoading: false,
        createNewEmailError: ''
      }
    }
    case CREATE_NEW_EMAIL_FAILURE: {
      return {
        ...state,
        createNewEmailLoading: false,
        createNewEmailError: payload.error
      }
    }
    default: {
      return state
    }
  }
}

export default reducer
