import { Autocomplete } from "@mui/material";

import useAutocompleteAddress from "./useAutocompleteAddress";
import TextInput from "../common/TextInput";

import intl from "../../helper/intl";

export default function AutocompleteAddress({
  id,
  label,
  value,
  noOptionsText,
  required,
  disabled,
  onChange,
  onInputChange,
  onFocus,
}) {
  const { predictions, loadingState } = useAutocompleteAddress(value);

  const options = predictions.map((prediction) => {
    const { formattedAddress } = prediction;
    return {
      label: formattedAddress,
      data: prediction,
    };
  });

  return (
    <Autocomplete
      id={id}
      fullWidth
      freeSolo
      disablePortal
      disableClearable
      inputValue={value}
      options={options}
      filterOptions={(options) => options}
      noOptionsText={noOptionsText}
      loading={loadingState}
      loadingText={intl.translate('general__loading')}
      onChange={(_, value) => onChange(value)}
      onFocus={onFocus}
      renderInput={(params) => {
        return (
          <TextInput
            listenToEvents
            required={required}
            disabled={disabled}
            label={label}
            onChange={(event) => onInputChange(event.target.value)}
            isValid={(value) => !!value}
            errorMessage={intl.translate('general__empty_field_error')}
            tabIndex={-1}
            {...params}
          />
        );
      }}
    />
  );
}