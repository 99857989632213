import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(
  (theme) => ({
    'phone-fieldset': {
      marginTop: theme.spacing(4),
      borderRadius: theme.shape.borderRadius
    },
    'update-btn': {
      marginTop: theme.spacing(2)
    },
    'create-btn': {
      marginTop: theme.spacing(4)
    }
  }),
  { name: 'account-phones-page-' }
)

export default useStyles
