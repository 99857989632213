import { useCallback, useEffect, useRef, useState } from "react";
import { debounce } from "lodash";

import locationService from "../../service/locationService";
 
export default function useAutocompleteAddress(input) {
  const [predictions, setPredictions] = useState([]);
  const [loadingState, setLoadingState] = useState(false);
  const debounceSeconds = 1;
 
  function getPlacePredictions(input) {
    setLoadingState(true);
    locationService.autocompleteAddress({ input })
      .then(results => setPredictions(results || []))
      .finally(() => setLoadingState(false));
  };
 
  const debouncedGetPlacePredictions = useCallback(
      debounce(getPlacePredictions, debounceSeconds * 1000),
      []
  );
 
  useEffect(() => {
    if (input && !(input.trim() === '')) {
      debouncedGetPlacePredictions(input)
    }
  }, [input]);
 
  return {
    predictions,
    loadingState
  };
}
