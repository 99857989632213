import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(
  (theme) => ({
    wrapper: {
      display: 'flex'
    },
    'country-code-wrapper': {
      width: '100px'
    },
    'phone-number-wrapper': {
      position: 'relative',
      flex: '1'
    },
    'phone-number-fake-mask': {
      position: 'absolute',
      top: theme.spacing(2),
      left: 0,
      margin: '16px 14px',
      pointerEvents: 'none',
    },
    'confirm-button-wrapper': {
      flex: '0',
      paddingTop: theme.spacing(2),

      '& .MuiButton-root': {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2)
      }
    }
  }),
  { name: 'phone-number-input-' }
)

export default useStyles
