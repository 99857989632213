import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(
  (theme) => ({
    wrapper: {
      fontWeight: '400',
      fontSize: 34,
      letterSpacing: 0,
      lineHeight: 1.25,
      textAlign: 'center'
    }
  }),
  { name: 'page-title-' }
)

export default useStyles
