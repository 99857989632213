import { makeStyles } from '@mui/styles'

const drawerWidth = 285

const useStyles = makeStyles(
  (theme) => ({
    'header-spacing': theme.mixins.toolbar,
    drawer: {
      minWidth: drawerWidth,
      width: 'auto',
      whiteSpace: 'nowrap',
      flexShrink: '0',

      [theme.breakpoints.down('md')]: {
        position: 'absolute'
      }
    },
    'drawer-open': {
      minWidth: drawerWidth,
      width: 'auto',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen
      })
    },
    'drawer-closed': {
      width: theme.spacing(1),
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      }),
      overflowX: 'hidden',
      borderRight: 'none',
      [theme.breakpoints.down('md')]: {
        width: 0
      }
    },
    'drawer-overlay': {
      position: 'fixed',
      top: 0,
      right: 0,
      left: 0,
      bottom: 0,
      width: '100%',
      height: '100%',
      zIndex: -1,
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      // Remove grey highlight
      WebkitTapHighlightColor: 'transparent',
      // Disable scroll capabilities.
      touchAction: 'none',
      opacity: 0,
      transition: theme.transitions.create(['zIndex', 'opacity'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen
      })
    },
    'drawer-overlay-active': {
      [theme.breakpoints.down('md')]: {
        zIndex: theme.zIndex.drawer - 1,
        opacity: 1
      }
    },
    'drawer-list-wrapper': {
      position: 'relative',
      width: '100%'
    },
    'drawer-list': {
      width: '100%'
    },
    'item-button': {
      display: 'flex',
      gap: '8px'
    },
    item: {
      padding: 0,
      color: 'inherit',

      '& .MuiListItemIcon-root': {
        'min-width': '32px'
      },

      '& .MuiButtonBase-root.MuiListItemButton-root.Mui-focusVisible': {
        boxShadow: 'inset 0 0 0 1px #0033FF',
        backgroundColor: '#fff'
      },

      '& .MuiButtonBase-root.MuiListItemButton-root.Mui-selected': {
        color: '#0033FF',
        backgroundColor: '#E6EFFF'
      },

      '& .MuiButtonBase-root.MuiListItemButton-root.Mui-selected svg': {
        color: '#0033FF'
      },

      '& .MuiButtonBase-root.MuiListItemButton-root.Mui-focusVisible.Mui-selected': {
        boxShadow: 'inset 0 0 0 1px #0033FF, inset 0 0 0 2px #fff'
      }
    },
    'item-text': {
      whiteSpace: 'normal',

      '& .MuiTypography-root': {
        lineHeight: 1
      }
    },
    chip: {
      background: '#FFF1F0',
      border: '1px solid #FFA39E',
      borderRadius: '4px'
    }
  }),
  { name: 'navigation-menu-' }
)

export default useStyles
