import accountService from '../service/accountService'
import { openAppSnackbar, closeAppSnackbar } from './notificationsAction'
import intl from '../helper/intl'

export const ACCOUNT_EMAILS_CHANGE = 'ACCOUNT_EMAILS_CHANGE'

export const LOAD_ACCOUNT_EMAILS_START = 'LOAD_ACCOUNT_EMAILS_START'
export const LOAD_ACCOUNT_EMAILS_SUCCESS = 'LOAD_ACCOUNT_EMAILS_SUCCESS'
export const LOAD_ACCOUNT_EMAILS_FAILURE = 'LOAD_ACCOUNT_EMAILS_FAILURE'

export const UPDATE_EMAIL_START = 'UPDATE_EMAIL_START'
export const UPDATE_EMAIL_SUCCESS = 'UPDATE_EMAIL_SUCCESS'
export const UPDATE_EMAIL_FAILURE = 'UPDATE_EMAIL_FAILURE'

export const DELETE_EMAIL_START = 'DELETE_EMAIL_START'
export const DELETE_EMAIL_SUCCESS = 'DELETE_EMAIL_SUCCESS'
export const DELETE_EMAIL_FAILURE = 'DELETE_EMAIL_FAILURE'

export const SHOW_NEW_EMAIL_DIALOG = 'SHOW_NEW_EMAIL_DIALOG'
export const HIDE_NEW_EMAIL_DIALOG = 'HIDE_NEW_EMAIL_DIALOG'
export const NEW_EMAIL_CHANGE = 'NEW_EMAIL_CHANGE'
export const CREATE_NEW_EMAIL_START = 'CREATE_NEW_EMAIL_START'
export const CREATE_NEW_EMAIL_SUCCESS = 'CREATE_NEW_EMAIL_SUCCESS'
export const CREATE_NEW_EMAIL_FAILURE = 'CREATE_NEW_EMAIL_FAILURE'

export const accountEmailsChange = ({ emails }) => ({
  type: ACCOUNT_EMAILS_CHANGE,
  payload: {
    emails
  }
})

export const loadAccountEmailsStart = () => ({
  type: LOAD_ACCOUNT_EMAILS_START
})

export const loadAccountEmailsSuccess = ({ emails }) => ({
  type: LOAD_ACCOUNT_EMAILS_SUCCESS,
  payload: {
    emails
  }
})

export const loadAccountEmailsFailure = ({ error }) => ({
  type: LOAD_ACCOUNT_EMAILS_FAILURE,
  payload: {
    error
  }
})

export const updateEmailStart = ({ id }) => ({
  type: UPDATE_EMAIL_START,
  payload: {
    id
  }
})

export const updateEmailSuccess = () => ({
  type: UPDATE_EMAIL_SUCCESS
})

export const updateEmailFailure = ({ error }) => ({
  type: UPDATE_EMAIL_FAILURE,
  payload: {
    error
  }
})

export const deleteEmailStart = ({ id }) => ({
  type: DELETE_EMAIL_START,
  payload: {
    id
  }
})

export const deleteEmailSuccess = ({ id }) => ({
  type: DELETE_EMAIL_SUCCESS,
  payload: {
    id
  }
})

export const deleteEmailFailure = ({ error }) => ({
  type: DELETE_EMAIL_FAILURE,
  payload: {
    error
  }
})

export const deleteEmailAsync =
  ({ accountId, id }) =>
    (dispatch) => {
      const deleteEmail = (_accountId, _id) => {
        dispatch(deleteEmailStart({ id: _id }))

        accountService
          .deleteEmail({ accountId: _accountId, emailId: _id })
          .then((result) => {
            dispatch(deleteEmailSuccess({ id: _id }))
            dispatch(openAppSnackbar({ message: intl.translate('account_emails_page__delete_success') }))
          })
          .catch((error) => {
            dispatch(deleteEmailFailure({ error: intl.translate('account_emails_page__delete_error') }))
            dispatch(
              openAppSnackbar({
                message: intl.getServerError(error.response.data) || intl.translate('account_emails_page__delete_error'),
                actionText: intl.translate('general__retry'),
                actionFunction: () => {
                  dispatch(closeAppSnackbar())
                  deleteEmail(_accountId, _id)
                }
              })
            )
          })
      }

      deleteEmail(accountId, id)
    }

export const showNewEmailDialog = () => ({
  type: SHOW_NEW_EMAIL_DIALOG
})

export const hideNewEmailDialog = () => ({
  type: HIDE_NEW_EMAIL_DIALOG
})

export const newEmailChange = ({ email }) => ({
  type: NEW_EMAIL_CHANGE,
  payload: {
    email
  }
})

export const createNewEmailStart = () => ({
  type: CREATE_NEW_EMAIL_START
})

export const createNewEmailSuccess = ({ email }) => ({
  type: CREATE_NEW_EMAIL_SUCCESS,
  payload: {
    email
  }
})

export const createNewEmailFailure = ({ error }) => ({
  type: CREATE_NEW_EMAIL_FAILURE,
  payload: {
    error
  }
})

export const createNewEmailAsync =
  ({ accountId, email }) =>
    (dispatch) => {
      const createNewEmail = (_accountId, _email) => {
        dispatch(createNewEmailStart())

        email.email = email.email.toLowerCase() // Email addresses should be all lowercase

        accountService
          .createEmail({ accountId: _accountId, email: _email })
          .then((result) => {
            dispatch(createNewEmailSuccess({ email: result.data }))
            dispatch(hideNewEmailDialog())
            dispatch(openAppSnackbar({ message: intl.translate('account_emails_page__create_success') }))
          })
          .catch((error) => {
            dispatch(createNewEmailFailure({ error: intl.translate('account_emails_page__create_error') }))
            dispatch(
              openAppSnackbar({
                message: intl.getServerError(error.response.data) || intl.translate('account_emails_page__create_error'),
                actionText: intl.translate('general__retry'),
                actionFunction: () => {
                  dispatch(closeAppSnackbar())
                  createNewEmail(_accountId, _email)
                }
              })
            )
          })
      }

      createNewEmail(accountId, email)
    }
