import {
  ACCOUNT_LOCATIONS_CHANGE,
  LOAD_ACCOUNT_LOCATIONS_START,
  LOAD_ACCOUNT_LOCATIONS_SUCCESS,
  LOAD_ACCOUNT_LOCATIONS_FAILURE,
  UPDATE_LOCATION_START,
  UPDATE_LOCATION_SUCCESS,
  UPDATE_LOCATION_FAILURE,
  DELETE_LOCATION_START,
  DELETE_LOCATION_SUCCESS,
  DELETE_LOCATION_FAILURE,
  GEOCODE_START,
  GEOCODE_SUCCESS,
  GEOCODE_FAILURE,
  REVERSE_GEOCODE_START,
  REVERSE_GEOCODE_SUCCESS,
  REVERSE_GEOCODE_FAILURE,
  SHOW_NEW_LOCATION_DIALOG,
  HIDE_NEW_LOCATION_DIALOG,
  NEW_LOCATION_CHANGE,
  CREATE_NEW_LOCATION_START,
  CREATE_NEW_LOCATION_SUCCESS,
  CREATE_NEW_LOCATION_FAILURE
} from '../actions/accountLocationsAction'

const initialState = {
  locations: [],

  locationsLoading: true,
  locationsError: '',

  updateLocationLoading: false,
  updateLocationError: '',

  deleteLocationLoading: false,
  deleteLocationError: '',

  geocodeLoading: false,
  geocodeError: '',

  reverseGeocodeLoading: false,
  reverseGeocodeError: '',

  newLocationDialogIsOpen: false,
  newLocation: { name: '', line1: '', line2: '', city: '', postcode: '', state: '', country: '', longitude: '', latitude: '' },
  createNewLocationLoading: false,
  createNewLocationError: ''
}

const reducer = (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case ACCOUNT_LOCATIONS_CHANGE: {
      return {
        ...state,
        locations: payload.locations
      }
    }
    case LOAD_ACCOUNT_LOCATIONS_START: {
      return {
        ...state,
        locationsLoading: true,
        locationsError: ''
      }
    }
    case LOAD_ACCOUNT_LOCATIONS_SUCCESS: {
      return {
        ...state,
        locations: payload.locations,
        locationsLoading: false,
        locationsError: ''
      }
    }
    case LOAD_ACCOUNT_LOCATIONS_FAILURE: {
      return {
        ...state,
        locationsLoading: false,
        locationsError: payload.error
      }
    }
    case UPDATE_LOCATION_START: {
      return {
        ...state,
        updateLocationLoading: payload.id,
        updateLocationError: ''
      }
    }
    case UPDATE_LOCATION_SUCCESS: {
      return {
        ...state,
        updateLocationLoading: false,
        updateLocationError: ''
      }
    }
    case UPDATE_LOCATION_FAILURE: {
      return {
        ...state,
        updateLocationLoading: false,
        updateLocationError: payload.error
      }
    }
    case DELETE_LOCATION_START: {
      return {
        ...state,
        deleteLocationLoading: payload.id,
        deleteLocationError: ''
      }
    }
    case DELETE_LOCATION_SUCCESS: {
      const updatedLocations = [...state.locations]
      const numLocations = updatedLocations.length
      for (let i = 0; i < numLocations; i++) {
        if (payload.id === updatedLocations[i].id) {
          updatedLocations.splice(i, 1) // remove location
          break
        }
      }
      return {
        ...state,
        locations: updatedLocations,
        deleteLocationLoading: false,
        deleteLocationError: ''
      }
    }
    case DELETE_LOCATION_FAILURE: {
      return {
        ...state,
        deleteLocationLoading: false,
        deleteLocationError: payload.error
      }
    }
    case GEOCODE_START: {
      return {
        ...state,
        geocodeLoading: payload.id,
        geocodeError: ''
      }
    }
    case GEOCODE_SUCCESS: {
      return {
        ...state,
        geocodeLoading: false,
        geocodeError: ''
      }
    }
    case GEOCODE_FAILURE: {
      return {
        ...state,
        geocodeLoading: false,
        geocodeError: payload.error
      }
    }
    case REVERSE_GEOCODE_START: {
      return {
        ...state,
        reverseGeocodeLoading: payload.id,
        reverseGeocodeError: ''
      }
    }
    case REVERSE_GEOCODE_SUCCESS: {
      return {
        ...state,
        reverseGeocodeLoading: false,
        reverseGeocodeError: ''
      }
    }
    case REVERSE_GEOCODE_FAILURE: {
      return {
        ...state,
        reverseGeocodeLoading: false,
        reverseGeocodeError: payload.error
      }
    }
    case SHOW_NEW_LOCATION_DIALOG: {
      return {
        ...state,
        newLocation: { name: '', line1: '', line2: '', city: '', postcode: '', state: '', country: '', longitude: '', latitude: '' },
        newLocationDialogIsOpen: true
      }
    }
    case HIDE_NEW_LOCATION_DIALOG: {
      return {
        ...state,
        newLocationDialogIsOpen: false
      }
    }
    case NEW_LOCATION_CHANGE: {
      return {
        ...state,
        newLocation: payload.location
      }
    }
    case CREATE_NEW_LOCATION_START: {
      return {
        ...state,
        createNewLocationLoading: true,
        createNewLocationError: ''
      }
    }
    case CREATE_NEW_LOCATION_SUCCESS: {
      // Add new location to our array of locations
      const updatedLocations = [...state.locations]
      updatedLocations.push(payload.location)
      return {
        ...state,
        locations: updatedLocations,
        createNewLocationLoading: false,
        createNewLocationError: ''
      }
    }
    case CREATE_NEW_LOCATION_FAILURE: {
      return {
        ...state,
        createNewLocationLoading: false,
        createNewLocationError: payload.error
      }
    }
    default: {
      return state
    }
  }
}

export default reducer
