import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(
  (theme) => ({
    wrapper: {
      display: 'flex'
    },
    'email-wrapper': {
      flex: '1'
    },
    'confirm-button-wrapper': {
      flex: '0',
      paddingTop: theme.spacing(2),

      '& .MuiButton-root': {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2)
      }
    }
  }),
  { name: 'email-input-' }
)

export default useStyles
