const config = (() => {
  // Public variables
  const apiUrl = (window.__env__ ? window.__env__.BACKEND_API_URL || '' : '') + '/api'
  const oauthUrl = (window.__env__ ? window.__env__.BACKEND_API_URL || '' : '') + '/oauth/token'

  // Private variables - we will define getters and setters for them
  let _companyId = sessionStorage.getItem('companyId') || '0'
  let _companyName = sessionStorage.getItem('companyName') || ''
  let _accessToken = sessionStorage.getItem('accessToken') || ''
  let _refreshToken = sessionStorage.getItem('refreshToken') || ''

  const getCompanyId = () => {
    return _companyId
  }

  const setCompanyId = (id) => {
    _companyId = id
    sessionStorage.setItem('companyId', id)
  }

  const getCompanyName = () => {
    return _companyName
  }

  const setCompanyName = (name) => {
    _companyName = name
    sessionStorage.setItem('companyName', name)
  }

  const getFaqUrl = () => {
    return window.location.origin + '/resources/' + getCompanyName() + '/web/faq.html'
  }

  const getAccessToken = () => {
    return _accessToken
  }

  const setAccessToken = (token) => {
    _accessToken = token
    sessionStorage.setItem('accessToken', token)
  }

  const getRefreshToken = () => {
    return _refreshToken
  }

  const setRefreshToken = (token) => {
    _refreshToken = token
    sessionStorage.setItem('refreshToken', token)
  }

  return {
    apiUrl,
    oauthUrl,
    getCompanyId,
    setCompanyId,
    getCompanyName,
    setCompanyName,
    getFaqUrl,
    getAccessToken,
    setAccessToken,
    getRefreshToken,
    setRefreshToken
  }
})()

export default config
