import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(
  (theme) => ({
    'wrapper-horizontal': {
      display: 'flex',
      alignItems: 'center',
      width: '100%',

      '&:before, &:after': {
        content: "''",
        flex: 1,
        borderBottom: '1px solid ' + theme.palette.divider
      }
    },
    'wrapper-vertical': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      height: '100%',

      '&:before, &:after': {
        content: "''",
        flex: 1,
        borderLeft: '1px solid ' + theme.palette.divider
      }
    },
    'text-horizontal': {
      color: theme.palette.text.secondary,
      paddingRight: theme.spacing(1),
      paddingLeft: theme.spacing(1)
    },
    'text-vertical': {
      color: theme.palette.text.secondary,
      paddingTop: theme.spacing(0.5),
      paddingBottom: theme.spacing(0.5)
    }
  }),
  { name: 'separator-' }
)

export default useStyles
