import {
  SHOW_APP_SNACKBAR,
  APP_SNACKBAR_CONTENT_CHANGE,
  HIDE_APP_SNACKBAR,
  SHOW_APP_DIALOG,
  APP_DIALOG_CONTENT_CHANGE,
  HIDE_APP_DIALOG,
  SHOW_CONTACT_CONFIRMATION_DIALOG,
  CONTACT_CONFIRMATION_DIALOG_PARAMS_CHANGE,
  HIDE_CONTACT_CONFIRMATION_DIALOG
} from '../actions/notificationsAction'

const initialState = {
  appSnackbarIsOpen: false,
  appSnackbarMessage: '',
  appSnackbarTitle: '',
  appSnackbarDescription: '',
  appSnackbarDuration: null,
  appSnackbarActionText: '',
  appSnackbarActionFunction: null,

  appDialogIsOpen: false,
  appDialogTitle: '',
  appDialogDescription: '',
  appDialogActionText: '',
  appDialogActionFunction: null,
  appDialogAutoFocusButton: '',

  contactConfirmationDialogIsOpen: false,
  contactConfirmationDialogEmail: '',
  contactConfirmationDialogPhoneNumber: ''
}

const reducer = (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case SHOW_APP_SNACKBAR:
      return {
        ...state,
        appSnackbarIsOpen: true
      }

    case HIDE_APP_SNACKBAR:
      return {
        ...state,
        appSnackbarIsOpen: false
      }

    case APP_SNACKBAR_CONTENT_CHANGE:
      return {
        ...state,
        appSnackbarMessage: payload.message,
        appSnackbarTitle: payload.title,
        appSnackbarDescription: payload.description,
        appSnackbarDuration: payload.duration,
        appSnackbarActionText: payload.actionText,
        appSnackbarActionFunction: payload.actionFunction
      }

    case SHOW_APP_DIALOG:
      return {
        ...state,
        appDialogIsOpen: true
      }

    case HIDE_APP_DIALOG:
      return {
        ...state,
        appDialogIsOpen: false
      }

    case APP_DIALOG_CONTENT_CHANGE:
      return {
        ...state,
        appDialogTitle: payload.title,
        appDialogDescription: payload.description,
        appDialogActionText: payload.actionText,
        appDialogActionFunction: payload.actionFunction,
        appDialogAutoFocusButton: payload.autoFocusButton
      }

    case SHOW_CONTACT_CONFIRMATION_DIALOG:
      return {
        ...state,
        contactConfirmationDialogIsOpen: true
      }

    case CONTACT_CONFIRMATION_DIALOG_PARAMS_CHANGE:
      return {
        ...state,
        contactConfirmationDialogEmail: payload.email || '',
        contactConfirmationDialogPhoneNumber: payload.phoneNumber || ''
      }

    case HIDE_CONTACT_CONFIRMATION_DIALOG:
      return {
        ...state,
        contactConfirmationDialogIsOpen: false
      }

    default:
      return state
  }
}

export default reducer
