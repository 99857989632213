import intl from '../helper/intl'

import {
  LANGUAGE_CHANGE,
  AVAILABLE_LANGUAGES_CHANGE,
  LOAD_AVAILABLE_LANGUAGES_START,
  LOAD_AVAILABLE_LANGUAGES_SUCCESS,
  LOAD_AVAILABLE_LANGUAGES_FAILURE
} from '../actions/intlAction'

/**
 * NOTE ON THIS FILE: Before updating the state variables related to translations and language, we need to call intl.setLanguageAndTranslations
 * That way when the state refreshes, the intl module will have updated the translations and the function intl.translate will translate to the correct language.
 */

// Get initial language from the URL
let initialLanguage = intl.getUrlLanguage() || intl.getBrowserLanguage() || intl.getDefaultLanguage()
// If the URL contains a language for which we don't have translations, revert to default language
if (!intl.setLanguageAndTranslations(initialLanguage)) {
  initialLanguage = intl.getDefaultLanguage()
}

// The state of this reducer contains all the variables used by our app that depend on the language
const initialState = {
  language: initialLanguage,
  languageDirection: intl.getLanguageDirection(initialLanguage),
  urlBasename: '/' + initialLanguage,
  availableLanguages: [{ name: intl.translate('app_header__language_select_en'), value: 'en' }],
  availableLanguagesLoading: false,
  availableLanguagesError: ''
}

const reducer = (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case LANGUAGE_CHANGE: {
      if (intl.setLanguageAndTranslations(payload.language)) {
        return {
          ...state,
          language: payload.language,
          languageDirection: intl.getLanguageDirection(payload.language),
          urlBasename: '/' + payload.language
        }
      } else {
        return state
      }
    }
    case AVAILABLE_LANGUAGES_CHANGE: {
      return {
        ...state,
        availableLanguages: payload.languages
      }
    }
    case LOAD_AVAILABLE_LANGUAGES_START: {
      return {
        ...state,
        availableLanguagesLoading: true,
        availableLanguagesError: ''
      }
    }
    case LOAD_AVAILABLE_LANGUAGES_SUCCESS: {
      return {
        ...state,
        availableLanguages: payload.languages,
        availableLanguagesLoading: false,
        availableLanguagesError: ''
      }
    }
    case LOAD_AVAILABLE_LANGUAGES_FAILURE: {
      return {
        ...state,
        availableLanguagesLoading: false,
        availableLanguagesError: payload.error
      }
    }
    default: {
      return state
    }
  }
}

export default reducer
