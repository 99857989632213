import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(
  (theme) => ({
    wrapper: {
      '&._centered_container_': {
        maxWidth: '1200px'
      }
    },
    'location-form': {
      marginTop: theme.spacing(4)
    },
    'geolocation-fieldset': {
      [theme.breakpoints.up('sm')]: {
        marginTop: theme.spacing(6)
      }
    },
    'update-btn': {
      marginTop: theme.spacing(2)
    },
    'create-btn': {
      marginTop: theme.spacing(4)
    }
  }),
  { name: 'account-locations-page-' }
)

export default useStyles
