import { loadAvailableLanguagesStart, loadAvailableLanguagesSuccess, loadAvailableLanguagesFailure } from './intlAction'

import authService from '../service/authService'

export const COMPANY_DATA_CHANGE = 'COMPANY_DATA_CHANGE'

export const LOAD_COMPANY_DATA_START = 'LOAD_COMPANY_DATA_START'
export const LOAD_COMPANY_DATA_SUCCESS = 'LOAD_COMPANY_DATA_SUCCESS'
export const LOAD_COMPANY_DATA_FAILURE = 'LOAD_COMPANY_DATA_FAILURE'

export const REGISTER_START = 'REGISTER_START'
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS'
export const REGISTER_FAILURE = 'REGISTER_FAILURE'

export const REGISTER_CREDENTIALS_CHANGE = 'REGISTER_CREDENTIALS_CHANGE'

export const DEREGISTER_START = 'DEREGISTER_START'
export const DEREGISTER_SUCCESS = 'DEREGISTER_SUCCESS'
export const DEREGISTER_FAILURE = 'DEREGISTER_FAILURE'

export const DEREGISTER_CREDENTIALS_CHANGE = 'DEREGISTER_CREDENTIALS_CHANGE'

export const LOGIN_START = 'LOGIN_START'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAILURE = 'LOGIN_FAILURE'

export const LOGIN_CREDENTIALS_CHANGE = 'LOGIN_CREDENTIALS_CHANGE'

export const LOGOUT = 'LOGOUT'

export const PASSWORD_REQUEST_DATA_CHANGE = 'PASSWORD_REQUEST_DATA_CHANGE'
export const REQUEST_PASSWORD_START = 'REQUEST_PASSWORD_START'
export const REQUEST_PASSWORD_SUCCESS = 'REQUEST_PASSWORD_SUCCESS'
export const REQUEST_PASSWORD_FAILURE = 'REQUEST_PASSWORD_FAILURE'

export const PASSWORD_RESET_DATA_CHANGE = 'PASSWORD_RESET_DATA_CHANGE'
export const RESET_PASSWORD_START = 'RESET_PASSWORD_START'
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS'
export const RESET_PASSWORD_FAILURE = 'RESET_PASSWORD_FAILURE'

export const MFA_USER_DATA_CHANGE = 'MFA_USER_DATA_CHANGE'

export const companyDataChange = ({ data }) => ({
  type: COMPANY_DATA_CHANGE,
  payload: {
    data
  }
})

export const loadCompanyDataStart = () => ({
  type: LOAD_COMPANY_DATA_START
})

export const loadCompanyDataSuccess = ({ data }) => ({
  type: LOAD_COMPANY_DATA_SUCCESS,
  payload: {
    data
  }
})

export const loadCompanyDataFailure = ({ error }) => ({
  type: LOAD_COMPANY_DATA_FAILURE,
  payload: {
    error
  }
})

export const loadCompanyDataAsync = () => (dispatch) => {
  dispatch(loadCompanyDataStart())
  dispatch(loadAvailableLanguagesStart())

  authService
    .getCompanyData()
    .then((result) => {
      const availableLanguages = []
      const numLanguages = result.data.lang ? result.data.lang.length : 0
      for (let i = 0; i < numLanguages; i++) {
        availableLanguages.push({
          name: result.data.lang[i].language,
          value: result.data.lang[i].code
        })
      }

      dispatch(loadCompanyDataSuccess({ data: result.data }))
      dispatch(loadAvailableLanguagesSuccess({ languages: availableLanguages }))
    })
    .catch((error) => {
      dispatch(loadCompanyDataFailure({ error: '' }))
      dispatch(loadAvailableLanguagesFailure({ error: '' }))
      console.error(error)
    })
}

export const registerStart = () => ({
  type: REGISTER_START
})

export const registerSuccess = ({ accountId, accessToken, refreshToken }) => ({
  type: REGISTER_SUCCESS,
  payload: {
    accountId,
    accessToken,
    refreshToken
  }
})

export const registerFailure = ({ error }) => ({
  type: REGISTER_FAILURE,
  payload: {
    error
  }
})

export const registerCredentialsChange = ({ credentials }) => ({
  type: REGISTER_CREDENTIALS_CHANGE,
  payload: {
    credentials
  }
})

export const deregisterStart = () => ({
  type: DEREGISTER_START
})

export const deregisterSuccess = () => ({
  type: DEREGISTER_SUCCESS
})

export const deregisterFailure = ({ error }) => ({
  type: DEREGISTER_FAILURE,
  payload: {
    error
  }
})

export const deregisterCredentialsChange = ({ credentials }) => ({
  type: DEREGISTER_CREDENTIALS_CHANGE,
  payload: {
    credentials
  }
})

export const loginStart = () => ({
  type: LOGIN_START
})

export const loginSuccess = ({ accountId, accessToken, refreshToken }) => ({
  type: LOGIN_SUCCESS,
  payload: {
    accountId,
    accessToken,
    refreshToken
  }
})

export const loginFailure = ({ error }) => ({
  type: LOGIN_FAILURE,
  payload: {
    error
  }
})

export const loginCredentialsChange = ({ credentials }) => ({
  type: LOGIN_CREDENTIALS_CHANGE,
  payload: {
    credentials
  }
})

export const logout = () => ({
  type: LOGOUT
})

export const passwordRequestDataChange = ({ data }) => ({
  type: PASSWORD_REQUEST_DATA_CHANGE,
  payload: {
    data
  }
})

export const requestPasswordStart = () => ({
  type: REQUEST_PASSWORD_START
})

export const requestPasswordSuccess = () => ({
  type: REQUEST_PASSWORD_SUCCESS
})

export const requestPasswordFailure = ({ error }) => ({
  type: REQUEST_PASSWORD_FAILURE,
  payload: {
    error
  }
})

export const passwordResetDataChange = ({ data }) => ({
  type: PASSWORD_RESET_DATA_CHANGE,
  payload: {
    data
  }
})

export const resetPasswordStart = () => ({
  type: RESET_PASSWORD_START
})

export const resetPasswordSuccess = () => ({
  type: RESET_PASSWORD_SUCCESS
})

export const resetPasswordFailure = ({ error }) => ({
  type: RESET_PASSWORD_FAILURE,
  payload: {
    error
  }
})

export const mfaUserDataChange = ({ email, countryCode, phoneNumber }) => ({
  type: MFA_USER_DATA_CHANGE,
  payload: {
    email,
    countryCode,
    phoneNumber,
  }
})