import {
  ACCOUNT_DETAILS_CHANGE,
  LOAD_ACCOUNT_DETAILS_START,
  LOAD_ACCOUNT_DETAILS_SUCCESS,
  LOAD_ACCOUNT_DETAILS_FAILURE,
  UPDATE_ACCOUNT_DETAILS_START,
  UPDATE_ACCOUNT_DETAILS_SUCCESS,
  UPDATE_ACCOUNT_DETAILS_FAILURE
} from '../actions/accountDetailsAction'

const initialState = {
  details: {
    firstName: '',
    lastName: '',
    email: '',
    emailConfirmed: true,
    language: '',
    countryCode: '',
    phoneNumber: '',
    phoneNumberConfirmed: true,
    externalId: '',
    password: '',
    newPassword: '',
    phoneNumberSms: false,
    newPasswordConfirm: '',
    passwordUpdateNeeded: false
  },

  customFields: [],

  detailsLoading: true,
  detailsError: '',

  updateDetailsLoading: false,
  updateDetailsError: ''
}

const reducer = (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case ACCOUNT_DETAILS_CHANGE: {
      const newState = { ...state }
      if (payload.details) {
        newState.details = payload.details
      }
      if (payload.customFields) {
        newState.customFields = payload.customFields
      }
      return newState
    }
    case LOAD_ACCOUNT_DETAILS_START: {
      return {
        ...state,
        detailsLoading: true,
        detailsError: ''
      }
    }
    case LOAD_ACCOUNT_DETAILS_SUCCESS: {
      const newState = {
        ...state,
        detailsLoading: false,
        detailsError: ''
      }
      if (payload.details) {
        newState.details = payload.details
      }
      if (payload.customFields) {
        newState.customFields = payload.customFields
      }
      return newState
    }
    case LOAD_ACCOUNT_DETAILS_FAILURE: {
      return {
        ...state,
        detailsLoading: false,
        detailsError: payload.error
      }
    }
    case UPDATE_ACCOUNT_DETAILS_START: {
      return {
        ...state,
        updateDetailsLoading: true,
        updateDetailsError: ''
      }
    }
    case UPDATE_ACCOUNT_DETAILS_SUCCESS: {
      return {
        ...state,
        updateDetailsLoading: false,
        updateDetailsError: ''
      }
    }
    case UPDATE_ACCOUNT_DETAILS_FAILURE: {
      return {
        ...state,
        updateDetailsLoading: false,
        updateDetailsError: payload.error
      }
    }
    default: {
      return state
    }
  }
}

export default reducer
