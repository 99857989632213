import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(
  (theme) => ({
    wrapper: {
      display: 'flex',
      flexWrap: 'wrap',
      position: 'relative',
      minHeight: '100vh'
    },
    'skip-to-content-link': {
      position: 'fixed',
      top: theme.spacing(-8),
      left: theme.spacing(2),
      padding: theme.spacing(2),
      zIndex: theme.zIndex.drawer + 2,
      backgroundColor: theme.palette.background.paper,
      transition: theme.transitions.create('top', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen
      }),

      '&:focus, &:active': {
        top: theme.spacing(2)
      },

      '@media print': {
        display: 'none'
      }
    },

    // These are the global style rules and the global class names that can be reused in all the app's components.
    // In order to diferentiate these class names, we'll write them in _snake_case_surrounded_by_underscores_
    // IMPORTANT: Do not define @global styles anywhere else in the app, and also don't abuse global styles.
    '@global': {
      /* ****************** */
      /* GLOBAL STYLE RULES */
      /* ****************** */
      body: {
        overflowY: 'scroll', // always save a space for the scrollbar to prevent ugly "content jumps"

        // Apply a background image on the register and login pages
        '&[data-pathname^="/register"], &[data-pathname^="/registerpublic"], &[data-pathname^="/index"][data-logged-in="false"]': {
          backgroundImage: 'url("' + theme.backgroundImageSrc + '")',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'center top'
        },

        '& .MuiAutocomplete-listbox': {
          maxHeight: '175px'
        }
      },

      /* ****************** */
      /* GLOBAL CLASS NAMES */
      /* ****************** */
      '._hidden_': {
        // For hiding elements
        display: 'none'
      },
      '._visually_hidden_': {
        // For hiding elements. In this case the elements will still be readable by screen readers
        border: '0 !important',
        clip: 'rect(0 0 0 0) !important',
        height: '1px !important',
        margin: '-1px !important',
        overflow: 'hidden !important',
        padding: '0 !important',
        position: 'absolute !important',
        width: '1px !important'
      },
      '._invisible_': {
        // For hiding elements and preserving their dimensions
        visibility: 'hidden',
        opacity: 0
      },
      '._cursor_pointer_': {
        // Shortcut class for making the cursor over an element appear like a pointer (like in clickable elements)
        cursor: 'pointer'
      },
      '._centered_container_': {
        // For centering a section or page and limiting its width
        display: 'block',
        margin: 'auto',
        padding: theme.spacing(4),
        maxWidth: '600px',

        [theme.breakpoints.down('md')]: {
          paddingRight: theme.spacing(2),
          paddingLeft: theme.spacing(2)
        }
      },
      '._centered_btn_container_': {
        // To be applied to the container of a button we want to center
        marginTop: theme.spacing(2),
        textAlign: 'center'
      },
      '._centered_text_': {
        // For centering text (or inline elements) inside an element
        textAlign: 'center'
      },
      '._position_relative_': {
        // helper class for a quick position:relative style
        position: 'relative'
      }
    }
  }),
  { name: 'app-' }
)

export default useStyles
