import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(
  (theme) => ({
    wrapper: {
      // marginTop: theme.spacing(1)
    },
    'address-line-1-wrapper': {
      position: 'relative'
    },
    'address-line-1': {
      position: 'absolute',
      top: theme.spacing(2),
      right: 0,
      bottom: 0,
      left: theme.spacing(2),
      zIndex: 1
    },
    'address-line-1-autocomplete': {
      position: 'relative',
      width: '100%',
      // maxWidth: '260px',
      zIndex: 2
    },
    'link-to-map': {
      display: 'block',
      width: '100%',
      textAlign: 'right'
    },
    'map-wrapper': {
      position: 'relative',
      marginTop: theme.spacing(2),
      width: '100%',
      height: '400px'
    },
    map_button_wrapper: {
      position: 'absolute',
      top: 10,
      right: 20,
      zIndex: 1
    },
    map: {
      position: 'relative',
      marginTop: theme.spacing(2),
      width: '100%',
      minWidth: '300px',
      height: '400px',
      borderRadius: theme.shape.borderRadius,
      [theme.breakpoints.up('sm')]: {
        minWidth: '500px',
        height: '300px'
      },
      [theme.breakpoints.up('md')]: {
        minWidth: '500px',
        height: '300px'
      },
      [theme.breakpoints.up('lg')]: {
        minWidth: '500px',
        height: '300px'
      },

      '& .ol-viewport': {
        borderRadius: theme.shape.borderRadius
      }
    }
  }),
  { name: 'register-page-location-fieldset' }
)

export default useStyles
