import { Paper, Typography } from '@mui/material'
import { visuallyHidden } from '@mui/utils'
import { useEffect } from 'react'

import ButtonWithProgress from '../common/ButtonWithProgress'
import CheckboxInputGroup from '../common/CheckboxInputGroup'
import CheckboxInputTree from '../common/CheckboxInputTree'
import PageTitle from '../common/PageTitle'
import ProgressOverlay from '../common/ProgressOverlay'

import intl from '../../helper/intl'
import accountService from '../../service/accountService'

import { useDispatch, useSelector } from 'react-redux'
import {
  notificationChannelsChange,
  notificationTypesChange,
  updateNotificationChannelsFailure,
  updateNotificationChannelsStart,
  updateNotificationChannelsSuccess,
  updateNotificationTypesFailure,
  updateNotificationTypesStart,
  updateNotificationTypesSuccess,
  updateWeatherTypesFailure,
  updateWeatherTypesStart,
  updateWeatherTypesSuccess,
  weatherTypesChange
} from '../../actions/accountNotificationsAction'
import { closeAppSnackbar, openAppSnackbar } from '../../actions/notificationsAction'

import utils from '../../helper/utils'
import useStyles from './AccountNotificationsPage.styles'

const AccountNotificationsPage = () => {
  const companyData = useSelector((state) => state.auth.companyData)
  const accountId = useSelector((state) => state.auth.accountId)
  const notificationsLoading = useSelector((state) => state.accountNotifications.notificationsLoading)
  const notificationTypes = useSelector((state) => state.accountNotifications.types)
  const notificationChannels = useSelector((state) => state.accountNotifications.channels)
  const weatherTypes = useSelector((state) => state.accountNotifications.weatherTypes)
  const updateNotificationTypesLoading = useSelector((state) => state.accountNotifications.updateNotificationTypesLoading)
  const updateNotificationChannelsLoading = useSelector((state) => state.accountNotifications.updateNotificationChannelsLoading)
  const updateWeatherTypesLoading = useSelector((state) => state.accountNotifications.updateWeatherTypesLoading)
  const dispatch = useDispatch()
  const classes = useStyles()

  useEffect(() => {
    document.title =
      intl.translate('account_notifications_page__title') + ' | ' + (companyData.portal_title || intl.translate('app_header__title'))
  }, [companyData])

  const onTypesFormSubmit = (event) => {
    event.preventDefault()

    dispatch(updateNotificationTypesStart())
    accountService
      .updateNotificationTypes({ accountId, notificationTypes })
      .then((result) => {
        dispatch(updateNotificationTypesSuccess())
        dispatch(openAppSnackbar({ message: intl.translate('account_notifications_page__update_success') }))
      })
      .catch((error) => {
        dispatch(updateNotificationTypesFailure({ error: intl.translate('account_notifications_page__update_error') }))
        dispatch(
          openAppSnackbar({
            message: intl.getServerError(error.response.data) || intl.translate('account_notifications_page__update_error'),
            actionText: intl.translate('general__retry'),
            actionFunction: () => {
              dispatch(closeAppSnackbar())
              onTypesFormSubmit(event)
            }
          })
        )
      })
  }

  const onChannelsFormSubmit = (event) => {
    event.preventDefault()

    dispatch(updateNotificationChannelsStart())
    accountService
      .updateNotificationChannels({ accountId, notificationChannels })
      .then((result) => {
        dispatch(updateNotificationChannelsSuccess())
        dispatch(openAppSnackbar({ message: intl.translate('account_notifications_page__update_success') }))
      })
      .catch((error) => {
        dispatch(updateNotificationChannelsFailure({ error: intl.translate('account_notifications_page__update_error') }))
        dispatch(
          openAppSnackbar({
            message: intl.getServerError(error.response.data) || intl.translate('account_notifications_page__update_error'),
            actionText: intl.translate('general__retry'),
            actionFunction: () => {
              dispatch(closeAppSnackbar())
              onChannelsFormSubmit(event)
            }
          })
        )
      })
  }

  const onWeatherTypesFormSubmit = (event) => {
    event.preventDefault()
    dispatch(updateWeatherTypesStart())
    accountService
      .updateWeatherTypes({ accountId, weatherTypes })
      .then((result) => {
        dispatch(updateWeatherTypesSuccess())
        dispatch(openAppSnackbar({ message: intl.translate('account_notifications_page__update_success') }))
      })
      .catch((error) => {
        dispatch(updateWeatherTypesFailure({ error: intl.translate('account_notifications_page__update_error') }))
        dispatch(
          openAppSnackbar({
            message: intl.getServerError(error.response.data) || intl.translate('account_notifications_page__update_error'),
            actionText: intl.translate('general__retry'),
            actionFunction: () => {
              dispatch(closeAppSnackbar())
              onChannelsFormSubmit(event)
            }
          })
        )
      })
  }

  return (
    <Paper className="_centered_container_ _position_relative_">
      <ProgressOverlay hidden={!notificationsLoading} />

      <PageTitle>{intl.translate('account_notifications_page__title')}</PageTitle>

      <Typography variant="body1" paragraph mt={2}>{intl.translate('account_notifications_page__description')}</Typography>

      {utils.checkPermission('notification_types_view') && !!notificationTypes.length && (
        <form autoComplete="off" onSubmit={onTypesFormSubmit}>
          <CheckboxInputGroup
            id="notification-types"
            className={classes['notification-types']}
            legend={intl.translate('account_notifications_page__notification_types_label')}
            renderLabel={(inputLabel) => intl.translate(`account_notifications_page__campaign_type_${inputLabel}`) || inputLabel}
            disabled={updateNotificationTypesLoading}
            inputs={notificationTypes}
            onChange={(inputs) => dispatch(notificationTypesChange({ types: inputs }))}
          />
          <div className={'_centered_btn_container_ ' + classes['submit-btn-container']}>
            <ButtonWithProgress
              type="submit"
              showProgress={updateNotificationTypesLoading}
              variant="contained"
              color={window.app.theme.palette.button ? 'button' : 'primary'}
              size="large"
            >
              {intl.translate('general__update')}
              <Typography style={visuallyHidden}> {intl.translate('account_notifications_page__notification_types')}</Typography>
            </ButtonWithProgress>
          </div>
        </form>
      )}

      {utils.checkPermission('notification_channels_view') && !!notificationChannels.length && (
        <form autoComplete="off" onSubmit={onChannelsFormSubmit}>
          <CheckboxInputGroup
            id="notification-channels"
            className={classes['notification-channels']}
            disabled={updateNotificationChannelsLoading}
            sortable={true}
            legend={
              <span>
                {intl.translate('account_notifications_page__notification_channels_label')}
                <span aria-hidden="true"> {intl.translate('account_notifications_page__notification_channels_dragdrop_label')}</span>
              </span>
            }
            renderLabel={(inputLabel) => intl.translate(`account_notifications__${inputLabel.toLowerCase()}`) || inputLabel}
            inputs={notificationChannels}
            onChange={(inputs, value) => {
              const valueTTY = 21

              const checkboxTTY = inputs.findIndex((input) => input.value === 21)
              const checkboxSMS = inputs.findIndex((input) => input.value === 7)
              const checkboxVOICE = inputs.findIndex((input) => input.value === 10)

              const checkedTTY = checkboxTTY > -1 ? inputs[checkboxTTY].checked === true : false
              const checkedSMS = checkboxSMS > -1 ? inputs[checkboxSMS].checked === true : false
              const checkedVOICE = checkboxVOICE > -1 ? inputs[checkboxVOICE].checked === true : false

              if (checkedTTY && value === valueTTY) {
                if (checkboxVOICE > -1) {
                  inputs[checkboxVOICE] = { ...inputs[checkboxVOICE], checked: false }
                }
                if (checkboxSMS > -1) {
                  inputs[checkboxSMS] = { ...inputs[checkboxSMS], checked: false }
                }
              } else {
                if ((checkedSMS || checkedVOICE) && checkedTTY > -1) {
                  inputs[checkboxTTY] = { ...inputs[checkboxTTY], checked: false }
                }
              }

              dispatch(notificationChannelsChange({ channels: inputs }))
            }}
          />
          <div className={'_centered_btn_container_ ' + classes['submit-btn-container']}>
            <ButtonWithProgress
              type="submit"
              showProgress={updateNotificationChannelsLoading}
              variant="contained"
              color={window.app.theme.palette.button ? 'button' : 'primary'}
              size="large"
            >
              {intl.translate('general__update')}
              <Typography style={visuallyHidden}> {intl.translate('account_notifications_page__notification_channels')}</Typography>
            </ButtonWithProgress>
          </div>
        </form>
      )}

      {utils.checkPermission('weather_notifications_view') && !!weatherTypes.length && (
        <form autoComplete="off" className={weatherTypes && weatherTypes.length ? '' : '_hidden_'} onSubmit={onWeatherTypesFormSubmit}>
          <CheckboxInputTree
            id="weather-types"
            className={classes['weather-types']}
            disabled={updateWeatherTypesLoading}
            legend={intl.translate('account_notifications_page__weather_types_label')}
            renderLabel={(inputLabel) => intl.translate(`account_notifications_page__weather_notification_${inputLabel}`) || inputLabel}
            nodes={weatherTypes}
            onChange={(inputs) => dispatch(weatherTypesChange({ weatherTypes: inputs }))}
          />
          <div className={'_centered_btn_container_ ' + classes['submit-btn-container']}>
            <ButtonWithProgress
              type="submit"
              showProgress={updateWeatherTypesLoading}
              variant="contained"
              color={window.app.theme.palette.button ? 'button' : 'primary'}
              size="large"
            >
              {intl.translate('general__update')}
              <Typography style={visuallyHidden}> {intl.translate('account_notifications_page__weather_types')}</Typography>
            </ButtonWithProgress>
          </div>
        </form>
      )}
    </Paper>
  )
}

export default AccountNotificationsPage
