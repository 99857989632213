import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(
  (theme) => ({
    wrapper: {
      '& .MuiSnackbarContent-root': {
        backgroundColor: '#fff',
        color: 'black',
        display: 'flex',
        borderRadius: '8px',
        padding: '20px 24px',
        boxShadow:
          '0px 9px 28px 8px rgba(0, 0, 0, 0.05), ' + '0px 3px 6px -4px rgba(0, 0, 0, 0.12), ' + '0px 6px 16px 0px rgba(0, 0, 0, 0.08)'
      },
      '& .MuiSnackbarContent-action': {
        alignSelf: 'start'
      }
    },
    'wrapper-massage-container': {
      display: 'flex',
      alignItems: 'initial',
      gap: '8px'
    },
    'wrapper-message': {
      display: 'flex',
      flexDirection: 'column',
      gap: '4px'
    },
    'wrapper-title': {
      fontWeight: 'bold',
      fontSize: '16px',
      margin: 0
    },
    'wrapper-title-reduced': {
      fontSize: '16px',
      margin: 0
    },
    'wrapper-description': {
      fontSize: '14px',
      margin: 0
    },
    'wrapper-button': {
      display: 'flex',
      justifyContent: 'end'
    },
    'action-btn': {
      color: theme.palette.secondary.light
    }
  }),
  { name: 'basic-snackbar-' }
)

export default useStyles
