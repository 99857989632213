import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(
  (theme) => ({
    title: {
      marginBottom: theme.spacing(4)
    },
    link: {
      display: 'block',
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1)
    }
  }),
  { name: 'sitemap-page-' }
)

export default useStyles
