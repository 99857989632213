import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(
  (theme) => ({
    separator: {
      marginTop: theme.spacing(1)
    },
    'google-recaptcha': {
      paddingTop: theme.spacing(2),
      '& > div > div': {
        margin: 'auto'
      }
    },
    'link-to-login': {
      marginTop: theme.spacing(2)
    },
    'disclaimer': {
      marginTop: theme.spacing(2),
      color: theme.palette.text.secondary,
      fontSize: '12px'
    }
  }),
  { name: 'register-page-' }
)

export default useStyles
