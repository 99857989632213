import accountService from '../service/accountService'
import { openAppSnackbar, closeAppSnackbar, openContactConfirmationDialog } from './notificationsAction'
import intl from '../helper/intl'

export const ACCOUNT_INDIVIDUALS_CHANGE = 'ACCOUNT_INDIVIDUALS_CHANGE'

export const LOAD_ACCOUNT_INDIVIDUALS_START = 'LOAD_ACCOUNT_INDIVIDUALS_START'
export const LOAD_ACCOUNT_INDIVIDUALS_SUCCESS = 'LOAD_ACCOUNT_INDIVIDUALS_SUCCESS'
export const LOAD_ACCOUNT_INDIVIDUALS_FAILURE = 'LOAD_ACCOUNT_INDIVIDUALS_FAILURE'

export const UPDATE_INDIVIDUAL_START = 'UPDATE_INDIVIDUAL_START'
export const UPDATE_INDIVIDUAL_SUCCESS = 'UPDATE_INDIVIDUAL_SUCCESS'
export const UPDATE_INDIVIDUAL_FAILURE = 'UPDATE_INDIVIDUAL_FAILURE'

export const DELETE_INDIVIDUAL_START = 'DELETE_INDIVIDUAL_START'
export const DELETE_INDIVIDUAL_SUCCESS = 'DELETE_INDIVIDUAL_SUCCESS'
export const DELETE_INDIVIDUAL_FAILURE = 'DELETE_INDIVIDUAL_FAILURE'

export const SHOW_NEW_INDIVIDUAL_DIALOG = 'SHOW_NEW_INDIVIDUAL_DIALOG'
export const HIDE_NEW_INDIVIDUAL_DIALOG = 'HIDE_NEW_INDIVIDUAL_DIALOG'
export const NEW_INDIVIDUAL_CHANGE = 'NEW_INDIVIDUAL_CHANGE'
export const CREATE_NEW_INDIVIDUAL_START = 'CREATE_NEW_INDIVIDUAL_START'
export const CREATE_NEW_INDIVIDUAL_SUCCESS = 'CREATE_NEW_INDIVIDUAL_SUCCESS'
export const CREATE_NEW_INDIVIDUAL_FAILURE = 'CREATE_NEW_INDIVIDUAL_FAILURE'

export const accountIndividualsChange = ({ individuals }) => ({
  type: ACCOUNT_INDIVIDUALS_CHANGE,
  payload: {
    individuals
  }
})

export const loadAccountIndividualsStart = () => ({
  type: LOAD_ACCOUNT_INDIVIDUALS_START
})

export const loadAccountIndividualsSuccess = ({ individuals }) => ({
  type: LOAD_ACCOUNT_INDIVIDUALS_SUCCESS,
  payload: {
    individuals
  }
})

export const loadAccountIndividualsFailure = ({ error }) => ({
  type: LOAD_ACCOUNT_INDIVIDUALS_FAILURE,
  payload: {
    error
  }
})

export const updateIndividualStart = ({ id }) => ({
  type: UPDATE_INDIVIDUAL_START,
  payload: {
    id
  }
})

export const updateIndividualSuccess = () => ({
  type: UPDATE_INDIVIDUAL_SUCCESS
})

export const updateIndividualFailure = ({ error }) => ({
  type: UPDATE_INDIVIDUAL_FAILURE,
  payload: {
    error
  }
})

export const deleteIndividualStart = ({ id }) => ({
  type: DELETE_INDIVIDUAL_START,
  payload: {
    id
  }
})

export const deleteIndividualSuccess = ({ id }) => ({
  type: DELETE_INDIVIDUAL_SUCCESS,
  payload: {
    id
  }
})

export const deleteIndividualFailure = ({ error }) => ({
  type: DELETE_INDIVIDUAL_FAILURE,
  payload: {
    error
  }
})

export const deleteIndividualAsync =
  ({ accountId, id }) =>
    (dispatch) => {
      const deleteIndividual = (_accountId, _id) => {
        dispatch(deleteIndividualStart({ id: _id }))

        accountService
          .deleteIndividual({ accountId: _accountId, individualId: _id })
          .then((result) => {
            dispatch(deleteIndividualSuccess({ id: _id }))
            dispatch(openAppSnackbar({ message: intl.translate('account_individuals_page__delete_success') }))
          })
          .catch((error) => {
            dispatch(deleteIndividualFailure({ error: intl.translate('account_individuals_page__delete_error') }))
            dispatch(
              openAppSnackbar({
                message: intl.getServerError(error.response.data) || intl.translate('account_individuals_page__delete_error'),
                actionText: intl.translate('general__retry'),
                actionFunction: () => {
                  dispatch(closeAppSnackbar())
                  deleteIndividual(_accountId, _id)
                }
              })
            )
          })
      }

      deleteIndividual(accountId, id)
    }

export const showNewIndividualDialog = () => ({
  type: SHOW_NEW_INDIVIDUAL_DIALOG
})

export const hideNewIndividualDialog = () => ({
  type: HIDE_NEW_INDIVIDUAL_DIALOG
})

export const newIndividualChange = ({ individual }) => ({
  type: NEW_INDIVIDUAL_CHANGE,
  payload: {
    individual
  }
})

export const createNewIndividualStart = () => ({
  type: CREATE_NEW_INDIVIDUAL_START
})

export const createNewIndividualSuccess = ({ individual }) => ({
  type: CREATE_NEW_INDIVIDUAL_SUCCESS,
  payload: {
    individual
  }
})

export const createNewIndividualFailure = ({ error }) => ({
  type: CREATE_NEW_INDIVIDUAL_FAILURE,
  payload: {
    error
  }
})

export const createNewIndividualAsync =
  ({ accountId, individual }) =>
    (dispatch) => {
      const createNewIndividual = (_accountId, _individual) => {
        dispatch(createNewIndividualStart())

        individual.email = individual.email.toLowerCase() // Email addresses should be all lowercase

        accountService
          .createIndividual({ accountId: _accountId, individual: _individual })
          .then((result) => {
            dispatch(createNewIndividualSuccess({ individual: result.data }))
            dispatch(hideNewIndividualDialog())
            dispatch(openAppSnackbar({ message: intl.translate('account_individuals_page__create_success') }))
            if (result.data.phoneNumberConfirmed === false) {
              dispatch(
                openContactConfirmationDialog({
                  phoneNumber: intl.getDialCodeByCountryCode(_individual.countryCode) + _individual.phoneNumber
                })
              )
            }
          })
          .catch((error) => {
            dispatch(createNewIndividualFailure({ error: intl.translate('account_individuals_page__create_error') }))
            dispatch(
              openAppSnackbar({
                message: intl.getServerError(error.response.data) || intl.translate('account_individuals_page__create_error'),
                actionText: intl.translate('general__retry'),
                actionFunction: () => {
                  dispatch(closeAppSnackbar())
                  createNewIndividual(_accountId, _individual)
                }
              })
            )
          })
      }

      createNewIndividual(accountId, individual)
    }
