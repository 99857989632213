import { Add as AddIcon } from '@mui/icons-material'
import { Paper, Typography } from '@mui/material'
import { visuallyHidden } from '@mui/utils'
import { useEffect } from 'react'

import BasicDialog from '../common/BasicDialog'
import ButtonWithProgress from '../common/ButtonWithProgress'
import PageTitle from '../common/PageTitle'
import ProgressOverlay from '../common/ProgressOverlay'
import IndividualFieldset from './IndividualFieldset'

import intl from '../../helper/intl'
import validate from '../../helper/validate'

import accountService from '../../service/accountService'

import { useDispatch, useSelector } from 'react-redux'
import {
  accountIndividualsChange,
  createNewIndividualAsync,
  deleteIndividualAsync,
  hideNewIndividualDialog,
  newIndividualChange,
  showNewIndividualDialog,
  updateIndividualFailure,
  updateIndividualStart,
  updateIndividualSuccess
} from '../../actions/accountIndividualsAction'
import {
  closeAppDialog,
  closeAppSnackbar,
  openAppDialog,
  openAppSnackbar,
  openContactConfirmationDialog
} from '../../actions/notificationsAction'

import utils from '../../helper/utils'
import useStyles from './AccountIndividualsPage.styles'

const getNumericValue = (value) => {
  return value.replace(/\D/g, '') // remove non-numeric characters
}

const AccountIndividualsPage = () => {
  const companyData = useSelector((state) => state.auth.companyData)
  const accountId = useSelector((state) => state.auth.accountId)
  const loginCredentials = useSelector((state) => state.auth.loginCredentials)
  const individuals = useSelector((state) => state.accountIndividuals.individuals)
  const individualsLoading = useSelector((state) => state.accountIndividuals.individualsLoading)
  const updateIndividualLoading = useSelector((state) => state.accountIndividuals.updateIndividualLoading)
  const deleteIndividualLoading = useSelector((state) => state.accountIndividuals.deleteIndividualLoading)
  const newIndividualDialogIsOpen = useSelector((state) => state.accountIndividuals.newIndividualDialogIsOpen)
  const newIndividual = useSelector((state) => state.accountIndividuals.newIndividual)
  const createNewIndividualLoading = useSelector((state) => state.accountIndividuals.createNewIndividualLoading)
  const dispatch = useDispatch()
  const classes = useStyles()

  useEffect(() => {
    document.title =
      intl.translate('account_individuals_page__title') + ' | ' + (companyData.portal_title || intl.translate('app_header__title'))
  }, [companyData])

  const createLegend = (legendFirstName, legendLastName) => {
    if (legendFirstName && legendLastName) {
      return legendFirstName + ' ' + legendLastName
    } else if (legendFirstName && !legendLastName) {
      return legendFirstName
    } else if (!legendFirstName && legendLastName) {
      return legendLastName
    } else {
      return '-'
    }
  }

  const shouldFieldsetBeDisabled = (individualId) => {
    // We'll disable a fieldset if the individual of that fieldset is being updated or deleted.
    return (
      (updateIndividualLoading && updateIndividualLoading === individualId) ||
      (deleteIndividualLoading && deleteIndividualLoading === individualId)
    )
  }

  const onIndividualsChange = (individualId, individual) => {
    const newIndividuals = []
    const numIndividuals = individuals.length
    for (let i = 0; i < numIndividuals; i++) {
      const _individual = individualId === individuals[i].id ? individual : individuals[i]
      const _phoneInput = document.getElementById('individual-fieldset-' + _individual.id + '--phone-number--phone-number')
      if (_phoneInput && _individual.phoneNumber !== _phoneInput.value) {
        // To prevent showing errors on valid phone inputs, we have to pick the value from the HTML field
        _individual.phoneNumber = _phoneInput.value
      }
      newIndividuals.push({ ..._individual })
    }
    dispatch(accountIndividualsChange({ individuals: newIndividuals }))
  }

  const onUpdateIndividualFormSubmit = (event, individual) => {
    event.preventDefault()

    const individualError = getIndividualError(individual)

    if (individualError) {
      dispatch(openAppSnackbar({ message: individualError }))
      return false
    }

    updateIndividual(accountId, individual)
  }

  const updateIndividual = (_accountId, _individual) => {
    dispatch(updateIndividualStart({ id: _individual.id }))

    _individual.email = _individual.email.toLowerCase() // Email addresses should be all lowercase

    accountService
      .updateIndividual({ accountId: _accountId, individual: _individual })
      .then((result) => {
        dispatch(updateIndividualSuccess())
        dispatch(openAppSnackbar({ message: intl.translate('account_individuals_page__update_success') }))
        onIndividualsChange(_individual.id, result.data)
        if (result.data.phoneNumberConfirmed === false) {
          dispatch(
            openContactConfirmationDialog({
              phoneNumber: intl.getDialCodeByCountryCode(_individual.countryCode) + _individual.phoneNumber
            })
          )
        }
      })
      .catch((error) => {
        dispatch(updateIndividualFailure({ error: intl.translate('account_individuals_page__update_error') }))
        dispatch(
          openAppSnackbar({
            message: intl.getServerError(error.response.data) || intl.translate('account_individuals_page__update_error'),
            actionText: intl.translate('general__retry'),
            actionFunction: () => {
              dispatch(closeAppSnackbar())
              updateIndividual(_accountId, _individual)
            }
          })
        )
      })
  }

  const showDeleteIndividualDialog = (individualId) => {
    dispatch(
      openAppDialog({
        title: intl.translate('account_individuals_page__delete_modal_title'),
        description: intl.translate('account_individuals_page__delete_modal_description'),
        actionText: intl.translate('account_individuals_page__delete_modal_confirm'),
        actionFunction: () => onDeleteIndividual(individualId),
        autoFocusButton: 'cancel'
      })
    )
  }

  const onDeleteIndividual = (individualId) => {
    dispatch(closeAppDialog())
    dispatch(deleteIndividualAsync({ accountId, id: individualId }))
  }

  const onCreateIndividualFormSubmit = (event) => {
    event.preventDefault()

    const individualError = getIndividualError(newIndividual)
    if (individualError) {
      dispatch(openAppSnackbar({ message: individualError }))
      return false
    }
    dispatch(createNewIndividualAsync({ accountId, individual: newIndividual }))
  }

  const getIndividualError = (individualObj) => {
    if (!individualObj.firstName) {
      return intl.translate('account_individuals_page__empty_first_name_error')
    } else if (!individualObj.lastName) {
      return intl.translate('account_individuals_page__empty_last_name_error')
    } else if (!individualObj.email && !individualObj.phoneNumber) {
      return intl.translate('account_individuals_page__empty_email_or_phone_error')
    } else if (individualObj.email && !validate.isValidEmail(individualObj.email)) {
      return intl.translate('general__invalid_email')
    } else if (
      (getNumericValue(individualObj.phoneNumber) && !individualObj.countryCode) ||
      (getNumericValue(individualObj.phoneNumber) !== '' &&
        !validate.isValidPhoneNumber(individualObj.phoneNumber, { countryCode: individualObj.countryCode }))
    ) {
      return intl.translate(individualObj.countryCode ? 'general__invalid_phone_number' : 'general__empty_phone_prefix_error')
    } else {
      for (const existingIndividual of individuals) {
        const differentId = existingIndividual.id !== individualObj.id
        const sameEmail = individualObj.email && existingIndividual.email === individualObj.email
        const samePhoneNumber =
          individualObj.countryCode &&
          individualObj.phoneNumber &&
          existingIndividual.countryCode === individualObj.countryCode &&
          existingIndividual.phoneNumber === individualObj.phoneNumber
        if (differentId && sameEmail) {
          return intl.translate('general__email_already_registered')
        } else if (differentId && samePhoneNumber) {
          return intl.translate('general__phone_already_registered')
        }
      }
      return null
    }
  }

  return (
    <>
      <Paper className="_centered_container_ _position_relative_">
        <ProgressOverlay hidden={!individualsLoading} />
        <PageTitle>{intl.translate('account_individuals_page__title')}</PageTitle>
        <Typography variant="body1" paragraph mt={2}>
          {intl.translate('account_individuals_page__description')}
        </Typography>
        {individuals.map((individual, index) => (
          <form key={index} autoComplete="off" onSubmit={(event) => onUpdateIndividualFormSubmit(event, individual)}>
            {utils.checkPermission('relatives_email_view') && (
              <IndividualFieldset
                id={individual.id}
                className={classes['individual-fieldset']}
                legend={createLegend(individual.firstName, individual.lastName)}
                disabled={shouldFieldsetBeDisabled(individual.id)}
                firstName={individual.firstName}
                lastName={individual.lastName}
                email={individual.email}
                emailConfirmed={individual.emailConfirmed}
                onConfirmEmail={() => dispatch(openContactConfirmationDialog({ email: individual.email }))}
                countryCode={individual.countryCode}
                phoneNumber={individual.phoneNumber}
                phoneNumberConfirmed={individual.phoneNumberConfirmed}
                onConfirmPhoneNumber={() =>
                  dispatch(
                    openContactConfirmationDialog({
                      phoneNumber: intl.getDialCodeByCountryCode(individual.countryCode) + individual.phoneNumber
                    })
                  )
                }
                phoneNumberSms={individual.phoneNumberSms}
                onChange={(individual) => onIndividualsChange(individual.id, individual)}
                onDelete={() => showDeleteIndividualDialog(individual.id)}
                showDeleteProgress={deleteIndividualLoading && deleteIndividualLoading === individual.id}
              />
            )}
            <div className="_centered_btn_container_">
              <ButtonWithProgress
                type="submit"
                id={'update-individual-' + individual.id + '-btn'}
                className={classes['update-btn']}
                showProgress={updateIndividualLoading && updateIndividualLoading === individual.id}
                disabled={shouldFieldsetBeDisabled(individual.id)}
                variant="contained"
                color={window.app.theme.palette.button ? 'button' : 'primary'}
                size="large"
              >
                {intl.translate('account_individuals_page__update_btn')}
                <Typography style={visuallyHidden}> {createLegend(individual.firstName, individual.lastName)}</Typography>
              </ButtonWithProgress>
            </div>
          </form>
        ))}
        <div className="_centered_btn_container_">
          <ButtonWithProgress
            className={classes['create-btn']}
            variant="contained"
            size="large"
            startIcon={<AddIcon />}
            onClick={() => dispatch(showNewIndividualDialog())}
          >
            {intl.translate('account_individuals_page__create_btn')}
          </ButtonWithProgress>
        </div>
      </Paper>
      <BasicDialog
        id="new-individual-dialog"
        isOpen={newIndividualDialogIsOpen}
        title={intl.translate('account_individuals_page__create_modal_title')}
        description=""
        actionButtons={[
          {
            content: intl.translate('account_individuals_page__create_modal_cancel'),
            onClick: () => dispatch(hideNewIndividualDialog())
          },
          {
            id: 'create-new-individual-btn',
            content: intl.translate('account_individuals_page__create_modal_confirm'),
            type: 'submit',
            form: 'new-individual-form',
            showProgress: createNewIndividualLoading
          }
        ]}
        onClose={() => dispatch(hideNewIndividualDialog())}
      >
        <form id="new-individual-form" autoComplete="off" onSubmit={onCreateIndividualFormSubmit}>
          <IndividualFieldset
            id="new-individual"
            // legend={createLegend(newIndividual.firstName, newIndividual.lastName)}
            autoFocus={true}
            disabled={createNewIndividualLoading}
            firstName={newIndividual.firstName}
            lastName={newIndividual.lastName}
            email={newIndividual.email}
            countryCode={newIndividual.countryCode || loginCredentials.countryCode}
            phoneNumber={newIndividual.phoneNumber}
            phoneNumberSms={newIndividual.phoneNumberSms}
            onChange={(individual) => dispatch(newIndividualChange({ individual }))}
          />
        </form>
      </BasicDialog>
    </>
  )
}

export default AccountIndividualsPage
