import { useEffect, useRef, useState } from 'react'
import { Paper, Grid, Tooltip, Typography, Link } from '@mui/material'
import { Link as RouterLink } from 'react-router-dom'
import PageTitle from '../common/PageTitle'
import TextInput from '../common/TextInput'
import PhoneNumberInput from '../common/PhoneNumberInput'
import ButtonWithProgress from '../common/ButtonWithProgress'
import Separator from '../common/Separator'

import ReCAPTCHA from 'react-google-recaptcha';

import authService from '../../service/authService'
import intl from '../../helper/intl'
import validate from '../../helper/validate'

import { useSelector, useDispatch } from 'react-redux'
import { requestPasswordStart, requestPasswordSuccess, requestPasswordFailure, passwordRequestDataChange } from '../../actions/authAction'
import { openAppSnackbar, closeAppSnackbar } from '../../actions/notificationsAction'

import useStyles from './PasswordRequestPage.styles'
import utils from '../../helper/utils'

const PasswordRequestPage = () => {
  const companyData = useSelector((state) => state.auth.companyData)
  const requestPasswordLoading = useSelector((state) => state.auth.requestPasswordLoading)
  const passwordRequestData = useSelector((state) => state.auth.passwordRequestData)
  const urlBasename = useSelector((state) => state.intl.urlBasename)
  const dispatch = useDispatch()
  const classes = useStyles()
  const captchaRef = useRef(null);
  const [captchaToken, setCaptchaToken] = useState(null);

  useEffect(() => {
    document.title = intl.translate('password_request_page__title') + ' | ' + (companyData.portal_title || intl.translate('app_header__title'))

    if (companyData.name === 'Longueuil' && !passwordRequestData.countryCode) {
      onFormDataChange('countryCode', 'CA') // Initialize countryCode = +1 for Longueuil
    }
  }, [companyData])

  const onFormDataChange = (paramName, paramValue) => {
    const newFormData = { ...passwordRequestData }
    newFormData[paramName] = paramValue
    dispatch(passwordRequestDataChange({ data: newFormData }))
  }

  const validateFormFields = () => {
    const emailIsValid = validate.isValidEmail(passwordRequestData.email)
    const phoneNumberIsValid = validate.isValidPhoneNumber(passwordRequestData.phoneNumber, {
      countryCode: passwordRequestData.countryCode
    })
    const recaptchaIsValid = utils.checkPermission('reset_password_recaptcha') ? !!captchaToken : true;

    return (emailIsValid || phoneNumberIsValid) && recaptchaIsValid
  }

  const onFormSubmit = (event) => {
    event.preventDefault()

    if (!passwordRequestData.email && passwordRequestData.phoneNumber && !passwordRequestData.countryCode) {
      dispatch(openAppSnackbar({ message: intl.translate('general__empty_phone_prefix_error') }))
      return false
    }

    const dataWithCaptcha = { ...passwordRequestData };
    if (captchaToken) {
      dataWithCaptcha.recaptcha_token = captchaToken;
      setCaptchaToken(null);
    }

    if (captchaRef.current) {
      captchaRef.current.reset();
    }

    dispatch(requestPasswordStart())
    authService
      .requestPasswordReset(dataWithCaptcha)
      .then((result) => {
        dispatch(requestPasswordSuccess())
        dispatch(
          openAppSnackbar({
            message: intl.translate(passwordRequestData.email ? 'server_error__e5603' : 'server_error__e5602'),
            actionText: intl.translate('password_request_page__confirmation_action'),
            actionFunction: () => {
              dispatch(closeAppSnackbar())
            }
          })
        )
      })
      .catch((error) => {
        const errorMessage = intl.getServerError(error.response.data) || intl.translate('password_request_page__error');
        dispatch(requestPasswordFailure({ error: errorMessage }));
        dispatch(
          openAppSnackbar({
            message: errorMessage,
          })
        )
      })
  }

  const GOOGLE_CAPTCHA_KEY = window.__env__ ? (window.__env__.GOOGLE_CAPTCHA_KEY || '') : '';

  return (
    <Paper className="_centered_container_">
      <PageTitle>{intl.translate('password_request_page__title')}</PageTitle>

      <form autoComplete="off" onSubmit={onFormSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography className={classes.description} variant="body1">
              {intl.translate('password_request_page__description')}
            </Typography>

            {utils.checkPermission('reset_password_email_view') && (
              <Tooltip title={passwordRequestData.phoneNumber ? intl.translate('general__only_phone_or_email_necessary') : ''}>
                <div>
                  <TextInput
                    id="password-request-page--email"
                    label={intl.translate('general__email_label')}
                    disabled={!!passwordRequestData.phoneNumber || requestPasswordLoading}
                    value={passwordRequestData.email}
                    onChange={(event) => onFormDataChange('email', event.target.value)}
                    isValid={(value) => (value ? validate.isValidEmail(value) : true)}
                    errorMessage={intl.translate('general__invalid_email')}
                  />
                </div>
              </Tooltip>
            )}
            {utils.checkPermission('reset_password_phone_number_view') && (
              <>
                <Separator className={classes.separator} text={intl.translate('password_request_page__separator')} />

                <Tooltip title={passwordRequestData.email ? intl.translate('general__only_phone_or_email_necessary') : ''}>
                  <div>
                    <PhoneNumberInput
                      id="password-request-page--phone-number"
                      label={intl.translate('general__phone_number_label')}
                      disabled={!!passwordRequestData.email || requestPasswordLoading}
                      value={passwordRequestData.phoneNumber}
                      countryCodeValue={passwordRequestData.countryCode}
                      onPhoneNumberChange={(event) => onFormDataChange('phoneNumber', event.target.value)}
                      onCountryCodeChange={(event) => onFormDataChange('countryCode', event.target.value)}
                      isValid={(value) =>
                        value ? validate.isValidPhoneNumber(value, { countryCode: passwordRequestData.countryCode }) : true
                      }
                    />
                  </div>
                </Tooltip>
              </>
            )}
          </Grid>
          {utils.checkPermission('reset_password_recaptcha') &&
            <Grid item xs={12}>
              <ReCAPTCHA
                className={classes['google-recaptcha']}
                sitekey={GOOGLE_CAPTCHA_KEY || '-' /* sitekey is mandatory */}
                ref={captchaRef}
                onChange={(token) => setCaptchaToken(token)}
              />
            </Grid>
          }
          <Grid item xs={12}>
            <div className="_centered_btn_container_">
              <ButtonWithProgress
                type="submit"
                disabled={!validateFormFields()}
                showProgress={requestPasswordLoading}
                variant="contained"
                color={window.app.theme.palette.button ? 'button' : 'primary'}
                size="large"
              >
                {intl.translate('password_request_page__submit_btn')}
              </ButtonWithProgress>
            </div>
          </Grid>
          <Grid item xs={12}>
            <Typography
              className={classes['link-to-login']}
              align="center"
              variant="body1"
              color={window.app.theme.palette.button ? 'link.main' : 'primary'}
            >
              <Link component={RouterLink} to={urlBasename} color="inherit">
                {intl.translate('password_request_page__link_to_login')}
              </Link>
            </Typography>
          </Grid>
        </Grid>
      </form>
    </Paper>
  )
}

export default PasswordRequestPage
